<template>
  <card-background class="lesson" ref="lesson">
    <heading-size-h1>{{ sectionName }}</heading-size-h1>
    <router-view
      :nodesList="getSortedNodes"
      :isNodesLoading="isNodesLoading"
    />
  </card-background>
</template>

<script>
import { mapMutations } from 'vuex';
import CardBackground from '@/components/UI/card/CardBackground.vue';
import HeadingSizeH1 from '@/components/UI/texts/headings/HeadingSizeH1.vue';

import useNodesList from '@/hooks/lessons/useNodesList.js';
import { nextTick } from 'vue';


export default {
  name: 'lesson',

  components: {
    CardBackground,
    HeadingSizeH1,
  },

  setup() {
    const {
      isNodesLoading,
      getSortedNodes,
      fetchLessonNodes,
      fetchNode
    } = useNodesList();
    return { isNodesLoading, getSortedNodes, fetchLessonNodes, fetchNode };
  },

  data() {
    return{
      sectionName: '',
      cards: [
        // { id: 'dasdasdasdsad', lessonNum: 1, isUserViewed: false, name: 'Урок 1', index: 1},
      ],
      lessonId: 0,
      serviceURL: process.env.VUE_APP_REGISTRATION_SERVICE_URL,
      title: '',
      level: null,
    }
  },

  watch: {
    isNodesLoading: {
      async handler() {
        if (!this.isNodesLoading) {
          await nextTick();
          this.$refs['lesson'].$el.scrollIntoView();
        }
      }
    }
  },

  methods: {
    ...mapMutations({
      setLessons: 'lessonsData/setLessons',
    })
  },

  async created() {
    try {
      this.level = +this.$route.query.level;
      const responseNode = await this.fetchNode(this.$route.params.lid);
      this.sectionName = responseNode.getNode().getTitle();
    } catch(e) {
      console.error(`Get title error [code ${e.code}]:`, e.message);
      if (e.code === 5) {
        this.sectionName = 'Раздел не найден'
      }
    }

    try {
      await this.fetchLessonNodes(this.$route.params.lid, this.level);
      this.setLessons(this.getSortedNodes);
    } catch (err) {
      console.error(`Fetch lessons error [code ${err.code}]:`, err);
    }
  }
}
</script>

<style scoped>
.lesson{
  display: flex;
  flex-direction: column;
  gap: 24px;
}
</style>