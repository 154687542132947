import { loadUint8ArrayFile } from '@/utils/converter';

function range(start, end, step) {
  const range = [];
  const typeofStart = typeof start;
  const typeofEnd = typeof end;

  if (step === 0) {
      throw TypeError("Step cannot be zero.");
  }

  if (typeofStart == "undefined" || typeofEnd == "undefined") {
      throw TypeError("Must pass start and end arguments.");
  } else if (typeofStart != typeofEnd) {
      throw TypeError("Start and end arguments must be of same type.");
  }

  typeof step == "undefined" && (step = 1);
  if (end < start) {
      step = -step;
  }

  if (typeofStart == "number") {
      while (step > 0 ? end >= start : end <= start) {
          range.push(start);
          start += step;
      }

  } else if (typeofStart == "string") {
      if (start.length != 1 || end.length != 1) {
          throw TypeError("Only strings with one character are supported.");
      }
      start = start.charCodeAt(0);
      end = end.charCodeAt(0);
      while (step > 0 ? end >= start : end <= start) {
          range.push(String.fromCharCode(start));
          start += step;
      }
  } else {
      throw TypeError("Only string and number types are supported");
  }
  return range;
}

const defaultPhotos = importAll(require.context('@/assets/KazIllustrations/avatars', false, /\.png$/));

function importAll(r) {
  return r.keys().map(r);
}

function getRandomItem(items) {
  return items[Math.floor(Math.random() * items.length)]
}

async function getRandomPhoto() {
  const randomPhoto = getRandomItem(defaultPhotos);
  const response = await fetch(randomPhoto);
  const blob = await response.blob();
  const uint8ArrayImage = await loadUint8ArrayFile(blob);
  return uint8ArrayImage;
}

export {
  range,
  getRandomItem,
  getRandomPhoto,
  defaultPhotos
}
