export const taskHomeChanger = {
  namespaced: true,

  state: () => ({
    currentView: 0,
  }),
  mutations: {
    setCurrentView(state, view) {
      state.currentView = view;
    }
  }
}