<template>
  <router-link :to="{ name: 'moderation-order', params: { mid: orderInfo.orderId } }" class="link">
    <div class="wrapper">
      <img class="order__cover" :src="orderInfo.orderBook.cover" />

      <div class="order__info">
        <h4 class="heading heading_size_h4">
          {{ orderInfo.orderBook.titleSeo }}
        </h4>

        <p class="label label_size_M">{{ orderInfo.order.type }}</p>

        <Badge
          :label="statesNames[orderInfo.order.status]"
          :status="true"
          :appearance="'clear'"
        />
      </div>
    </div>
  </router-link>
</template>

<script>
import Badge from '@/components/KazUI/atoms/badge'

import STATES from '@/components/moderation/utils/states.js';
import STATES_NAMES from '@/components/moderation/utils/statesNames.js';

export default{
  name: 'connected-order-card',
  components:{
    Badge
  },
  data(){
    return{
      statesNames: Object.keys(STATES_NAMES).map(k => STATES_NAMES[k]),
    }
  },
  props:{
    orderInfo:{
      type: Object,
      default: {},
      requred: false,
    },
  },
  created(){
    // console.log(this.orderInfo)
  }
}
</script>

<style scoped>
.link{
  text-decoration: none;
  width: 100%;
}
.wrapper{
  padding: 8px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  background-color: var(--kaz-base-base-01);
  border-radius: 4px;
  width: 100%;
  transition: var(--kaz-transition-molecules);
}
.wrapper:hover{
  background-color: var(--kaz-base-base-02);
  transition: var(--kaz-transition-molecules);
}
.order__cover{
  width: 72px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
}
.order__info{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
</style>