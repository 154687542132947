const routes = [
  {
    path: '/promocodes',
    name: 'promocodes',
    meta: {layout: 'main', auth: true},
    component: () => import('@/views/promocodes/Home.vue'),
  },
  {
    path: '/promocode-creation',
    name: 'promocode-creation',
    meta: {layout: 'main', auth: true},
    component: () => import('@/views/promocodes/CreatePromocode.vue'),
  },
]

export default routes