import STATES from '@/utils/states.js';
import { saveBookNode, deleteBookNode, editBookNode } from '@/services/TaskReader';
import { ACTION_TYPES } from '@/utils/book-structure';


export const nodeInsertionDialog = {
  namespaced: true,

  state: () => ({
    visibility: false,
    node: {
      parentId: null,
      id: null,
      nodeName: '',
      nodeType: [],
      nodePage: '',
      order: -1,
    },
    isTask: false,
    actionStatus: STATES.INIT,
    actionType: 0,
  }),

  getters: {
    getVisibility: state => state.visibility,
    getNode: state => state.node,
    getTaskAttr: state => state.isTask,
    getActionStatus: state => state.actionStatus,
    getActionType: state => state.actionType,
  },

  mutations: {
    showDialog(state) {
      state.visibility = true;
    },
    hideDialog(state) {
      state.visibility = false;
    },
    setVisibility(state, visibility) {
      state.visibility = visibility;
    },
    setTaskAttr(state, isTask) {
      state.isTask = isTask;
    },
    setNodeProperty(state, { key, value }) {
      if (state.node.hasOwnProperty(key)) {
        state.node[key] = value;
      }
    },
    resetNode(state) {
      state.node = {
        parentId: null,
        id: null,
        nodeName: '',
        nodeType: [],
        nodePage: '',
        order: -1,
      };
    },
    setActionStatus(state, actionStatus) {
      state.actionStatus = actionStatus;
    },
    setActionType(state, actionType) {
      if (Object.values(ACTION_TYPES).includes(actionType)) {
        state.actionType = actionType;
      } else {
        console.warn(`Action type = ${actionType} is not allowed`);
      }
    },
  },

  actions: {
    setupSaving({ commit }, { parentId, order, isTask }) {
      commit('resetNode');
      commit('setActionStatus', STATES.INIT);
      commit('setActionType', ACTION_TYPES.ADD);
      if (parentId !== undefined) {
        commit('setNodeProperty', { key: 'parentId', value: parentId });
      } else {
        throw new Error('parentId is not defined');
      }
      if (order !== undefined) {
        commit('setNodeProperty', { key: 'order', value: order });
      }
      commit('setTaskAttr', isTask);
      commit('showDialog');
    },

    setupRemoving({ commit }, { nodeId, isTask }) {
      commit('resetNode');
      commit('setActionStatus', STATES.INIT);
      commit('setActionType', ACTION_TYPES.REMOVE);
      commit('setNodeProperty', { key: 'id', value: nodeId });
      commit('setTaskAttr', isTask);
      commit('showDialog');
    },

    setupUpdating({ commit, getters }, { node, isTask }) {
      commit('resetNode');
      commit('setActionStatus', STATES.INIT);
      commit('setActionType', ACTION_TYPES.UPDATE);

      for (const [key, value] of Object.entries(node)) {
        if (Object.keys(getters.getNode).includes(key)) {
          commit('setNodeProperty', { key: key, value: value });
        } else {
          console.warn(`Unknown node property ${key}`);
        }
      }

      commit('setTaskAttr', isTask);
      commit('showDialog');
    },

    // this is just to save the original code
    setupUpdatingVersion2({ commit, getters }, { node, isTask }) {
      commit('resetNode');
      commit('setActionStatus', STATES.INIT);
      commit('setActionType', ACTION_TYPES.UPDATE);

      for (const [key, value] of Object.entries(node)) {
        if (Object.keys(getters.getNode).includes(key)) {
          commit('setNodeProperty', { key: key, value: value });
        } else {
          console.warn(`Unknown node property ${key}`);
        }
      }

      commit('setTaskAttr', isTask);
      // commit('showDialog');
    },

    async saveNode({ commit, getters }, bookId) {
      try {
        commit('setActionStatus', STATES.LOADING);
        const newNode = getters.getNode;

        const newId = await saveBookNode({
          parentId: newNode.parentId,
          title: newNode.nodeName,
          typeId: newNode.nodeType,
          order: newNode.order,
          page: newNode.nodePage,
          bookId: bookId,
        });

        commit('setNodeProperty', { key: 'id', value: newId.toObject().value });
        commit('hideDialog');
        commit('setActionStatus', STATES.LOADED);
        console.log('Node saved to parent:', newNode.parentId, newId.toObject());
      } catch (error) {
        commit('setActionStatus', STATES.ERROR);
        throw error;
      }
    },
    
    async removeNode({ commit, getters }) {
      try {
        commit('setActionStatus', STATES.LOADING);
        const nodeId = getters.getNode.id;

        await deleteBookNode(nodeId);
        
        commit('hideDialog');
        commit('setActionStatus', STATES.LOADED);
      } catch (error) {
        commit('setActionStatus', STATES.ERROR);
        throw error;
      }
    },

    async updateNode({ commit, getters }, bookId) {
      try {
        commit('setActionStatus', STATES.LOADING);
        const node = getters.getNode;

        await editBookNode({ bookId, node });

        commit('hideDialog');
        commit('setActionStatus', STATES.LOADED);
      } catch (error) {
        commit('setActionStatus', STATES.ERROR);
        throw error;
      }
    }
  }
}