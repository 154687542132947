/**
 * @fileoverview gRPC-Web generated client stub for kazatel.moderation
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.23.1
// source: moderation/moderation_service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var taskreader_taskreader_pb = require('../taskreader/taskreader_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var google_type_datetime_pb = require('../google/type/datetime_pb.js')
const proto = {};
proto.kazatel = {};
proto.kazatel.moderation = require('./moderation_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.kazatel.moderation.ModerationServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.kazatel.moderation.ModerationServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.moderation.ContentRequest,
 *   !proto.kazatel.moderation.Task>}
 */
const methodDescriptor_ModerationService_Content = new grpc.web.MethodDescriptor(
  '/kazatel.moderation.ModerationService/Content',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.kazatel.moderation.ContentRequest,
  proto.kazatel.moderation.Task,
  /**
   * @param {!proto.kazatel.moderation.ContentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.moderation.Task.deserializeBinary
);


/**
 * @param {!proto.kazatel.moderation.ContentRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.moderation.Task>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.moderation.ModerationServiceClient.prototype.content =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.moderation.ModerationService/Content',
      request,
      metadata || {},
      methodDescriptor_ModerationService_Content);
};


/**
 * @param {!proto.kazatel.moderation.ContentRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.moderation.Task>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.moderation.ModerationServicePromiseClient.prototype.content =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.moderation.ModerationService/Content',
      request,
      metadata || {},
      methodDescriptor_ModerationService_Content);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ModerationService_InWork = new grpc.web.MethodDescriptor(
  '/kazatel.moderation.ModerationService/InWork',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.StringValue,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.moderation.ModerationServiceClient.prototype.inWork =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.moderation.ModerationService/InWork',
      request,
      metadata || {},
      methodDescriptor_ModerationService_InWork,
      callback);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.moderation.ModerationServicePromiseClient.prototype.inWork =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.moderation.ModerationService/InWork',
      request,
      metadata || {},
      methodDescriptor_ModerationService_InWork);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.moderation.ReturnRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ModerationService_Return = new grpc.web.MethodDescriptor(
  '/kazatel.moderation.ModerationService/Return',
  grpc.web.MethodType.UNARY,
  proto.kazatel.moderation.ReturnRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.kazatel.moderation.ReturnRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.kazatel.moderation.ReturnRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.moderation.ModerationServiceClient.prototype.return =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.moderation.ModerationService/Return',
      request,
      metadata || {},
      methodDescriptor_ModerationService_Return,
      callback);
};


/**
 * @param {!proto.kazatel.moderation.ReturnRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.moderation.ModerationServicePromiseClient.prototype.return =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.moderation.ModerationService/Return',
      request,
      metadata || {},
      methodDescriptor_ModerationService_Return);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ModerationService_Publish = new grpc.web.MethodDescriptor(
  '/kazatel.moderation.ModerationService/Publish',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.StringValue,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.moderation.ModerationServiceClient.prototype.publish =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.moderation.ModerationService/Publish',
      request,
      metadata || {},
      methodDescriptor_ModerationService_Publish,
      callback);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.moderation.ModerationServicePromiseClient.prototype.publish =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.moderation.ModerationService/Publish',
      request,
      metadata || {},
      methodDescriptor_ModerationService_Publish);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.kazatel.moderation.HistoryResponce>}
 */
const methodDescriptor_ModerationService_History = new grpc.web.MethodDescriptor(
  '/kazatel.moderation.ModerationService/History',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.StringValue,
  proto.kazatel.moderation.HistoryResponce,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.moderation.HistoryResponce.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.moderation.HistoryResponce)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.moderation.HistoryResponce>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.moderation.ModerationServiceClient.prototype.history =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.moderation.ModerationService/History',
      request,
      metadata || {},
      methodDescriptor_ModerationService_History,
      callback);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.moderation.HistoryResponce>}
 *     Promise that resolves to the response
 */
proto.kazatel.moderation.ModerationServicePromiseClient.prototype.history =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.moderation.ModerationService/History',
      request,
      metadata || {},
      methodDescriptor_ModerationService_History);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ModerationService_Blocking = new grpc.web.MethodDescriptor(
  '/kazatel.moderation.ModerationService/Blocking',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.StringValue,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.moderation.ModerationServiceClient.prototype.blocking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.moderation.ModerationService/Blocking',
      request,
      metadata || {},
      methodDescriptor_ModerationService_Blocking,
      callback);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.moderation.ModerationServicePromiseClient.prototype.blocking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.moderation.ModerationService/Blocking',
      request,
      metadata || {},
      methodDescriptor_ModerationService_Blocking);
};


module.exports = proto.kazatel.moderation;

