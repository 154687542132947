<template>
  <div
    v-if="!accepted && !hideBanner"
    ref="cookieBanner"
    :class="{ 'cookie-banner': true, 'show-banner': !hideBanner }"
    class="cookie-banner"
  >
    <div class="cookie-banner__content">
      <paragraph-size-large
        :textColor="'var(--text-black)'"
      >
        Мы используем куки, потому что без них сайты работают плохо
      </paragraph-size-large>
      <button class="content__button" @click="acceptCookies">Хорошо</button>
    </div>
  </div>
</template>

<script>
import ParagraphSizeLarge from '@/components/UI/texts/paragraphs/ParagraphSizeLarge.vue';

export default {
  name: 'cookie-notify',
  components: {
    ParagraphSizeLarge
  },
  data() {
    return {
      accepted: false,
      hideBanner: false,
    };
  },
  mounted() {
    const accepted = localStorage.getItem('cookiesAccepted');
    if (!accepted) {
      setTimeout(() => {
        this.showBanner();
      }, 3000);
    } else {
      this.hideBanner = true;
    }
  },
  methods: {
    acceptCookies() {
      this.accepted = true;
      localStorage.setItem('cookiesAccepted', true);
      localStorage.setItem('cookiesLastAccept', new Date());
      this.hideBanner = true;
    },
    showBanner() {
      this.hideBanner = false;
      const bannerElement = this.$refs.cookieBanner;
      if (bannerElement) {
        bannerElement.classList.add('show-banner');
      }
    }
  }
};
</script>


<style scoped>
.cookie-banner {
  position: fixed;
  bottom: -100px;
  padding: 0px;
  transition: bottom 0.5s ease;
  border-radius: 8px;
  max-width: 992px;
  width: 100%;
  z-index: 9999;
  box-shadow: 4px 0px 16px 0px rgba(74, 74, 74, 0.15);
  align-self: center;
}
.cookie-banner__content{
  background-color: var(--white);
  padding: 16px;
  display: flex;
  flex-direction: row;
  max-width: 1272px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
}
.content__button{
  color: var(--text-black);
  padding: 11px 21px 13px 20px;
  background-color: var(--background-light);
  border: none;
  border-radius: 6px;

  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  transition: .3s ease-in-out;
}

.content__button:hover{
  background-color: var(--background-light-20);
  transition: .3s ease-in-out;
}
.show-banner {
  bottom: 10px;
}

@media screen and (max-width: 1200px) {
  .cookie-banner {
    max-width: 1140px;
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 992px) {
  .cookie-banner {
    max-width: 720px;
  }
}
@media screen and (max-width: 768px) {
  .cookie-banner {
    max-width: 540px;
  }
  .cookie-banner__content{
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
  }
  .show-banner {
    bottom: 0px;
  }
}
</style>
