<template>
  <div class="popup" v-if="isPopupShown" v-bind:class="{ 'popup_opened': isPopupShown }">
    <card-background class="popup_type_solutions">
      <div class="popup__heading-wrapper" v-if="!isAlreadySent">
        <heading-size-h2 :textColor="'var(--text-black)'" textWeight="600">
          Жалоба на контент
        </heading-size-h2>
        <button-close @click="closePopup" />
      </div>
      <form class="form" @submit.prevent="handleSubmit" v-if="!isAlreadySent">
        <paragraph-size-medium :textWeight="'600'" :textColor="'var(--text-black)'">
          Предоставьте нам информацию по жалобе и мы ее обязательно рассмотрим
        </paragraph-size-medium>
        <div class="popup__inputs-wrapper">
          <select-component v-model:value="selectedValue" label="Тип жалобы">
            <option v-for="option in options" :value="option">{{ option }}</option>
          </select-component>
          <text-area-component v-model:value="comment"
            placeholder="Укажите причину обращения, ссылки на контент и всё, что посчитаете нужным" />
        </div>
        <button-primary type="submit">Отправить жалобу</button-primary>
      </form>

      <div class="sent-wrapper" v-if="sentSuccessfully">
        <div class="popup__heading-wrapper">
          <heading-size-h2 :textColor="'var(--text-black)'" textWeight="600">
            Заявка успешно отправлена
          </heading-size-h2>
          <button-close @click="closePopup" />
        </div>
        <paragraph-size-medium :textWeight="'600'" :textColor="'var(--text-black)'">
          Спасибо за&nbsp;вашу заявку! Наши специалисты вернуться с&nbsp;обратной связью в&nbsp;течение 3-х рабочих дней
        </paragraph-size-medium>
        <button-primary @click="closePopup">Буду ждать</button-primary>
      </div>
    </card-background>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import ParagraphSizeLarge from '@/components/UI/texts/paragraphs/ParagraphSizeLarge.vue'
import HeadingSizeH2 from '@/components/UI/texts/headings/HeadingSizeH2.vue'
import ButtonClose from '@/components/UI/buttons/ButtonClose.vue';
import ButtonText from '@/components/UI/buttons/ButtonText.vue';
import ButtonPrimary from '@/components/UI/buttons/ButtonPrimary.vue';
import ParagraphSizeMedium from '@/components/UI/texts/paragraphs/ParagraphSizeMedium.vue';
import InputComponent from '@/components/UI/inputs/InputComponent.vue';
import CardBackground from '@/components/UI/card/CardBackground.vue';
import SelectComponent from '@/components/UI/inputs/SelectComponent.vue';
import TextAreaComponent from '@/components/UI/inputs/TextAreaComponent.vue';
import useTaskTypes from '@/views/tasks/hooks/useTaskTypes.js';

const { MailsSenderClient } = require('@/generated/mailsender/mailsender_grpc_web_pb.js');

export default {
  name: 'popup-report',
  components: {
    ParagraphSizeLarge,
    HeadingSizeH2,
    ButtonClose,
    ButtonText,
    ButtonPrimary,
    ParagraphSizeMedium,
    InputComponent,
    CardBackground,
    SelectComponent,
    TextAreaComponent
  },
  props: {
    taskId: {
      type: String || Number,
      required: true
    },
    taskType: {
      type: Number,
      required: true
    },
    isPopupShown: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closePopup'],

  setup() {
    const { TASK_TYPES } = useTaskTypes();
    return { TASK_TYPES }
  },

  data() {
    return {
      comment: '',
      selectedValue: null,
      options: [
        'Авторские права',
        'Ошибка в контенте'
      ],
      isAlreadySent: false,
      sentSuccessfully: false,
      serviceURL: process.env.VUE_APP_REGISTRATION_SERVICE_URL,
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'userData/getUserData'
    })
  },
  methods: {
    closePopup() {
      this.$emit('closePopup');
      this.isAlreadySent = false;
      this.sentSuccessfully = false;
    },
    handleSubmit() {
      const selectedOption = this.selectedValue
      const request = {
        requestType: selectedOption,
        comment: this.comment,
      }
      this.selectedValue = ''
      this.comment = ''
      try {
        const now = new Date();

        let type = 'Неизвестный тип';
        switch (this.taskType) {
          case this.TASK_TYPES.SOLUTION:
            type = 'Решение задачи';
            break;
          case this.TASK_TYPES.CONDITION:
            type = 'Текст задачи';
            break;
          case this.TASK_TYPES.AUDIO:
            type = 'Аудио объяснение решения';
            break;
          case this.TASK_TYPES.VIDEO:
            type = 'Видео объяснение решения';
            break;
        }

        const mailSender = new MailsSenderClient(this.serviceURL, null, null);
        const requestSendMail = new proto.kazatel.mailsender.Email();
        requestSendMail.setEmail('info@kazatel.su');
        requestSendMail.setSubject(request.requestType
          + ' / ' + this.getUser.login
          + ' / ' + this.taskId
          + ' / ' + type
          + ' / ' + now
        );
        requestSendMail.setText(request.comment
          + '\n\n' + document.URL
          + '\n\n' + this.getUser.firstName + ' ' + this.getUser.lastName
          + '\n' + this.getUser.email);
        // console.log(this.getUser, requestSendMail.toObject());
        // return;
        this.isAlreadySent = true;
        this.sentSuccessfully = true;
        mailSender.sendMail(requestSendMail, null, this.resultHandlerSend);
      } catch (e) {
        console.error('sendMail:', e);
        alert('Ошибка отправки информации на электронную почту');
      }
    },
    resultHandlerSend(err) {
      if (err) {
        let errMsg = null;
        let isAlert = false;
        switch (err.code) {
          case 2:
            errMsg = 'Сервис недоступен\n' + err.message;
            isAlert = true;
            break;
          case 14:
            errMsg = 'Сервис отправки электронных писем недоступен\n' + err.message;
            isAlert = true;
            break;
          default:
            errMsg = `Code: ${err.code}\n message: ${err.message}`;
          //isAlert = true;
        }
        console.error(errMsg);
        if (isAlert) {
          window.alert(errMsg);
        }
      } else {
      }
    },
  },
}
</script>

<style scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease, visibility 0s linear .5s;
  -webkit-transition: opacity .5s ease, visibility 0s linear .5s;
  -moz-transition: opacity .5s ease, visibility 0s linear .5s;
  -ms-transition: opacity .5s ease, visibility 0s linear .5s;
  -o-transition: opacity .5s ease, visibility 0s linear .5s;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
}

.popup_opened {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transition: opacity .5s ease, visibility 0s linear 0s;
  -webkit-transition: opacity .5s ease, visibility 0s linear 0s;
  -moz-transition: opacity .5s ease, visibility 0s linear 0s;
  -ms-transition: opacity .5s ease, visibility 0s linear 0s;
  -o-transition: opacity .5s ease, visibility 0s linear 0s;
}

.popup__heading-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.sent-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.popup_type_solutions {
  border-radius: 12px;
  background-color: var(--white);
  padding: 24px 32px 28px 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 516px;
}

.popup__inputs-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
</style>
