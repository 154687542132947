<template>
<div
  :style="{ backgroundColor: bgColor }"
  class="background-card"
>
  <slot></slot>
</div>
</template>
<script>
export default {
  name: 'card-background',
  props: {
    bgColor: {
      type: String,
      default: "var(--white)"
    },
    bgImage: {
      type: String,
      default: ''
    }
  }
}
</script>
<style scoped>
  .background-card {
    padding: 32px;
    background-color: var(--white);
    border: none;
    border-radius: 8px;
    box-shadow: 4px 0px 16px 0px rgba(74, 74, 74, 0.15);
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    .background-card{
      padding: 24px 16px 28px 16px;
    }
  }
  @media screen and (max-width: 586px) {
    .background-card{
      padding: 20px 16px 24px 16px;
    }
  }
</style>