<template>
  <div class="layout-wrapper">
    <header-common/>
    <div class="layout-wrapper__main">
      <card-background class="nav__wrapper">
        <tabs-navigation
          :tabs="docLinks"
        />
      </card-background>
      <main class="main">
        <router-view />
      </main>
    </div>
    <footer-component
      :cooperation="cooperation"
      :documents="documents"
      :commonLinks="common"
      :socials="socials"
    />
</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import HeaderCommon from '@/components/common/Header';
import FooterComponent from '@/components/common/FooterComponent';

import CardBackground from '@/components/UI/card/CardBackground.vue';
import TabsNavigation from '@/components/KazUI/atoms/tabsNavigation';
import KazLoader from '@/components/KazUI/atoms/loader';

import { documents, common, cooperation, socials } from '@/components/common/FooterComponent/links.js';


export default {
  name: 'docs-layout',

  components: {
    FooterComponent,
    CardBackground,
    TabsNavigation,
    HeaderCommon,
    KazLoader,
},

  data() {
    return {
      serviceURL: process.env.VUE_APP_REGISTRATION_SERVICE_URL,
      windowWidth: window.innerWidth,
      isLoading: true,

      // for FooterComponent
      documents,
      common,
      cooperation,
      socials,
      docLinks: documents.map(d => ({
        ...d, 
        iconName: 'kuiIconFileLarge', 
        disabled: false
      }))
    }
  },

  computed: {
    ...mapGetters({
      getGrades: 'gradeData/getGrades',
      sessionActive: 'authData/sessionActive',
    })
  },

  methods: {
    ...mapActions({
      fetchGrades: 'gradeData/fetchGrades',
      fetchCourses: 'courseData/fetchCourses',
      fetchUser: 'userData/fetchUser',
      fetchUserRoles: 'userData/fetchUserRoles'
    }),

    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },

    async getMenuGrades() {
      try {
        if (!this.getGrades.length) {
          await this.fetchGrades();
        }
        await this.fetchCourses();
      } catch(err) {
        console.error('Error in getMenuGrades:', err);
      }
    },

    async getUser() {
      try {
        await this.fetchUser();
      }
      catch(err) {
        console.log('Error in getUser:', err);
      }
    },

    async getRoles() {
      try {
        await this.fetchUserRoles();
      }
      catch(err) {
        console.log('Error in getRoles:', err);
      }
    },
  },

  async created() {
    await this.getMenuGrades();
    if (this.sessionActive) {
      console.warn('session is active, fetching user data...');
      await this.getUser();
      await this.getRoles();
      this.isLoading = false
    } else {
      console.warn('session is nonactive');
    }
  },

  mounted() {
    window.addEventListener('resize', this.updateWindowWidth);
  }
}
</script>

<style scoped>
@import url('./styles/base.css');
</style>
