<template>
  <div class="wrapper">
    <div class="order__info_item">
      <p class="label label_size_M">Модератор:</p>
      <p class="label label_size_M">{{ orderInfo.currentModerator? orderInfo.currentModerator : '-' }}</p>
    </div>
    <div class="order__info_item">
      <p class="label label_size_M">Автор решения:</p>
      <p class="label label_size_M">{{ orderInfo.author }}</p>
    </div>
    <div class="order__info_item">
      <p class="label label_size_M">Статус:</p>
      <div class="status__wrapper">
        <div :class="['status__circle', `status__circle_${orderInfo.status}`]"></div>
        <p class="label label_size_M">
          {{ statesNames[orderInfo.status] }}
        </p>
      </div>
    </div>
    <div class="order__info_item">
      <p class="label label_size_M">Тип контента:</p>
      <p class="label label_size_M">{{ orderInfo.type }}</p>
    </div>
    <div class="order__info_item order__info_item_column">
      <p class="label label_size_M">Время:</p>
      <div class="item__column">
        <div class="item__wrapper">
          <p class="label label_size_S">время создания запроса:</p>
          <p class="label label_size_M">{{ orderInfo.time.creation }}</p>
        </div>
        <div class="item__wrapper">
          <p class="label label_size_S label_size">время окончания модерации:</p>
          <p class="label label_size_M">{{ orderInfo.time.end ? orderInfo.time.end : '-' }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import STATES from '@/components/moderation/utils/states.js';
import STATES_NAMES from '@/components/moderation/utils/statesNames.js';

export default{
  name: 'order-status',
  components:{

  },
  data(){
    return{
      statesNames: Object.keys(STATES_NAMES).map(k => STATES_NAMES[k]),
    }
  },
  props:{
    orderInfo:{
      type: Object,
      default: {},
      required: true
    }
  }
}
</script>

<style scoped>
.wrapper{
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.order__info_item{
  display: flex;
  flex-direction: row;
  gap: 32px;
}
.order__info_item p:first-of-type{
  width: 180px;
  color: var(--kaz-textIcons-text-02);
}
.order__info_item_column{
  flex-direction: column;
  gap: 4px
}
.item__wrapper{
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.item__wrapper p:first-of-type{
  color: var(--kaz-textIcons-text-03);
}
.label_size{
  width: fit-content !important;
}
.status__wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.status__wrapper p{
  color: var(--kaz-textIcons-text-01) !important;
}
.status__circle{
  width: 8px;
  height: 8px;
  border-radius: 100px;
}
.status__circle_0{
  background: var(--kaz-status-info-fill);
}
.status__circle_1{
  background: var(--kaz-status-neutral-fill);
}
.status__circle_2{
  background: var(--kaz-status-warning-fill);
}
.status__circle_3{
  background: var(--kaz-status-success-fill);
}
.status__circle_4{
  background: var(--kaz-status-error-fill);
}
</style>