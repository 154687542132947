<template>
<h2
  :style="{ color: textColor }"
  class="heading heading_type_H2"
>
  <slot></slot>
</h2>
</template>

<script>
export default {
  name: 'heading-size-h2',
  props: {
    textColor: {
      type: String,
      default: "var(--text-black)"
    },
  }
};
</script>

<style scoped>
.heading_type_H2{
  font-size: 25px;
  font-weight: 600;
  /* line-height: 100%; */
  line-height: 30px;
  letter-spacing: 0.24px;
  color: var(--text-black);
}
</style>