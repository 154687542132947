<template>
  <div v-if="isActive" class="spinner">
    <div class="spinner__circle" role="status">
      <span class="spinner__hidden-text">{{ title }}</span>
    </div>
    <span class="spinner__text">{{ title }}</span>
  </div>
  <template v-else>
    <slot></slot>
  </template>
</template>

<script>
export default {
  name: 'custom-spinner',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Загрузка...'
    },
    width: {
      type: String,
      default: '36px'
    },
    color: {
      type: String,
      default: 'var(--background-red)'
    }
  }
}
</script>

<style scoped>
.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.spinner__circle {
  display: inline-block;
  width: v-bind(width);
  aspect-ratio: 1;
  background-color: transparent;
  border-radius: 50%;
  border: 5px solid v-bind(color);
  border-right-color: transparent;
  animation: spin 0.8s linear infinite;
}

.spinner__hidden-text {
  display: inline-block;
  width: 1px;
  height: 1px;
  padding: 0;
  clip: rect(0,0,0,0);
  overflow: hidden;
  white-space: nowrap;
  border: none;
}

.spinner__text {
  color: var(--text-secondary);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
