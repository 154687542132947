import taskTypes from "../utils/taskTypes.js";


const BACK_TO_FRONT_TYPES = Object.freeze({
  1: taskTypes.CONDITION,
  2: taskTypes.SOLUTION,
  3: taskTypes.AUDIO,
  4: taskTypes.VIDEO,
  5: taskTypes.LESSON,
})

export default function() {
  return {
    TASK_TYPES: taskTypes,
    BACK_TO_FRONT_TYPES,
  }
}