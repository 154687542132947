import STATES from "@/utils/states.js";
import CONTENT_TYPES from '@/utils/contentTypes.js';


export const multistepUpload = {
  namespaced: true,

  state: () => ({
    multistepUploadState: {
      [CONTENT_TYPES.AUDIO] : STATES.INIT,
      [CONTENT_TYPES.VIDEO] : STATES.INIT,
    }
  }),

  getters: {
    getMultistepUploadState(state) {
      return state.multistepUploadState;
    },
    isUploaded(state) {
      for (const contentType in state.multistepUploadState) {
        if (state.multistepUploadState[contentType] === STATES.LOADING) return false
      }
      return true
    }
  },

  mutations: {
    setMultistepUploadState(state, { contentType, uploadState }) {
      if (contentType in state.multistepUploadState) {
        state.multistepUploadState[contentType] = uploadState;
      } else {
        const errMsg = `Content type ${contentType} not found`
        console.error(errMsg);
        throw new Error(errMsg);
      }
    }
  },

  actions: {
    resetMultistepUploadState({ commit }, { contentType }) {
      commit('setMultistepUploadState', { contentType, uploadState: STATES.INIT });
    },
    async makeUploadRequest({ commit }, { contentType, callback }) {
      let result = null;
      commit('setMultistepUploadState', { contentType, uploadState: STATES.LOADING });
      try {
        result = await callback();
        console.warn('callback:', result);
        commit('setMultistepUploadState', { contentType, uploadState: STATES.LOADED });
      } catch (error) {
        commit('setMultistepUploadState', { contentType, uploadState: STATES.ERROR });
      }
      return result
    }
  }
};
