import DetailedStatsTable from '@/views/monetization/DetailedStatsTable.vue'

const routes = [
  {
    path: '/denezhki',
    name: 'monetization',
    meta: {layout: 'monetization', auth: true},
    component: () => import('@/views/monetization/MonetizationHome.vue'),
  },
  {
    path: '/denezhki/profile',
    name: 'profile',
    meta: {layout: 'monetization', auth: true},
    component: () => import('@/views/monetization/MonetizationProfile.vue'),
  },
  {
    path: '/denezhki/reports',
    name: 'reports',
    meta: {layout: 'monetization', auth: true},
    component: () => import('@/views/monetization/MonetizationReports.vue'),
  },
  {
    path: '/denezhki/reports/:rid/table',
    name: 'report-table',
    meta: {layout: 'monetization', auth: true},
    component: () => import('@/views/monetization/DetailedReport.vue'),
  },
  {
    path: '/denezhki/stats',
    name: 'stats',
    meta: {layout: 'monetization', auth: true},
    component: () => import('@/views/monetization/MonetizationStats.vue'),
    // children: [
    //   {
    //     path: '/denezhki/stats/table',
    //     component: DetailedStatsTable,
    //   },
    // ],
  },
  {
    path: '/denezhki/stats/table',
    name: 'table',
    meta: {layout: 'monetization', auth: true},
    component: () => import('@/views/monetization/DetailedStatsTable.vue'),
  },
]

export default routes
