<template>
  <button class="button button_type_secondary" :type="type">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'button-secondary',

  props: {
    type: {
      type: String,
      default: "button",
    },
  }
};
</script>

<style src="./button.css"></style>
<style scoped>
  .button_type_secondary {
    padding: 16px 16px;
    border-radius: 8px;
    background-color: transparent;
    border: 2px solid var(--background-brown);
    color: var(--background-brown);
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 130%;
    transition: 0.3s;
    width: 100%;
  }
  .button_type_secondary:hover {
    background: var(--background-red);
    border: 2px solid var(--background-red);
    transition: 0.3s;
    color: var(--white);
  }
  .button_type_secondary:disabled {
    opacity: .5;
  }
  .button_type_secondary:disabled:hover {
    opacity: .5;
    background-color: transparent;
  }
</style>
