const routes = [
  {
    path: '/hidden-main',
    name: 'main',
    meta: { layout: "profile", auth: false },
    component: () => import('@/views/home/Home.vue')
  },
]

export default routes
