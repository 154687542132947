// source: moderation/moderation_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var taskreader_taskreader_pb = require('../taskreader/taskreader_pb.js');
goog.object.extend(proto, taskreader_taskreader_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_type_datetime_pb = require('../google/type/datetime_pb.js');
goog.object.extend(proto, google_type_datetime_pb);
goog.exportSymbol('proto.kazatel.moderation.ContentRequest', null, global);
goog.exportSymbol('proto.kazatel.moderation.HistoryResponce', null, global);
goog.exportSymbol('proto.kazatel.moderation.HistoryUnit', null, global);
goog.exportSymbol('proto.kazatel.moderation.ReturnRequest', null, global);
goog.exportSymbol('proto.kazatel.moderation.Task', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.moderation.ContentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.moderation.ContentRequest.repeatedFields_, null);
};
goog.inherits(proto.kazatel.moderation.ContentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.moderation.ContentRequest.displayName = 'proto.kazatel.moderation.ContentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.moderation.Task = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.moderation.Task, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.moderation.Task.displayName = 'proto.kazatel.moderation.Task';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.moderation.ReturnRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.moderation.ReturnRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.moderation.ReturnRequest.displayName = 'proto.kazatel.moderation.ReturnRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.moderation.HistoryUnit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.moderation.HistoryUnit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.moderation.HistoryUnit.displayName = 'proto.kazatel.moderation.HistoryUnit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.moderation.HistoryResponce = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.moderation.HistoryResponce.repeatedFields_, null);
};
goog.inherits(proto.kazatel.moderation.HistoryResponce, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.moderation.HistoryResponce.displayName = 'proto.kazatel.moderation.HistoryResponce';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.moderation.ContentRequest.repeatedFields_ = [1,2,3,4,5,6,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.moderation.ContentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.moderation.ContentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.moderation.ContentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.moderation.ContentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    contentIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    statusIdList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    typeIdList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    subjectIdList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    levelIdList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    like: jspb.Message.getFieldWithDefault(msg, 7, ""),
    userIdList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.moderation.ContentRequest}
 */
proto.kazatel.moderation.ContentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.moderation.ContentRequest;
  return proto.kazatel.moderation.ContentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.moderation.ContentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.moderation.ContentRequest}
 */
proto.kazatel.moderation.ContentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addContentId(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatusId(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTypeId(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSubjectId(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLevelId(values[i]);
      }
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLike(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.moderation.ContentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.moderation.ContentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.moderation.ContentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.moderation.ContentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getContentIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getStatusIdList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
  f = message.getTypeIdList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
  f = message.getSubjectIdList();
  if (f.length > 0) {
    writer.writePackedInt32(
      5,
      f
    );
  }
  f = message.getLevelIdList();
  if (f.length > 0) {
    writer.writePackedInt32(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUserIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
};


/**
 * repeated string task_id = 1;
 * @return {!Array<string>}
 */
proto.kazatel.moderation.ContentRequest.prototype.getTaskIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.kazatel.moderation.ContentRequest} returns this
 */
proto.kazatel.moderation.ContentRequest.prototype.setTaskIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.moderation.ContentRequest} returns this
 */
proto.kazatel.moderation.ContentRequest.prototype.addTaskId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.moderation.ContentRequest} returns this
 */
proto.kazatel.moderation.ContentRequest.prototype.clearTaskIdList = function() {
  return this.setTaskIdList([]);
};


/**
 * repeated string content_id = 2;
 * @return {!Array<string>}
 */
proto.kazatel.moderation.ContentRequest.prototype.getContentIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.kazatel.moderation.ContentRequest} returns this
 */
proto.kazatel.moderation.ContentRequest.prototype.setContentIdList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.moderation.ContentRequest} returns this
 */
proto.kazatel.moderation.ContentRequest.prototype.addContentId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.moderation.ContentRequest} returns this
 */
proto.kazatel.moderation.ContentRequest.prototype.clearContentIdList = function() {
  return this.setContentIdList([]);
};


/**
 * repeated int32 status_id = 3;
 * @return {!Array<number>}
 */
proto.kazatel.moderation.ContentRequest.prototype.getStatusIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.kazatel.moderation.ContentRequest} returns this
 */
proto.kazatel.moderation.ContentRequest.prototype.setStatusIdList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.moderation.ContentRequest} returns this
 */
proto.kazatel.moderation.ContentRequest.prototype.addStatusId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.moderation.ContentRequest} returns this
 */
proto.kazatel.moderation.ContentRequest.prototype.clearStatusIdList = function() {
  return this.setStatusIdList([]);
};


/**
 * repeated int32 type_id = 4;
 * @return {!Array<number>}
 */
proto.kazatel.moderation.ContentRequest.prototype.getTypeIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.kazatel.moderation.ContentRequest} returns this
 */
proto.kazatel.moderation.ContentRequest.prototype.setTypeIdList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.moderation.ContentRequest} returns this
 */
proto.kazatel.moderation.ContentRequest.prototype.addTypeId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.moderation.ContentRequest} returns this
 */
proto.kazatel.moderation.ContentRequest.prototype.clearTypeIdList = function() {
  return this.setTypeIdList([]);
};


/**
 * repeated int32 subject_id = 5;
 * @return {!Array<number>}
 */
proto.kazatel.moderation.ContentRequest.prototype.getSubjectIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.kazatel.moderation.ContentRequest} returns this
 */
proto.kazatel.moderation.ContentRequest.prototype.setSubjectIdList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.moderation.ContentRequest} returns this
 */
proto.kazatel.moderation.ContentRequest.prototype.addSubjectId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.moderation.ContentRequest} returns this
 */
proto.kazatel.moderation.ContentRequest.prototype.clearSubjectIdList = function() {
  return this.setSubjectIdList([]);
};


/**
 * repeated int32 level_id = 6;
 * @return {!Array<number>}
 */
proto.kazatel.moderation.ContentRequest.prototype.getLevelIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.kazatel.moderation.ContentRequest} returns this
 */
proto.kazatel.moderation.ContentRequest.prototype.setLevelIdList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.moderation.ContentRequest} returns this
 */
proto.kazatel.moderation.ContentRequest.prototype.addLevelId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.moderation.ContentRequest} returns this
 */
proto.kazatel.moderation.ContentRequest.prototype.clearLevelIdList = function() {
  return this.setLevelIdList([]);
};


/**
 * optional string like = 7;
 * @return {string}
 */
proto.kazatel.moderation.ContentRequest.prototype.getLike = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.moderation.ContentRequest} returns this
 */
proto.kazatel.moderation.ContentRequest.prototype.setLike = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.moderation.ContentRequest} returns this
 */
proto.kazatel.moderation.ContentRequest.prototype.clearLike = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.moderation.ContentRequest.prototype.hasLike = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated string user_id = 8;
 * @return {!Array<string>}
 */
proto.kazatel.moderation.ContentRequest.prototype.getUserIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.kazatel.moderation.ContentRequest} returns this
 */
proto.kazatel.moderation.ContentRequest.prototype.setUserIdList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.moderation.ContentRequest} returns this
 */
proto.kazatel.moderation.ContentRequest.prototype.addUserId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.moderation.ContentRequest} returns this
 */
proto.kazatel.moderation.ContentRequest.prototype.clearUserIdList = function() {
  return this.setUserIdList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.moderation.Task.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.moderation.Task.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.moderation.Task} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.moderation.Task.toObject = function(includeInstance, msg) {
  var f, obj = {
    content: (f = msg.getContent()) && taskreader_taskreader_pb.TaskContent.toObject(includeInstance, f),
    node: (f = msg.getNode()) && taskreader_taskreader_pb.Node.toObject(includeInstance, f),
    book: (f = msg.getBook()) && taskreader_taskreader_pb.Book.toObject(includeInstance, f),
    owner: jspb.Message.getFieldWithDefault(msg, 4, ""),
    moderator: jspb.Message.getFieldWithDefault(msg, 5, ""),
    moderationBegin: (f = msg.getModerationBegin()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    moderationEnd: (f = msg.getModerationEnd()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.moderation.Task}
 */
proto.kazatel.moderation.Task.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.moderation.Task;
  return proto.kazatel.moderation.Task.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.moderation.Task} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.moderation.Task}
 */
proto.kazatel.moderation.Task.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new taskreader_taskreader_pb.TaskContent;
      reader.readMessage(value,taskreader_taskreader_pb.TaskContent.deserializeBinaryFromReader);
      msg.setContent(value);
      break;
    case 2:
      var value = new taskreader_taskreader_pb.Node;
      reader.readMessage(value,taskreader_taskreader_pb.Node.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 3:
      var value = new taskreader_taskreader_pb.Book;
      reader.readMessage(value,taskreader_taskreader_pb.Book.deserializeBinaryFromReader);
      msg.setBook(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwner(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setModerator(value);
      break;
    case 6:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setModerationBegin(value);
      break;
    case 7:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setModerationEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.moderation.Task.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.moderation.Task.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.moderation.Task} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.moderation.Task.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      taskreader_taskreader_pb.TaskContent.serializeBinaryToWriter
    );
  }
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      taskreader_taskreader_pb.Node.serializeBinaryToWriter
    );
  }
  f = message.getBook();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      taskreader_taskreader_pb.Book.serializeBinaryToWriter
    );
  }
  f = message.getOwner();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getModerator();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getModerationBegin();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getModerationEnd();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional kazatel.books.TaskContent content = 1;
 * @return {?proto.kazatel.books.TaskContent}
 */
proto.kazatel.moderation.Task.prototype.getContent = function() {
  return /** @type{?proto.kazatel.books.TaskContent} */ (
    jspb.Message.getWrapperField(this, taskreader_taskreader_pb.TaskContent, 1));
};


/**
 * @param {?proto.kazatel.books.TaskContent|undefined} value
 * @return {!proto.kazatel.moderation.Task} returns this
*/
proto.kazatel.moderation.Task.prototype.setContent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.moderation.Task} returns this
 */
proto.kazatel.moderation.Task.prototype.clearContent = function() {
  return this.setContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.moderation.Task.prototype.hasContent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional kazatel.books.Node node = 2;
 * @return {?proto.kazatel.books.Node}
 */
proto.kazatel.moderation.Task.prototype.getNode = function() {
  return /** @type{?proto.kazatel.books.Node} */ (
    jspb.Message.getWrapperField(this, taskreader_taskreader_pb.Node, 2));
};


/**
 * @param {?proto.kazatel.books.Node|undefined} value
 * @return {!proto.kazatel.moderation.Task} returns this
*/
proto.kazatel.moderation.Task.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.moderation.Task} returns this
 */
proto.kazatel.moderation.Task.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.moderation.Task.prototype.hasNode = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional kazatel.books.Book book = 3;
 * @return {?proto.kazatel.books.Book}
 */
proto.kazatel.moderation.Task.prototype.getBook = function() {
  return /** @type{?proto.kazatel.books.Book} */ (
    jspb.Message.getWrapperField(this, taskreader_taskreader_pb.Book, 3));
};


/**
 * @param {?proto.kazatel.books.Book|undefined} value
 * @return {!proto.kazatel.moderation.Task} returns this
*/
proto.kazatel.moderation.Task.prototype.setBook = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.moderation.Task} returns this
 */
proto.kazatel.moderation.Task.prototype.clearBook = function() {
  return this.setBook(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.moderation.Task.prototype.hasBook = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string owner = 4;
 * @return {string}
 */
proto.kazatel.moderation.Task.prototype.getOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.moderation.Task} returns this
 */
proto.kazatel.moderation.Task.prototype.setOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string moderator = 5;
 * @return {string}
 */
proto.kazatel.moderation.Task.prototype.getModerator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.moderation.Task} returns this
 */
proto.kazatel.moderation.Task.prototype.setModerator = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.type.DateTime moderation_begin = 6;
 * @return {?proto.google.type.DateTime}
 */
proto.kazatel.moderation.Task.prototype.getModerationBegin = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 6));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.kazatel.moderation.Task} returns this
*/
proto.kazatel.moderation.Task.prototype.setModerationBegin = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.moderation.Task} returns this
 */
proto.kazatel.moderation.Task.prototype.clearModerationBegin = function() {
  return this.setModerationBegin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.moderation.Task.prototype.hasModerationBegin = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.type.DateTime moderation_end = 7;
 * @return {?proto.google.type.DateTime}
 */
proto.kazatel.moderation.Task.prototype.getModerationEnd = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 7));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.kazatel.moderation.Task} returns this
*/
proto.kazatel.moderation.Task.prototype.setModerationEnd = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.moderation.Task} returns this
 */
proto.kazatel.moderation.Task.prototype.clearModerationEnd = function() {
  return this.setModerationEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.moderation.Task.prototype.hasModerationEnd = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.moderation.ReturnRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.moderation.ReturnRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.moderation.ReturnRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.moderation.ReturnRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    contentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.moderation.ReturnRequest}
 */
proto.kazatel.moderation.ReturnRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.moderation.ReturnRequest;
  return proto.kazatel.moderation.ReturnRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.moderation.ReturnRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.moderation.ReturnRequest}
 */
proto.kazatel.moderation.ReturnRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.moderation.ReturnRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.moderation.ReturnRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.moderation.ReturnRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.moderation.ReturnRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string content_id = 1;
 * @return {string}
 */
proto.kazatel.moderation.ReturnRequest.prototype.getContentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.moderation.ReturnRequest} returns this
 */
proto.kazatel.moderation.ReturnRequest.prototype.setContentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string comment = 2;
 * @return {string}
 */
proto.kazatel.moderation.ReturnRequest.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.moderation.ReturnRequest} returns this
 */
proto.kazatel.moderation.ReturnRequest.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.moderation.HistoryUnit.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.moderation.HistoryUnit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.moderation.HistoryUnit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.moderation.HistoryUnit.toObject = function(includeInstance, msg) {
  var f, obj = {
    textValue: jspb.Message.getFieldWithDefault(msg, 1, ""),
    login: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contentId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    created: (f = msg.getCreated()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.moderation.HistoryUnit}
 */
proto.kazatel.moderation.HistoryUnit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.moderation.HistoryUnit;
  return proto.kazatel.moderation.HistoryUnit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.moderation.HistoryUnit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.moderation.HistoryUnit}
 */
proto.kazatel.moderation.HistoryUnit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentId(value);
      break;
    case 4:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.moderation.HistoryUnit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.moderation.HistoryUnit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.moderation.HistoryUnit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.moderation.HistoryUnit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTextValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContentId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string text_value = 1;
 * @return {string}
 */
proto.kazatel.moderation.HistoryUnit.prototype.getTextValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.moderation.HistoryUnit} returns this
 */
proto.kazatel.moderation.HistoryUnit.prototype.setTextValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string login = 2;
 * @return {string}
 */
proto.kazatel.moderation.HistoryUnit.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.moderation.HistoryUnit} returns this
 */
proto.kazatel.moderation.HistoryUnit.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string content_id = 3;
 * @return {string}
 */
proto.kazatel.moderation.HistoryUnit.prototype.getContentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.moderation.HistoryUnit} returns this
 */
proto.kazatel.moderation.HistoryUnit.prototype.setContentId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.type.DateTime created = 4;
 * @return {?proto.google.type.DateTime}
 */
proto.kazatel.moderation.HistoryUnit.prototype.getCreated = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 4));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.kazatel.moderation.HistoryUnit} returns this
*/
proto.kazatel.moderation.HistoryUnit.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.moderation.HistoryUnit} returns this
 */
proto.kazatel.moderation.HistoryUnit.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.moderation.HistoryUnit.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.moderation.HistoryResponce.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.moderation.HistoryResponce.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.moderation.HistoryResponce.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.moderation.HistoryResponce} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.moderation.HistoryResponce.toObject = function(includeInstance, msg) {
  var f, obj = {
    unitList: jspb.Message.toObjectList(msg.getUnitList(),
    proto.kazatel.moderation.HistoryUnit.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.moderation.HistoryResponce}
 */
proto.kazatel.moderation.HistoryResponce.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.moderation.HistoryResponce;
  return proto.kazatel.moderation.HistoryResponce.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.moderation.HistoryResponce} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.moderation.HistoryResponce}
 */
proto.kazatel.moderation.HistoryResponce.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.kazatel.moderation.HistoryUnit;
      reader.readMessage(value,proto.kazatel.moderation.HistoryUnit.deserializeBinaryFromReader);
      msg.addUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.moderation.HistoryResponce.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.moderation.HistoryResponce.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.moderation.HistoryResponce} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.moderation.HistoryResponce.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnitList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.kazatel.moderation.HistoryUnit.serializeBinaryToWriter
    );
  }
};


/**
 * repeated HistoryUnit unit = 1;
 * @return {!Array<!proto.kazatel.moderation.HistoryUnit>}
 */
proto.kazatel.moderation.HistoryResponce.prototype.getUnitList = function() {
  return /** @type{!Array<!proto.kazatel.moderation.HistoryUnit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kazatel.moderation.HistoryUnit, 1));
};


/**
 * @param {!Array<!proto.kazatel.moderation.HistoryUnit>} value
 * @return {!proto.kazatel.moderation.HistoryResponce} returns this
*/
proto.kazatel.moderation.HistoryResponce.prototype.setUnitList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.kazatel.moderation.HistoryUnit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.moderation.HistoryUnit}
 */
proto.kazatel.moderation.HistoryResponce.prototype.addUnit = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.kazatel.moderation.HistoryUnit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.moderation.HistoryResponce} returns this
 */
proto.kazatel.moderation.HistoryResponce.prototype.clearUnitList = function() {
  return this.setUnitList([]);
};


goog.object.extend(exports, proto.kazatel.moderation);
