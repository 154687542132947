<template>
  <div class="card-tip">
    <img src="@/assets/images/card-tip1.svg" class="card-tip__image" />
    <paragraph-size-small :textColor="'var(--white)'">{{ tipText }}</paragraph-size-small>
  </div>
</template>

<script>
import ParagraphSizeSmall from '@/components/UI/texts/paragraphs/ParagraphSizeSmall.vue';

export default{
  name: 'card-tip',
  components: {
    ParagraphSizeSmall,
  },
  props:{
    tipText:{
      type: String,
      required: true,
    }
  }
}
</script>

<style scoped>
.card-tip{
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  background-color: var(--background-green-5);
  border-radius: 4px;
  max-width: fit-content;
}
.card-tip__image{
  width: 14px;
  height: 14px;
}

@media screen and (max-width: 768px) {
  .card-tip__image{
    width: 14px;
    height: 14px;
  }
}
</style>