const auth = false;
const layout = 'moderation';

const routes = [
  {
    path: '/generation/book',
    name: 'book-creation',
    meta: { layout, auth },
    component: () => import('@/views/generation/GenerationBookCreation.vue'),
  },
  {
    path: '/generation/book/:bid',
    name: 'book-edit',
    meta: { layout, auth },
    component: () => import('@/views/generation/GenerationBookEdit.vue'),
  },
  {
    path: '/generation/structure/:bid',
    name: 'structure-edit',
    meta: { layout, auth },
    component: () => import('@/views/generation/GenerationStructureEdit.vue'),
  },
  {
    path: '/generation/structure/v2/:bid',
    name: 'structure-edit-v2',
    meta: { layout, auth },
    component: () => import('@/views/generation/GenerationStructureEditV2.vue'),
  },
  {
    path: '/generation/books',
    name: 'book-list',
    meta: { layout, auth },
    component: () => import('@/views/generation/GenerationBookList.vue'),
  },
  {
    path: '/generation/books/v2',
    name: 'book-list-v2',
    meta: { layout, auth },
    component: () => import('@/views/generation/GenerationBookListV2.vue'),
  },
  {
    path: "/generation/search",
    name: "book-search",
    meta: { layout, auth },
    component: () => import('@/views/generation/GenerationBookSearch.vue')
  },
];

export default routes
