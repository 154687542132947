import { ref, onMounted, } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { extractTokenInfo } from '@/utils/auth';
import { fetchStreamData } from '@/utils/loaders.js';
const {TaskReaderClient} = require('@/generated/taskreader/taskreader_grpc_web_pb.js');


export default function useNodesList(bookId=null) {
  if (!bookId) {
    console.log('Getting book ID from URL params...');
    bookId = useRoute().params.bid;
  } else {
    console.log('Getting book ID from a prop...');
  }
  if (!bookId) {
    throw new Error(`Book ID is empty: ${bookId}`)
  } else {
    console.log(`Book ID: ${bookId}`);
  }

  const store = useStore();
  const nodesList = ref({});
  const isNodesLoading = ref(true);
  const nodesLoadingError = ref(null);

  let taskReaderService = null;
  try {
    taskReaderService = new TaskReaderClient(
      process.env.VUE_APP_REGISTRATION_SERVICE_URL, null, null
    );
  } catch(err) {
    console.error(err);
  }

  const fetchBookNodes = async () => {
    try {
      // console.log('starting fetchBookNodes...');
      const tokens = extractTokenInfo();
      const metadata = { 'token': tokens.accessToken.token };
      const requestNodes = new proto.kazatel.books.NodesRequest();
      requestNodes.setBookId(bookId);
      const streamNodes = taskReaderService.bookNodes(requestNodes, metadata);

      isNodesLoading.value = true;
      nodesLoadingError.value = null;

      await store.dispatch('bookTree/fetchNodeTypes');

      console.log('BOOK NODES FETCHING', Date.now() );
      Object.assign(nodesList.value, {});
      const streamData = await fetchStreamData(streamNodes, 'NODES');
      console.log('BOOK NODES FETCHING AFTER', Date.now() );

      streamData.forEach(response => {
        nodesList.value[response.getId()] = {
          id: response.getId(),
          parentId: response.getParentId(),
          title: response.getTitle(),
          prevId: response.getPrevId(),
          nextId: response.getNextId(),
          typeId: response.getTypeId(),
          children: [],
          content: [],
        };
      });

      console.log('BOOK NODES FETCHED:', Date.now() );
      console.log('BOOK NODES LEN:', Object.keys(nodesList.value).length);
      console.log('BOOK ROW NODES:', streamData.length);
    } catch(err) {
      console.error('fetchBookNodes:', err);
      nodesLoadingError.value = err;
    } finally {
      isNodesLoading.value = false;
    }
  }

  onMounted(fetchBookNodes);

  return {
    nodesList,
    isNodesLoading,
    nodesLoadingError,
  }
}