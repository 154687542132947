<template>
  <kaz-dialog 
    v-if="visible !== null"
    :visible="visible" 
    @close="$emit('close')"
  >
    <h2 class="heading heading_size_h2">Возврат задания на доработку</h2>
    <p class="label label_size_M">Укажите причину отказа в публикации и комментарий </p>
    <form class="comment-section" @submit.prevent="submitForm">
      <input-textarea
        :size="'L'"
        :label="'Комментарий'"
        :value="comment"
        @update:value="comment = $event"
      />
      <kaz-button
        class="condition-ok-button"
        label="Подтвердить"
        mode="normal"
        size="L"
        @click.stop="$emit('close')"
        type="submit"
      />
    </form>

  </kaz-dialog>
</template>

<script>

import Tabs from '@/components/KazUI/atoms/tabs';
import KazDialog from '@/components/KazUI/slots/dialog';
import KazButton from '@/components/KazUI/atoms/button';

import InputDefault from '@/components/KazUI/atoms/inputs/default'
import InputTextarea from '@/components/KazUI/atoms/inputs/textarea'

export default {
  name: 'popup-condition',
  

  components: {
    Tabs,
    KazDialog,
    KazButton,
    InputDefault,
    InputTextarea,
  },

  data() {
    return {
      reason: '',
      comment: ''
    };
  },

  props: {
    conditions: {
      type: Array,
      required: false,
    },
    visible: {
      type: Boolean,
      default: true,
    }
  },

  emits: ['close', 'on-submit'],

  methods: {
    submitForm() {
      this.$emit('on-submit', { reason: this.reason, comment: this.comment });
      this.reason = '';
      this.comment = '';
    },
  },

  computed: {

  }
}
</script>

<style scoped>
.comment-section {
  width: 100%;
  max-height: fit-content;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.comment-section button {
  align-self: flex-end;
  justify-self: flex-end;
}

.condition-ok-button {
  display: block;
  margin-left: auto;
  margin-top: auto;
}

@media screen and (min-width: 768px) {
  .condition-viewer {
    max-height: none;
  }
  .condition-ok-button {
    margin-left: 0;
  }
}

</style>
