<template>
    <header-component/>

    <div class="container px-0">
      <div class="d-flex flex-row gap-0">
        <aside-component :isVisible="visible"/>
        <div class="ephemeral">
          <button 
            class="aside-card__btn"
            :class="{ toggled : !visible}"
            @click.prevent="visible = !visible"
          >
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.0292969" width="32" height="32" rx="16" fill="white"/>
              <path d="M18.0293 11L13.0293 16L18.0293 21" stroke="#9C9CC9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <rect x="1.0293" y="1" width="30" height="30" rx="15" stroke="#9C9CC9" stroke-opacity="0.5" stroke-width="2"/>
            </svg>
          </button>
        </div>

        <main class="main-card">
          <router-view />
        </main>
      </div>
    </div>

    <footer-component
      :grades="getGrades" 
      :courses="getCourses"
      :documents="documents"
      :commonLinks="common"
    />
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import HeaderComponent from '@/components/common/HeaderComponent';
// import FooterComponent from '@/components/common/FooterComponent/FooterComponent.vue';
import AsideComponent from '@/components/common/AsideComponent.vue';

import {fetchStreamData} from '@/utils/loaders';

const {TaskReaderClient, default: books} = require(
  '@/generated/taskreader/taskreader_grpc_web_pb.js'
);

import { documents, common, } from '@/components/common/FooterComponent/links';


export default {
  name: 'profile-layout',
  components: {
    HeaderComponent,
    // FooterComponent,
    AsideComponent,
  },

  data() {
    return {
      visible: true,
      serviceURL: process.env.VUE_APP_REGISTRATION_SERVICE_URL,
      documents, 
      common,
    }
  },

  computed: {
    ...mapGetters({
      getGrades: 'gradeData/getGrades',
      getActiveGradeId: 'gradeData/getActiveGradeId',
      getActiveCourseId: 'gradeData/getActiveCourseId',
      getCourses: 'courseData/getCourses'
    })
  },

  methods: {
    ...mapActions({
      fetchGrades: 'gradeData/fetchGrades',
    }),
    async getMenuGrades() {
      try {
        if (!this.getGrades.length) {
          await this.fetchGrades();
        }
      } catch(err) {
        console.error('Error in getMenuGrades:', err);
      }
    }
  },

  async created() {       
    await this.getMenuGrades();
  }
}
</script>

<style scoped>
.aside-card__btn {
  position: absolute;
  top: 73.7%;
  left: -35px;
  z-index: 1;
  padding: 0;
  background: transparent;
  border: none;
  border-radius: 50%;
  transition: all 0.5s;
}
.aside-card__btn.toggled {
  /*left: 15%;*/
  transform: rotate(180deg);
}
@media screen and (max-width: 992px) {
  .ephemeral{
    display: none;
  }
}
</style>