class LocalStorageWrapper {
  transformation = null;

  constructor(transformation = null) {
    if (transformation === null || typeof transformation !== 'object') {
      throw new Error('Transformation must be a Transform object.')
    }
    if (transformation) {
      this.transformation = transformation;
    }
  }

  setItem(key, value) {
    value = this.transformation.directTransform(value);
    localStorage.setItem(key, value)
    // console.warn(`Key=${key}, value=`, value);
  }

  getItem(key) {
    const value = localStorage.getItem(key);
    try {
      return this.transformation.reverseTransform(value);
    } catch (error) {
      // case of storing plain value (not JSON format)
      const correctValue = this.transformation.directTransform(value);
      return this.transformation.reverseTransform(correctValue);
    }
  }
}

class Transform {
  directTransform = this._transformIdentity
  reverseTransform = this._transformIdentity

  constructor(directTransform = null, reverseTransform = null) {
    if (typeof directTransform !== 'function' || typeof reverseTransform !== 'function') {
      throw new Error('directTransform and reverseTransform must be functions.')
    }
    if (directTransform) {
      this.directTransform = directTransform
    }
    if (reverseTransform) {
      this.reverseTransform = reverseTransform
    }
  }

  _transformIdentity(value) {
    return value
  }
}

const transformJSON = new Transform(JSON.stringify, JSON.parse)

export {
  LocalStorageWrapper,
  transformJSON
}