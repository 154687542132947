!<template>
  <div 
    class="kaz-dialog"
    :class="{'kaz-dialog_hidden' : !visible}"
    @click.stop="$emit('close')"
  >
    <kaz-button
      class="close-button_outer" 
      @click.stop="$emit('close')"
      :leftIcon="true"
      leftIconName="kuiIconClose"
      label=""
      mode="normal"
      size="S"
    />
    <div 
      class="kaz-dialog__content"
      :class="[`content_size_${size}`, { 'content_type_fit' : fitContent }]"
      @click.stop
    >
      <button class="close-button_inner" @click.stop="$emit('close')">
        <svg-icon iconName="kuiIconClose" size="16px"/>
      </button>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import KazButton from '@/components/KazUI/atoms/button';


const body = document.querySelector('body');

export default {
  name: 'kaz-dialog',

  components: {
    KazButton,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'M',
    },
    fitContent:{
      type: Boolean,
      default: false
    }
  },

  watch: {
    visible() {
      if (this.visible) {
        body.classList.add('dialog-open');
      } else {
        body.classList.remove('dialog-open');
      }
    }
  }
}
</script>

<style scoped>
.kaz-dialog {
  --content-height-mid: 458px;
  --content-height-small: 248px;
  --content-height-exsmall: 304px;

  position: fixed;
  display: grid;
  place-items: end;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: rgba(0 0 0 / 0.75);
  z-index: 1000;
}

.kaz-dialog_hidden {
  opacity: 0;
  visibility: hidden;
}

.kaz-dialog__content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  background-color: var(--kaz-base-base-01);
  border-radius: 0;
  padding: 24px;
  overflow: auto;
  overscroll-behavior: contain;
}

.content_size_M {
  width: 100%;
  min-height: var(--content-height-mid);
  max-height: var(--content-height-mid);
}

.content_type_fit{
  min-height: fit-content;
}

.content_size_S {
  width: 100%;
  min-height: var(--content-height-small);
  max-height: var(--content-height-small);
}

.content_size_XS {
  width: 100%;
  min-height: var(--content-height-exsmall);
  max-height: var(--content-height-exsmall);
}

.close-button_outer {
  display: none;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--kaz-base-base-08);
}

.close-button_inner {
  display: block;
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: transparent;
  border: none;
  padding: 6px;
}

@media screen and (min-width: 768px) {
  .kaz-dialog {
    place-items: center;
  }

  .kaz-dialog__content {
    border-radius: 24px;
  }

  .close-button_outer {
    display: block;
  }

  .close-button_inner {
    display: none;
  }

  .content_size_M {
    width: 600px;
  }

  .content_size_S {
    width: 400px;
  }

  .content_size_XS {
    width: 320px;
  }

  .content_size_M,
  .content_size_X,
  .content_size_XS {
    max-height: 100%;
  }
}
</style>
