import { fetchSeoData } from "@/services/Seo";
import { setContentMeta } from '@/utils/meta';


async function setRemoteTitleDescription(self, path) {
  try {
    // console.log('IN [REMOTE TITLE]:', path);
    const response = await fetchSeoData(
      process.env.VUE_APP_SEO_DOMAIN,
      path 
    );
    // console.log('OUT [REMOTE TITLE]:', response.toObject());
    
    const { title, description } = response.toObject();
    document.title = title;
    self.$setMetaDescription(description);
  } catch (error) {
    console.warn('ERROR [REMOTE TITLE]:', error);
  }
}

async function useRemoteTitleDescription(path) {
  try {
    // console.log('IN [REMOTE TITLE]:', path);
    const response = await fetchSeoData(
      process.env.VUE_APP_SEO_DOMAIN,
      path 
    );
    // console.log('OUT [REMOTE TITLE]:', response.toObject());
    
    const { title, description } = response.toObject();
    document.title = title;
    setContentMeta('name', 'description', description);
  } catch (error) {
    console.warn('ERROR [REMOTE TITLE]:', error);
  }
}


export {
  setRemoteTitleDescription,
  useRemoteTitleDescription,
}