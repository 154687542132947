<template>
  <div class="input__wrapper">
    <div class="input-icon">
      <svg-icon class="icon icon_type_search" v-if="size==='M' || size==='L'" :iconName="'kuiIconSearchLarge'" :size="'24px'"/>
      <svg-icon class="icon icon_type_search" v-if="size!=='M' && size!=='L'" :iconName="'kuiIconSearch'" :size="'16px'"/>
    </div>
    <input
      :class="[
        'input',
        `input_size_${size}`,
        'input_type_search'
      ]"
      type="text"
      v-model="internalValue"
      :placeholder="placeholder"
      :disabled="readOnly"
      @input="onInput"
      ref="input"
    />
    <div class="input-icon">
      <svg-icon 
        v-if="internalValue && (size==='M' || size==='L')"
        class="icon icon_type_close" 
        :iconName="'kuiIconCloseLarge'" 
        :size="'24px'" 
        @mousedown="clearInput"
      />
      <svg-icon 
        v-if="internalValue && (size!=='M' && size!=='L')" 
        class="icon icon_type_close" 
        :iconName="'kuiIconClose'" 
        :size="'16px'" 
        @mousedown="clearInput"
      />
    </div>
  </div>
</template>

<script>
export default{
  name: 'input-search',
  props: {
    size:{
      type: String,
      default: 'M',
    },
    value: {
      type: String,
      default: ''
    },
    label: String,
    placeholder: String,
    readOnly:{
      type: Boolean,
      default: false
    },
    autocomplete: String,
    maxlength: [String, Number],
    isUpperCase:{
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      internalValue: this.value
    };
  },
  watch: {
    value: function(newVal) {
      this.internalValue = newVal;
    },
    internalValue: function(newVal) {
      this.$emit('update:value', newVal);
    }
  },
  methods:{
    clearInput(e) {
      // This prevents focus out event emitting.
      // Why and how does it work?
      e.preventDefault();
      this.internalValue = '';
    },
    onInput(event) {
      this.internalValue = event.target.value;
      this.$emit('update:value', this.internalValue);
    },
  }
}
</script>

<style scoped>
@import '../inputs.css';

.input__wrapper {
  position: relative;
}

.input-icon .icon{
  --kaz-base-base-09: var(--kaz-textIcons-text-03);
}
.input-icon:last-of-type:hover .icon{
  --kaz-base-base-09: var(--kaz-textIcons-text-02);
}

.input_size_L{
  padding: 16px 48px 16px 44px;
}

.input_size_M{
  padding: 12px 48px 12px 44px;
}

.input_size_S{
  padding: 6px 38px 6px 32px;
}

.input-icon:first-of-type {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
}

.input-icon:last-of-type {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}

.input-icon:last-child {
  cursor: pointer;
}

@media screen and (max-width: 568px) {
  .input__wrapper{
    width: 100%;
  }
}
</style>
