<template>
  <div
    :class="[
      'tag', `tag_size_${size}`, 
      `tag_appearance_${appearance}`, 
      {'tag_editable': editable}
    ]"
  >
    <div :class="['tag__content', `tag__content_size_${size}`]">
      <p :class="['label', size === 'S' ? 'label_size_XS' : 'label_size_S']">
        {{ label }}
      </p>
    </div>
    <template v-if="editable">
      <svg-icon
        class="tag__remove-icon"
        :class="{ 'tag__remove-icon_disabled' : disabled }"
        iconName="kuiIconCloseLarge" 
        size="24px" 
        @click.stop="$emit('remove-tag')"
      />
    </template>
  </div>
</template>

<script>
export default{
  name: 'Tag',

  props:{
    label:{
      type: String,
      default: 'hello user',
      requred: false
    },
    appearance:{
      type: String,
      requred: false,
      default: 'default'
    },
    size:{
      type: String,
      requred: false,
      default: 'L'
    },
    editable:{
      type: Boolean,
      requred: false,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  emits: ['remove-tag'],
}

</script>

<style scoped>
.tag{
  display: flex;
  width: fit-content;
  align-items: center;
  position: relative;
}

.tag_appearance_default{
  background-color: var(--kaz-base-clear)
}
.tag_appearance_primary{
  background-color: var(--kaz-base-primary)
}
.tag_appearance_success{
  background-color: var(--kaz-status-success-bg)
}

.tag_appearance_primary .label{
  color: var(--kaz-textIcons-day-text-01);
}

.tag_size_L{
  border-radius: 8px;
  padding: 8px 12px;
}
.tag_size_M{
  border-radius: 8px;
  padding: 4px 8px;
}
.tag_size_S{
  border-radius: 6px;
  padding: 2px 6px;
}

.tag_editable {
  padding-right: 32px;
}

.tag__remove-icon {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  cursor: pointer;
  --kaz-base-base-09: var(--kaz-textIcons-text-03);
}

.tag__remove-icon:hover {
  --kaz-base-base-09: var(--kaz-textIcons-text-02);
}

.tag__remove-icon_disabled {
  cursor: default;
  pointer-events: none;
}
</style>