<template>
  <article class="accordion-wrapper" :style="{ 'background-color': backgroundColor }">
    <header
      class="accordion-header"
      :class="{ 'accordion-header_open' : isContentVisible }"
    >
      <div class="accordion-header__main">
        <slot name="header"></slot>
      </div>
      <nav class="accordion-navigation">
        <button 
          class="accordion__visibility-button accordion__visibility-icon" 
          @click.stop="toggleContent"
        >
          <span v-if="!isContentVisible" class="accordion__icon-open">
            <slot  name="icon-open"></slot>
          </span>
          <span v-else class="accordion__icon-close">
            <slot  name="icon-close"></slot>
          </span>
        </button>
        <!-- <div class="accordion__actions" v-if="$slots.actions">
          <slot name="actions"></slot>
        </div> -->
      </nav>
    </header>

    <div 
      v-if="$slots.content"
      v-show="isContentVisible"
      class="accordion-content"
      :class="{ 'accordion-content_open' : isContentVisible }"
    >
      <slot name="content"></slot>
    </div>
  </article>
</template>

<script>

export default {
  name: 'container-accordion',

  props: {
    isContentVisible: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: 'var(--background-light)'
    }
  },

  emits: ['toggle-accordion'],

  methods: {
    toggleContent(event) {
      // this.isContentVisible = !this.isContentVisible;
      this.$emit('toggle-accordion')
    }
  }
}
</script>

<style scoped>

.accordion-wrapper {
  border-radius: 5px;
}

.accordion-header {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 24px;
}

.accordion-header_open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-header__main {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.accordion-navigation {
  display: flex;
  align-items: center;
  gap: 16px;

  position: relative;
  top: -24px;

  margin-left: auto;
}

.accordion__visibility-button {
  padding: 0;
  border: none;
  background-color: transparent;
}

.accordion-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  padding-top: 0;
}

.accordion-content_open {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

@media screen and (max-width: 568px) {
  .accordion-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 8px;
    padding-top: 0;
  }
  .accordion-header__main {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
