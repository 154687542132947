import Book from "@/views/tasks/Book.vue";
import BookContent from "@/views/tasks/BookContent.vue";
import TaskEditor from "@/views/tasks/task-editor/index.vue";

const prefixName = "editor-";
const prefixPath = '/editor/resh'

const routes = [
  {
    path: prefixPath + "/",
    name: prefixName + "resh",
    meta: { layout: "tasks", auth: false },
    component: () => import("@/views/tasks/Home.vue"),
  },
  {
    path: prefixPath + "/course",
    name : prefixName + "course",
    meta: { layout: "tasks", auth: false },
    component: () => import("@/views/tasks/Course.vue")
  },
  {
    path: prefixPath + "/books/:bid",
    name:  prefixName + "book",
    meta: { layout: "tasks", auth: false },
    component: Book,
    children: [
      {
        path: "",
        name: prefixName + "book-content",
        meta: { layout: "tasks", auth: false },
        component: BookContent
      },
      {
        path: "editor/:tid",
        name: prefixName + "task",
        meta: { layout: "tasks", auth: false },
        component: TaskEditor
      }
    ]
  }
]

export default routes;
