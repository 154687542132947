
<template>
  <div
    v-show="showLoginPopup"
    class="popup"
    v-bind:class="{ 'popup_opened': showLoginPopup }"
    @click="closePopupClick"
  >
    <div class="button-container">
      <button class="button_choose-state" :class="{ active: !isRegistration }" @click="setRegistration(false)">Вход</button>
      <button class="button_choose-state" :class="{ active: isRegistration }" @click="setRegistration(true)">Регистрация</button>
    </div>
    <!-- LOGIN -->
    <form v-show="!isRegistration" name="login" class="form form__login" @submit.prevent="loginHandler" @keydown.enter.prevent="loginHandler">
      <card-background class="form__inside-wrapper">
        <div class="form__heading-wrapper" >
          <p class="heading heading_size_h4">Вход в аккаунт Казатель</p>
          <button-close @click="closeLoginPopup()" />
        </div>
        <p class="heading heading_size_h3">Добро пожаловать!</p>
        <div class="input-field">
          <p class="error-message" v-if="authError">{{ authErrorMessage }}</p>
          <div class="auth__input-wrapper">
            <input-component
              type="email"
              placeholder="Ваш e-mail"
              v-model:value="inputEmail"
              autocomplete="email"
            />
            <input-component
              type="password"
              placeholder="Ваш пароль"
              v-model:value="inputPassword"
            />
          </div>
          <button-primary type="submit" autofocus :disabled="isButtonLoginDisabled">
            <span v-if="isLoading">Пробуем войти...</span>
            <span v-if="!isLoading">Войти в аккаунт</span>
          </button-primary>
          <p class="form__text form__text_type_link" @click="pushToPasswordReset">
            Не помню пароль
          </p>
        </div>
      </card-background>
    </form>
    <!-- REGISTRATION -->
    <form v-show="isRegistration" name="registration" class="form form__registration" @submit.prevent="handleRegisterSubmit" @keydown.enter.prevent="handleRegisterSubmit">
      <card-background class="form__inside-wrapper">
        <div class="form__heading-wrapper" >
          <p class="heading heading_size_h4">Регистрация нового пользователя</p>
          <button-close @click="closePopup" />
        </div>
        <p v-if="emailInputVisible" class="heading heading_size_h3">
          Укажите ваш e-mail для регистрации
        </p>
        <div class="input-field" v-if="emailInputVisible">
          <p class="error-message" v-if="regError">{{ regErrorMessage }}</p>
          <input-component
            type="email"
            placeholder="Email@email.com"
            v-model:value="email"
            :disabled="emailConfirmVisible"
            autocomplete="email"
          />
        </div>
        <div class="form__button-wrapper" v-if="emailInputVisible">
          <button-primary :disabled="isButtonConnectDisabled" type="submit" autofocus @click="this.$setMetrikaGoal('POPUP_REG');">
            <span v-if="isLoading">Отправляем данные...</span>
            <span v-if="!isLoading">Присоединиться к Казатель</span>
          </button-primary>
            <p class="form__text">
              Нажимая кнопку «присоединиться» вы даете
              <a href="./personal-data.pdf" class="text-link">согласие на обработку персональных данных</a>
              и <a href="./user-agreement.pdf" class="text-link">условия пользовательского соглашения</a>
            </p>
        </div>
        <div v-if="emailConfirmVisible && !passwordConfirmVisible" class="input-field" :style="{ display: emailConfirmVisible ? 'flex' : 'none' }">
          <p class="heading heading_size_h3">
            Введите код, отправленный на почту <span class="email-text">{{ email }}</span>
          </p>
          <p v-if="showCodeErrorMessage" class="error-message">
            {{ codeErrorMessage }}
          </p>
          <InputComponent
            type="text"
            placeholder=""
            v-model:value="confirmationCode"
          />
          <button-primary autofocus @click="confirmCode" :disabled="isButtonCodeDisabled">
            <span v-if="isLoading">Подтверждаем код...</span>
            <span v-if="!isLoading">Подтвердить код</span>
          </button-primary>
          <button class="text-button" @click="backToEmail">Поменять e-mail</button>
        </div>
        <div v-if="passwordConfirmVisible" class="input-field">
          <heading-size-h3>Придумайте новый пароль</heading-size-h3>
          <InputComponent
            type="password"
            placeholder=""
            v-model:value="newPassword"
          />
          <InputComponent
            type="password"
            placeholder=""
            label="Повторите пароль"
            v-model:value="repeatedPassword"
          />
          <button-primary autofocus @click="setPassword" :disabled="isButtonPasswordDisabled" >
            Завершить регистрацию
          </button-primary>
        </div>
      </card-background>
    </form>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import CardBackground from '@/components/UI/card/CardBackground.vue';
import ButtonClose from '@/components/UI/buttons/ButtonClose.vue';
import HeadingSizeH3 from '@/components/UI/texts/headings/HeadingSizeH3.vue';
import HeadingSizeH4 from '@/components/UI/texts/headings/HeadingSizeH4.vue';
import InputComponent from '@/components/UI/inputs/InputComponent.vue';
import ButtonPrimary from '@/components/UI/buttons/landing/ButtonPrimary.vue';
import {
  handleErrorLogin,
  registerUserStart,
  handleErrorRegister,
  registerUserCodeConfirmation,
  handleErrorConfirmation,
  handleErrorPasswordSetup
} from '@/utils/auth';

export default {
  name: 'registration-popup',
  components:{
    CardBackground,
    ButtonClose,
    HeadingSizeH3,
    HeadingSizeH4,
    InputComponent,
    ButtonPrimary
  },
  props: {
    isPopupShown: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closePopup'],
  data() {
    return{
      // registration
      isRegistration: false,
      emailInputVisible: true,
      emailConfirmVisible: false,
      emailConfirmOff: false,
      passwordConfirmVisible: false,
      email: "",
      mail: "",
      newPassword: '',
      repeatedPassword: '',
      regError: false,
      regErrorMessage: null,
      hashedEmail: '',
      hash: '',
      reset: false,
      accessToken: '',
      refreshToken: '',
      confirmationCode: null,
      uuidCode: null,
      codeErrorMessage: '',
      showCodeErrorMessage: false,
      serviceURL: process.env.VUE_APP_REGISTRATION_SERVICE_URL,
      // login
      inputEmail: '',
      inputPassword: '',
      authError: false,
      authErrorMessage: null,
      // common
      isLoading: false,
    }
  },
  computed: {
    isButtonConnectDisabled() {
      return this.email && this.emailConfirmVisible;
    },
    // isInputEmailDisabled(){
    //   return this.emailConfirmVisible;
    // },
    isButtonCodeDisabled() {
      return !this.confirmationCode;
    },
    isButtonPasswordDisabled() {
      return !this.repeatedPassword && !this.newPassword;
    },
    isButtonLoginDisabled(){
      return !this.inputEmail || !this.inputPassword
    },
    ...mapState({
      showLoginPopup: state => state.popupAuth.showLoginPopup,
    }),
  },
  methods: {
    ...mapActions({
      closeLoginPopup: 'popupAuth/closeLoginPopup',
      loginUser: 'authData/loginUser',
      setupUserPassword: 'authData/setupUserPassword'
      }
    ),
    // metrikaLocalGoal(type){
    //   switch(type){
    //     case 'POPUP_REG':
    //       ym(95026746, 'reachGoal', 'POPUP_REG');
    //       return true;
    //     case 'FIN_REG':
    //       ym(95026746,'reachGoal','FIN_REG');
    //       return true;
    //   }
    //   //move this outside, it works fine here
    // },
    updateParent() {
      this.$emit('update-parent');
    },
    onKeyDown(event) {
      if (event.key === 'Escape') {
        this.closeLoginPopup();
      }
    },
    setRegistration(value) {
      this.isRegistration = value;
    },
    closePopupClick(event) {
      if (event.target.classList.contains('popup') || event.target.classList.contains('close-btn')) {
        this.closeLoginPopup();
      }
    },
    closePopup() {
      this.closeLoginPopup();
    },
    pushToLogin(){
      this.$router.push('/login')
    },
    pushToPasswordReset(){
      this.$router.push('/password-reset')
    },
    showEmailInput(){
      this.emailInputVisible = true;
    },
    hideEmailInput(){
      this.emailInputVisible = false;
    },
    showEmailConfirm(){
      this.emailConfirmVisible = true;
    },
    hideEmailConfirm(){
      this.emailConfirmVisible = false;
    },
    showPasswordConfirm(){
      this.passwordConfirmVisible = true;
    },
    handleRegisterSubmit() {
      this.register();
    },
    async loginHandler() {
      try {
        this.authError = false;
        this.isLoading = true;
        await this.loginUser({email: this.inputEmail, password: this.inputPassword});
        this.closePopup();
        location.reload();
      } catch (error) {
        this.authError = true;
        this.authErrorMessage = handleErrorLogin(error);
        console.error(this.authErrorMessage);
      } finally {
        this.isLoading = false;
      }
    },
    async register() {
      try {
        this.regError = false;
        this.regErrorMessage = '';
        this.isLoading = true;
        const {code, hash} = await registerUserStart(this.email);
        this.hashedEmail = hash;
       
        this.hideEmailInput();
        this.showEmailConfirm();
      } catch (error) {
        this.regError = true;
        this.regErrorMessage = handleErrorRegister(error);
        console.error(this.regErrorMessage)
      } finally {
        this.isLoading = false;
      }
    },
    async confirmCode() {
      try {
        this.showCodeErrorMessage = false;
        this.codeErrorMessage = '';
        // Important moment:
        // If user is not set password is this case of registered user?
        // currently it's considered as not registered one because of
        // next tokens is not saved.
        this.preRegisterTokens = await registerUserCodeConfirmation(
          this.hashedEmail,
          this.confirmationCode,
          this.reset
        );
        this.hideEmailConfirm();
        this.showPasswordConfirm();
      } catch (error) {
        this.codeErrorMessage = handleErrorConfirmation(error);
        this.showCodeErrorMessage = true;
      }
    },
    async setPassword() {
      // This method is the final step of registration and only it saves tokens.
      if (this.isPasswordValid()) {
        try {
          const accessToken = this.preRegisterTokens.accessToken;
          await this.setupUserPassword({password: this.newPassword, accessToken});
          this.closePopup();
          location.reload();
        } catch (error) {
          const errorMessage = handleErrorPasswordSetup(error);
          console.error(errorMessage);
        }
        finally {
          this.$setMetrikaGoal('FIN_REG');
        }
      }
    },
    isPasswordValid() {
      if (this.newPassword) {
        return this.newPassword === this.repeatedPassword
      }
      return false
    },
    backToEmail() {
      this.hideEmailConfirm();
      this.showEmailInput();
    }
  },
  mounted() {
    this.email = this.hash || '';
    this.reset = this.$route.query.reset || false;
    this.inputEmail = this.$route.query.email || '';
    document.addEventListener('keydown', this.onKeyDown);
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }
}
</script>
<style scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease, visibility 0s linear .5s;
  -webkit-transition: opacity .5s ease, visibility 0s linear .5s;
  -moz-transition: opacity .5s ease, visibility 0s linear .5s;
  -ms-transition: opacity .5s ease, visibility 0s linear .5s;
  -o-transition: opacity .5s ease, visibility 0s linear .5s;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.popup_opened {
  opacity: 1;
  visibility: visible;
  transition: opacity .5s ease, visibility 0s linear 0s;
  -webkit-transition: opacity .5s ease, visibility 0s linear 0s;
  -moz-transition: opacity .5s ease, visibility 0s linear 0s;
  -ms-transition: opacity .5s ease, visibility 0s linear 0s;
  -o-transition: opacity .5s ease, visibility 0s linear 0s;
}
.button-container {
  display: flex;
  background-color: var(--white);
  border-radius: 8px;
}
.button_choose-state {
  flex: 1;
  padding: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  width: 200px;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-black);
}
.button_choose-state.active {
  background-color: var(--background-red);
  color: white;
  border-radius: 8px 0px 0px 8px;
}
.button_choose-state.active:last-of-type {
  border-radius: 0px 8px 8px 0px;
}
.button_choose-state:hover {
  background-color: #A0342A;
  color: white;
  border-radius: 8px 0px 0px 8px;
}
.button_choose-state:hover:last-of-type {
  border-radius: 0px 8px 8px 0px;
}
.form{
  width: 50%;
  max-width: 600px;
}
.form__heading-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.form__inside-wrapper{
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
  min-height: 382px;
}
.input-field{
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.form__text{
  color: #8B8B8B;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.28px;
}
.form__text_type_link{
  color: #D8473A;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.text-link{
  color: #D8473A;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.28px;
  text-decoration: none;
}
.form__button-wrapper{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.auth__input-wrapper{
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.error-message{
  color: var(--red-indicator);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: -20px;
}
.text-button{
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-family: 'Rubik';
  font-weight: 500;
  color: var(--background-red);
  align-self: center;
}
.email-text{
  color: var(--background-brown);
}
@media screen and (max-width: 992px) {
  .form{
    width: 90%;
  }
  .form__inside-wrapper{
    padding: 16px;
  }
  .button_choose-state {
    width: 160px;
    font-size: 16px;
  }
}
@media screen and (max-width: 576px) {
  .form__inside-wrapper{
    padding: 16px;
  }
  .button_choose-state {
    width: 140px;
    font-size: 16px;
  }
}
</style>
