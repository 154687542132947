export default {
  name: 'click-outside',

  beforeMount(el, binding) {
    el.clickOutsideEvent = event => {
      if (!(event.target === el || el.contains(event.target))) {
        try {
          binding.value();
        } catch (err) {
          console.error(err);
        }
      }
    };
    document.addEventListener('click', el.clickOutsideEvent);
  },

  unmounted(el) {
    document.removeEventListener('click', el.clickOutsideEvent)
  }
}