

export const editorIntro = {
  namespaced: true,

  state: () => ({
    steps: [
      'Выбери класс и предмет',
      'Выбери учебник',
      'Выбери номер задачи',
      'Заполни любое поле',
      'Получи «денежку»'
    ],
    currentStep: 0
  }),

  getters: {
    getSteps(state) {
      return state.steps
    },
    getCurrentStep(state) {
      return state.currentStep
    }
  },

  mutations: {
    setStepNumber(state, stepNumber) {
      if (stepNumber < 0 /*|| stepNumber >= state.steps.length*/) {
        throw new Error(`Step number ${stepNumber} is out of the range.`)
      }
      state.currentStep = stepNumber;
    }
  }
}