<template>
  <card-background class="table-page">
    <div class="heading-wrapper">
      <div class="button-back__wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M6.52594 9.16666H16.6693V10.8333H6.52594L10.9959 15.3033L9.8176 16.4817L3.33594 9.99999L9.8176 3.51833L10.9959 4.69666L6.52594 9.16666Z" fill="#83322A"/>
        </svg>
        <button-text @click="backToPrevPage">вернуться к обзору</button-text>
      </div>
      <div class="heading-button__wrapper">
        <heading-size-h2>Детальный отчет для задания «Математика, 5 класс, № 5245»</heading-size-h2>
        <button-text>скачать отчет.xml</button-text>
      </div>

    </div>
    <div class="table-container">
      <table class="custom-table">
        <thead class="table-header">
          <tr>
            <th>UUID пользователя</th>
            <th>Время просмотра</th>
            <th>Выведен просмотр</th>
            <th>Дата вывода</th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr v-for="(item, index) in tableData" :key="index" :class="{ 'yellow-row': index % 2 === 0, 'white-row': index % 2 !== 0 }">
            <td class="tooltip-cell">{{ item.column1 }}</td>
            <td class="tooltip-cell">{{ item.column2 }}</td>
            <td class="tooltip-cell">{{ item.column3 }}</td>
            <td class="tooltip-cell">{{ item.column4 }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </card-background>
</template>

<script>
import CardBackground from '@/components/UI/card/CardBackground.vue';
import ButtonText from '@/components/UI/buttons/ButtonText.vue';
import HeadingSizeH2 from '@/components/UI/texts/headings/HeadingSizeH2.vue';

export default {
  name: 'detailed-stats-table',
  data() {
    return {
      tableData: [
        { column1: '123e4567-e89b-12d3-a456-426655440000', column2: '26.07.2023 14:43 GMT+3', column3: 'Выведен', column4: '15.09.2023 07:30 GMT+3' },
        { column1: '123e4567-e89b-12d3-a456-426655440000', column2: '26.07.2023 14:43 GMT+3', column3: 'Выведен', column4: '15.09.2023 07:30 GMT+3' },
        { column1: '123e4567-e89b-12d3-a456-426655440000', column2: '26.07.2023 14:43 GMT+3', column3: 'Выведен', column4: '15.09.2023 07:30 GMT+3' },
        { column1: '123e4567-e89b-12d3-a456-426655440000', column2: '26.07.2023 14:43 GMT+3', column3: 'Выведен', column4: '15.09.2023 07:30 GMT+3' },
        { column1: '123e4567-e89b-12d3-a456-426655440000', column2: '26.07.2023 14:43 GMT+3', column3: 'Выведен', column4: '15.09.2023 07:30 GMT+3' },
        { column1: '123e4567-e89b-12d3-a456-426655440000', column2: '26.07.2023 14:43 GMT+3', column3: 'Выведен', column4: '15.09.2023 07:30 GMT+3' },
        { column1: '123e4567-e89b-12d3-a456-426655440000', column2: '26.07.2023 14:43 GMT+3', column3: 'Выведен', column4: '15.09.2023 07:30 GMT+3' },
        { column1: '123e4567-e89b-12d3-a456-426655440000', column2: '26.07.2023 14:43 GMT+3', column3: 'Выведен', column4: '15.09.2023 07:30 GMT+3' },
        { column1: '123e4567-e89b-12d3-a456-426655440000', column2: '26.07.2023 14:43 GMT+3', column3: 'Выведен', column4: '15.09.2023 07:30 GMT+3' },
        { column1: '123e4567-e89b-12d3-a456-426655440000', column2: '26.07.2023 14:43 GMT+3', column3: 'Выведен', column4: '15.09.2023 07:30 GMT+3' },
        { column1: '123e4567-e89b-12d3-a456-426655440000', column2: '26.07.2023 14:43 GMT+3', column3: 'Выведен', column4: '15.09.2023 07:30 GMT+3' },
        { column1: '123e4567-e89b-12d3-a456-426655440000', column2: '26.07.2023 14:43 GMT+3', column3: 'Выведен', column4: '15.09.2023 07:30 GMT+3' },
        { column1: '123e4567-e89b-12d3-a456-426655440000', column2: '26.07.2023 14:43 GMT+3', column3: 'Выведен', column4: '15.09.2023 07:30 GMT+3' },
        { column1: '123e4567-e89b-12d3-a456-426655440000', column2: '26.07.2023 14:43 GMT+3', column3: 'Выведен', column4: '15.09.2023 07:30 GMT+3' },
        { column1: '123e4567-e89b-12d3-a456-426655440000', column2: '26.07.2023 14:43 GMT+3', column3: 'Выведен', column4: '15.09.2023 07:30 GMT+3' },
        { column1: '123e4567-e89b-12d3-a456-426655440000', column2: '26.07.2023 14:43 GMT+3', column3: 'Выведен', column4: '15.09.2023 07:30 GMT+3' },
        { column1: '123e4567-e89b-12d3-a456-426655440000', column2: '26.07.2023 14:43 GMT+3', column3: 'Выведен', column4: '15.09.2023 07:30 GMT+3' },
        { column1: '123e4567-e89b-12d3-a456-426655440000', column2: '26.07.2023 14:43 GMT+3', column3: 'Выведен', column4: '15.09.2023 07:30 GMT+3' },
        { column1: '123e4567-e89b-12d3-a456-426655440000', column2: '26.07.2023 14:43 GMT+3', column3: 'Выведен', column4: '15.09.2023 07:30 GMT+3' },
        { column1: '123e4567-e89b-12d3-a456-426655440000', column2: '26.07.2023 14:43 GMT+3', column3: 'Выведен', column4: '15.09.2023 07:30 GMT+3' },
        { column1: '123e4567-e89b-12d3-a456-426655440000', column2: '26.07.2023 14:43 GMT+3', column3: 'Выведен', column4: '15.09.2023 07:30 GMT+3' },
        { column1: '123e4567-e89b-12d3-a456-426655440000', column2: '26.07.2023 14:43 GMT+3', column3: 'Выведен', column4: '15.09.2023 07:30 GMT+3' },
        { column1: '123e4567-e89b-12d3-a456-426655440000', column2: '26.07.2023 14:43 GMT+3', column3: 'Выведен', column4: '15.09.2023 07:30 GMT+3' },
        { column1: '123e4567-e89b-12d3-a456-426655440000', column2: '26.07.2023 14:43 GMT+3', column3: 'Выведен', column4: '15.09.2023 07:30 GMT+3' },
      ],
    };
  },
  components: {
    CardBackground,
    ButtonText,
    HeadingSizeH2
  },
  methods:{
    backToPrevPage(){
      this.$router.push('/denezhki/stats')
    }
  }
};
</script>

<style scoped>
.table-page{
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.heading-wrapper{
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.button-back__wrapper{
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: flex-start;
}
.button-back__wrapper path{
  transition: .3s;
}
.button-back__wrapper:hover path{
  fill: var(--background-red);
  transition: .3s;
}
.heading-button__wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.table-container {
  max-height: 600px;
  min-height: fit-content;
  overflow: auto;
  border: 1px solid var(--background-light-20);
  border-radius: 8px;
}

.custom-table {
  width: 100%;
}
.table-header {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}
.table-body {
  max-height: 600px;
  min-height: fit-content;
  overflow-y: auto;
}
th, td {
  padding: 20px 24px;
  color: var(--text-black);
  font-family: 'Gilroy';
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.16px;
  width: 302px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

th, td {
  border-right: 1px solid var(--text-light);
  border-bottom: 1px solid var(--text-light);
}
.yellow-row {
  background-color: #FFE1D0;
}
.white-row {
  background-color: #fff;
}
</style>
