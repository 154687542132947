// source: entities/entities_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var entities_entities_pb = require('../entities/entities_pb.js');
goog.object.extend(proto, entities_entities_pb);
var entities_tasks_pb = require('../entities/tasks_pb.js');
goog.object.extend(proto, entities_tasks_pb);
var entities_task_cards_pb = require('../entities/task_cards_pb.js');
goog.object.extend(proto, entities_task_cards_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.kazatel.entities.NodeDetail', null, global);
goog.exportSymbol('proto.kazatel.entities.SaveComplete', null, global);
goog.exportSymbol('proto.kazatel.entities.SaveContentInit', null, global);
goog.exportSymbol('proto.kazatel.entities.SaveContentInitResponse', null, global);
goog.exportSymbol('proto.kazatel.entities.SaveGenerateUrlParts', null, global);
goog.exportSymbol('proto.kazatel.entities.SaveGenerateUrlPartsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.entities.NodeDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.entities.NodeDetail.repeatedFields_, null);
};
goog.inherits(proto.kazatel.entities.NodeDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.entities.NodeDetail.displayName = 'proto.kazatel.entities.NodeDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.entities.SaveContentInit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.entities.SaveContentInit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.entities.SaveContentInit.displayName = 'proto.kazatel.entities.SaveContentInit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.entities.SaveContentInitResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.entities.SaveContentInitResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.entities.SaveContentInitResponse.displayName = 'proto.kazatel.entities.SaveContentInitResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.entities.SaveGenerateUrlParts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.entities.SaveGenerateUrlParts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.entities.SaveGenerateUrlParts.displayName = 'proto.kazatel.entities.SaveGenerateUrlParts';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.entities.SaveGenerateUrlPartsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.entities.SaveGenerateUrlPartsResponse.repeatedFields_, null);
};
goog.inherits(proto.kazatel.entities.SaveGenerateUrlPartsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.entities.SaveGenerateUrlPartsResponse.displayName = 'proto.kazatel.entities.SaveGenerateUrlPartsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.entities.SaveComplete = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.entities.SaveComplete.repeatedFields_, null);
};
goog.inherits(proto.kazatel.entities.SaveComplete, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.entities.SaveComplete.displayName = 'proto.kazatel.entities.SaveComplete';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.entities.NodeDetail.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.entities.NodeDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.entities.NodeDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.entities.NodeDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.NodeDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    node: (f = msg.getNode()) && entities_entities_pb.Node.toObject(includeInstance, f),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    typesList: jspb.Message.toObjectList(msg.getTypesList(),
    entities_tasks_pb.ContentByTypes.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.entities.NodeDetail}
 */
proto.kazatel.entities.NodeDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.entities.NodeDetail;
  return proto.kazatel.entities.NodeDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.entities.NodeDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.entities.NodeDetail}
 */
proto.kazatel.entities.NodeDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new entities_entities_pb.Node;
      reader.readMessage(value,entities_entities_pb.Node.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 3:
      var value = new entities_tasks_pb.ContentByTypes;
      reader.readMessage(value,entities_tasks_pb.ContentByTypes.deserializeBinaryFromReader);
      msg.addTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.entities.NodeDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.entities.NodeDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.entities.NodeDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.NodeDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      entities_entities_pb.Node.serializeBinaryToWriter
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      entities_tasks_pb.ContentByTypes.serializeBinaryToWriter
    );
  }
};


/**
 * optional Node node = 1;
 * @return {?proto.kazatel.entities.Node}
 */
proto.kazatel.entities.NodeDetail.prototype.getNode = function() {
  return /** @type{?proto.kazatel.entities.Node} */ (
    jspb.Message.getWrapperField(this, entities_entities_pb.Node, 1));
};


/**
 * @param {?proto.kazatel.entities.Node|undefined} value
 * @return {!proto.kazatel.entities.NodeDetail} returns this
*/
proto.kazatel.entities.NodeDetail.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.entities.NodeDetail} returns this
 */
proto.kazatel.entities.NodeDetail.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.entities.NodeDetail.prototype.hasNode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string entity_id = 2;
 * @return {string}
 */
proto.kazatel.entities.NodeDetail.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.NodeDetail} returns this
 */
proto.kazatel.entities.NodeDetail.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated kazatel.tasks.ContentByTypes types = 3;
 * @return {!Array<!proto.kazatel.tasks.ContentByTypes>}
 */
proto.kazatel.entities.NodeDetail.prototype.getTypesList = function() {
  return /** @type{!Array<!proto.kazatel.tasks.ContentByTypes>} */ (
    jspb.Message.getRepeatedWrapperField(this, entities_tasks_pb.ContentByTypes, 3));
};


/**
 * @param {!Array<!proto.kazatel.tasks.ContentByTypes>} value
 * @return {!proto.kazatel.entities.NodeDetail} returns this
*/
proto.kazatel.entities.NodeDetail.prototype.setTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.kazatel.tasks.ContentByTypes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.tasks.ContentByTypes}
 */
proto.kazatel.entities.NodeDetail.prototype.addTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.kazatel.tasks.ContentByTypes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.entities.NodeDetail} returns this
 */
proto.kazatel.entities.NodeDetail.prototype.clearTypesList = function() {
  return this.setTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.entities.SaveContentInit.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.entities.SaveContentInit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.entities.SaveContentInit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.SaveContentInit.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.entities.SaveContentInit}
 */
proto.kazatel.entities.SaveContentInit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.entities.SaveContentInit;
  return proto.kazatel.entities.SaveContentInit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.entities.SaveContentInit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.entities.SaveContentInit}
 */
proto.kazatel.entities.SaveContentInit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.entities.SaveContentInit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.entities.SaveContentInit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.entities.SaveContentInit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.SaveContentInit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.entities.SaveContentInitResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.entities.SaveContentInitResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.entities.SaveContentInitResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.SaveContentInitResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    uploadId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.entities.SaveContentInitResponse}
 */
proto.kazatel.entities.SaveContentInitResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.entities.SaveContentInitResponse;
  return proto.kazatel.entities.SaveContentInitResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.entities.SaveContentInitResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.entities.SaveContentInitResponse}
 */
proto.kazatel.entities.SaveContentInitResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.entities.SaveContentInitResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.entities.SaveContentInitResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.entities.SaveContentInitResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.SaveContentInitResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUploadId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string upload_id = 1;
 * @return {string}
 */
proto.kazatel.entities.SaveContentInitResponse.prototype.getUploadId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.SaveContentInitResponse} returns this
 */
proto.kazatel.entities.SaveContentInitResponse.prototype.setUploadId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.entities.SaveGenerateUrlParts.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.entities.SaveGenerateUrlParts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.entities.SaveGenerateUrlParts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.SaveGenerateUrlParts.toObject = function(includeInstance, msg) {
  var f, obj = {
    uploadId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    numberParts: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.entities.SaveGenerateUrlParts}
 */
proto.kazatel.entities.SaveGenerateUrlParts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.entities.SaveGenerateUrlParts;
  return proto.kazatel.entities.SaveGenerateUrlParts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.entities.SaveGenerateUrlParts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.entities.SaveGenerateUrlParts}
 */
proto.kazatel.entities.SaveGenerateUrlParts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberParts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.entities.SaveGenerateUrlParts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.entities.SaveGenerateUrlParts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.entities.SaveGenerateUrlParts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.SaveGenerateUrlParts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUploadId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumberParts();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string upload_id = 1;
 * @return {string}
 */
proto.kazatel.entities.SaveGenerateUrlParts.prototype.getUploadId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.SaveGenerateUrlParts} returns this
 */
proto.kazatel.entities.SaveGenerateUrlParts.prototype.setUploadId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 number_parts = 2;
 * @return {number}
 */
proto.kazatel.entities.SaveGenerateUrlParts.prototype.getNumberParts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.entities.SaveGenerateUrlParts} returns this
 */
proto.kazatel.entities.SaveGenerateUrlParts.prototype.setNumberParts = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.entities.SaveGenerateUrlPartsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.entities.SaveGenerateUrlPartsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.entities.SaveGenerateUrlPartsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.entities.SaveGenerateUrlPartsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.SaveGenerateUrlPartsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    uploadId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    urlsPartsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.entities.SaveGenerateUrlPartsResponse}
 */
proto.kazatel.entities.SaveGenerateUrlPartsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.entities.SaveGenerateUrlPartsResponse;
  return proto.kazatel.entities.SaveGenerateUrlPartsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.entities.SaveGenerateUrlPartsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.entities.SaveGenerateUrlPartsResponse}
 */
proto.kazatel.entities.SaveGenerateUrlPartsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addUrlsParts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.entities.SaveGenerateUrlPartsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.entities.SaveGenerateUrlPartsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.entities.SaveGenerateUrlPartsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.SaveGenerateUrlPartsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUploadId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrlsPartsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string upload_id = 1;
 * @return {string}
 */
proto.kazatel.entities.SaveGenerateUrlPartsResponse.prototype.getUploadId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.SaveGenerateUrlPartsResponse} returns this
 */
proto.kazatel.entities.SaveGenerateUrlPartsResponse.prototype.setUploadId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string urls_parts = 2;
 * @return {!Array<string>}
 */
proto.kazatel.entities.SaveGenerateUrlPartsResponse.prototype.getUrlsPartsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.kazatel.entities.SaveGenerateUrlPartsResponse} returns this
 */
proto.kazatel.entities.SaveGenerateUrlPartsResponse.prototype.setUrlsPartsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.entities.SaveGenerateUrlPartsResponse} returns this
 */
proto.kazatel.entities.SaveGenerateUrlPartsResponse.prototype.addUrlsParts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.entities.SaveGenerateUrlPartsResponse} returns this
 */
proto.kazatel.entities.SaveGenerateUrlPartsResponse.prototype.clearUrlsPartsList = function() {
  return this.setUrlsPartsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.entities.SaveComplete.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.entities.SaveComplete.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.entities.SaveComplete.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.entities.SaveComplete} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.SaveComplete.toObject = function(includeInstance, msg) {
  var f, obj = {
    uploadId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    partsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.entities.SaveComplete}
 */
proto.kazatel.entities.SaveComplete.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.entities.SaveComplete;
  return proto.kazatel.entities.SaveComplete.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.entities.SaveComplete} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.entities.SaveComplete}
 */
proto.kazatel.entities.SaveComplete.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addParts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.entities.SaveComplete.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.entities.SaveComplete.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.entities.SaveComplete} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.SaveComplete.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUploadId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPartsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string upload_id = 1;
 * @return {string}
 */
proto.kazatel.entities.SaveComplete.prototype.getUploadId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.SaveComplete} returns this
 */
proto.kazatel.entities.SaveComplete.prototype.setUploadId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string parts = 2;
 * @return {!Array<string>}
 */
proto.kazatel.entities.SaveComplete.prototype.getPartsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.kazatel.entities.SaveComplete} returns this
 */
proto.kazatel.entities.SaveComplete.prototype.setPartsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.entities.SaveComplete} returns this
 */
proto.kazatel.entities.SaveComplete.prototype.addParts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.entities.SaveComplete} returns this
 */
proto.kazatel.entities.SaveComplete.prototype.clearPartsList = function() {
  return this.setPartsList([]);
};


goog.object.extend(exports, proto.kazatel.entities);
