const STATES = Object.freeze({
  DISLIKE: -1,
  INIT: 0,
  LIKE: 1,
})

function mapRatingFront2Back(rating) {
  let res;
  switch (rating) {
    case STATES.INIT:
      res = proto.kazatel.ratings.UserRaiting.UNDEFINED;
      break;
    case STATES.DISLIKE:
      res = proto.kazatel.ratings.UserRaiting.NOT_LIKE;
      break;
    case STATES.LIKE:
      res = proto.kazatel.ratings.UserRaiting.LIKE;
      break;
    default:
      console.warn(`Input front rating [${rating}] is unsupported`);
      break;
  }
  return res;
}

function mapRatingBack2Front(rating) {
  let res;
  switch (rating) {
    case proto.kazatel.ratings.UserRaiting.UNDEFINED:
      res = STATES.INIT;
      break;
    case proto.kazatel.ratings.UserRaiting.NOT_LIKE:
      res = STATES.DISLIKE;
      break;
    case proto.kazatel.ratings.UserRaiting.LIKE:
      res = STATES.LIKE;
      break;
    default:
      console.warn(`Input back rating [${rating}] is unsupported`);
      break;
  }
  return res;
}


export {
  STATES,
  mapRatingFront2Back,
  mapRatingBack2Front,
}