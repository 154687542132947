// source: user/usercontrol.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.kazatel.user.BonusInfo', null, global);
goog.exportSymbol('proto.kazatel.user.BonusOperation', null, global);
goog.exportSymbol('proto.kazatel.user.ChangeVerificationCode', null, global);
goog.exportSymbol('proto.kazatel.user.ChangeVerificationCode.HashTypeCase', null, global);
goog.exportSymbol('proto.kazatel.user.GetRequest', null, global);
goog.exportSymbol('proto.kazatel.user.ParentWork', null, global);
goog.exportSymbol('proto.kazatel.user.TeacherEducation', null, global);
goog.exportSymbol('proto.kazatel.user.TeacherWork', null, global);
goog.exportSymbol('proto.kazatel.user.UpdatableData', null, global);
goog.exportSymbol('proto.kazatel.user.UpdatableTeacherEducation', null, global);
goog.exportSymbol('proto.kazatel.user.UpdatableTeacherWork', null, global);
goog.exportSymbol('proto.kazatel.user.UpdatableUserData', null, global);
goog.exportSymbol('proto.kazatel.user.UpdateVerificationInfo', null, global);
goog.exportSymbol('proto.kazatel.user.UserData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.user.UpdatableData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.user.UpdatableData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.user.UpdatableData.displayName = 'proto.kazatel.user.UpdatableData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.user.UserData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.user.UserData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.user.UserData.displayName = 'proto.kazatel.user.UserData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.user.UpdateVerificationInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.user.UpdateVerificationInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.user.UpdateVerificationInfo.displayName = 'proto.kazatel.user.UpdateVerificationInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.user.UpdatableUserData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.user.UpdatableUserData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.user.UpdatableUserData.displayName = 'proto.kazatel.user.UpdatableUserData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.user.GetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.user.GetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.user.GetRequest.displayName = 'proto.kazatel.user.GetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.user.ChangeVerificationCode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.kazatel.user.ChangeVerificationCode.oneofGroups_);
};
goog.inherits(proto.kazatel.user.ChangeVerificationCode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.user.ChangeVerificationCode.displayName = 'proto.kazatel.user.ChangeVerificationCode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.user.ParentWork = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.user.ParentWork, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.user.ParentWork.displayName = 'proto.kazatel.user.ParentWork';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.user.TeacherWork = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.user.TeacherWork, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.user.TeacherWork.displayName = 'proto.kazatel.user.TeacherWork';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.user.UpdatableTeacherWork = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.user.UpdatableTeacherWork, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.user.UpdatableTeacherWork.displayName = 'proto.kazatel.user.UpdatableTeacherWork';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.user.TeacherEducation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.user.TeacherEducation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.user.TeacherEducation.displayName = 'proto.kazatel.user.TeacherEducation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.user.UpdatableTeacherEducation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.user.UpdatableTeacherEducation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.user.UpdatableTeacherEducation.displayName = 'proto.kazatel.user.UpdatableTeacherEducation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.user.BonusInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.user.BonusInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.user.BonusInfo.displayName = 'proto.kazatel.user.BonusInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.user.BonusOperation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.user.BonusOperation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.user.BonusOperation.displayName = 'proto.kazatel.user.BonusOperation';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.user.UpdatableData.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.user.UpdatableData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.user.UpdatableData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.UpdatableData.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    patronymic: jspb.Message.getFieldWithDefault(msg, 3, ""),
    birthDate: (f = msg.getBirthDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    photo: msg.getPhoto_asB64(),
    defaultLanguage: jspb.Message.getFieldWithDefault(msg, 6, ""),
    about: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.user.UpdatableData}
 */
proto.kazatel.user.UpdatableData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.user.UpdatableData;
  return proto.kazatel.user.UpdatableData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.user.UpdatableData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.user.UpdatableData}
 */
proto.kazatel.user.UpdatableData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatronymic(value);
      break;
    case 4:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setBirthDate(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPhoto(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultLanguage(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.user.UpdatableData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.user.UpdatableData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.user.UpdatableData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.UpdatableData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPatronymic();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBirthDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getPhoto_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = message.getDefaultLanguage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAbout();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string last_name = 1;
 * @return {string}
 */
proto.kazatel.user.UpdatableData.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.UpdatableData} returns this
 */
proto.kazatel.user.UpdatableData.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.kazatel.user.UpdatableData.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.UpdatableData} returns this
 */
proto.kazatel.user.UpdatableData.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string patronymic = 3;
 * @return {string}
 */
proto.kazatel.user.UpdatableData.prototype.getPatronymic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.UpdatableData} returns this
 */
proto.kazatel.user.UpdatableData.prototype.setPatronymic = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.type.Date birth_date = 4;
 * @return {?proto.google.type.Date}
 */
proto.kazatel.user.UpdatableData.prototype.getBirthDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 4));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.kazatel.user.UpdatableData} returns this
*/
proto.kazatel.user.UpdatableData.prototype.setBirthDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.user.UpdatableData} returns this
 */
proto.kazatel.user.UpdatableData.prototype.clearBirthDate = function() {
  return this.setBirthDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.user.UpdatableData.prototype.hasBirthDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bytes photo = 5;
 * @return {string}
 */
proto.kazatel.user.UpdatableData.prototype.getPhoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes photo = 5;
 * This is a type-conversion wrapper around `getPhoto()`
 * @return {string}
 */
proto.kazatel.user.UpdatableData.prototype.getPhoto_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPhoto()));
};


/**
 * optional bytes photo = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPhoto()`
 * @return {!Uint8Array}
 */
proto.kazatel.user.UpdatableData.prototype.getPhoto_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPhoto()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.kazatel.user.UpdatableData} returns this
 */
proto.kazatel.user.UpdatableData.prototype.setPhoto = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};


/**
 * optional string default_language = 6;
 * @return {string}
 */
proto.kazatel.user.UpdatableData.prototype.getDefaultLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.UpdatableData} returns this
 */
proto.kazatel.user.UpdatableData.prototype.setDefaultLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string about = 7;
 * @return {string}
 */
proto.kazatel.user.UpdatableData.prototype.getAbout = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.UpdatableData} returns this
 */
proto.kazatel.user.UpdatableData.prototype.setAbout = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.user.UserData.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.user.UserData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.user.UserData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.UserData.toObject = function(includeInstance, msg) {
  var f, obj = {
    login: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    patronymic: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 6, ""),
    birthDate: (f = msg.getBirthDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    photo: msg.getPhoto_asB64(),
    defaultLanguage: jspb.Message.getFieldWithDefault(msg, 9, ""),
    about: jspb.Message.getFieldWithDefault(msg, 10, ""),
    attrReg: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.user.UserData}
 */
proto.kazatel.user.UserData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.user.UserData;
  return proto.kazatel.user.UserData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.user.UserData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.user.UserData}
 */
proto.kazatel.user.UserData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatronymic(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 7:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setBirthDate(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPhoto(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultLanguage(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbout(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAttrReg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.user.UserData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.user.UserData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.user.UserData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.UserData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPatronymic();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBirthDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getPhoto_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      8,
      f
    );
  }
  f = message.getDefaultLanguage();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAbout();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAttrReg();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional string login = 1;
 * @return {string}
 */
proto.kazatel.user.UserData.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.UserData} returns this
 */
proto.kazatel.user.UserData.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string last_name = 2;
 * @return {string}
 */
proto.kazatel.user.UserData.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.UserData} returns this
 */
proto.kazatel.user.UserData.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_name = 3;
 * @return {string}
 */
proto.kazatel.user.UserData.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.UserData} returns this
 */
proto.kazatel.user.UserData.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string patronymic = 4;
 * @return {string}
 */
proto.kazatel.user.UserData.prototype.getPatronymic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.UserData} returns this
 */
proto.kazatel.user.UserData.prototype.setPatronymic = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.kazatel.user.UserData.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.UserData} returns this
 */
proto.kazatel.user.UserData.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string phone = 6;
 * @return {string}
 */
proto.kazatel.user.UserData.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.UserData} returns this
 */
proto.kazatel.user.UserData.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.type.Date birth_date = 7;
 * @return {?proto.google.type.Date}
 */
proto.kazatel.user.UserData.prototype.getBirthDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 7));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.kazatel.user.UserData} returns this
*/
proto.kazatel.user.UserData.prototype.setBirthDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.user.UserData} returns this
 */
proto.kazatel.user.UserData.prototype.clearBirthDate = function() {
  return this.setBirthDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.user.UserData.prototype.hasBirthDate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bytes photo = 8;
 * @return {string}
 */
proto.kazatel.user.UserData.prototype.getPhoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes photo = 8;
 * This is a type-conversion wrapper around `getPhoto()`
 * @return {string}
 */
proto.kazatel.user.UserData.prototype.getPhoto_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPhoto()));
};


/**
 * optional bytes photo = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPhoto()`
 * @return {!Uint8Array}
 */
proto.kazatel.user.UserData.prototype.getPhoto_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPhoto()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.kazatel.user.UserData} returns this
 */
proto.kazatel.user.UserData.prototype.setPhoto = function(value) {
  return jspb.Message.setProto3BytesField(this, 8, value);
};


/**
 * optional string default_language = 9;
 * @return {string}
 */
proto.kazatel.user.UserData.prototype.getDefaultLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.UserData} returns this
 */
proto.kazatel.user.UserData.prototype.setDefaultLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string about = 10;
 * @return {string}
 */
proto.kazatel.user.UserData.prototype.getAbout = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.UserData} returns this
 */
proto.kazatel.user.UserData.prototype.setAbout = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool attr_reg = 11;
 * @return {boolean}
 */
proto.kazatel.user.UserData.prototype.getAttrReg = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.user.UserData} returns this
 */
proto.kazatel.user.UserData.prototype.setAttrReg = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.user.UpdateVerificationInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.user.UpdateVerificationInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.user.UpdateVerificationInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.UpdateVerificationInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    hash: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.user.UpdateVerificationInfo}
 */
proto.kazatel.user.UpdateVerificationInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.user.UpdateVerificationInfo;
  return proto.kazatel.user.UpdateVerificationInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.user.UpdateVerificationInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.user.UpdateVerificationInfo}
 */
proto.kazatel.user.UpdateVerificationInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHash(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.user.UpdateVerificationInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.user.UpdateVerificationInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.user.UpdateVerificationInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.UpdateVerificationInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHash();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional string hash = 2;
 * @return {string}
 */
proto.kazatel.user.UpdateVerificationInfo.prototype.getHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.UpdateVerificationInfo} returns this
 */
proto.kazatel.user.UpdateVerificationInfo.prototype.setHash = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.kazatel.user.UpdateVerificationInfo.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.user.UpdateVerificationInfo} returns this
 */
proto.kazatel.user.UpdateVerificationInfo.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.user.UpdatableUserData.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.user.UpdatableUserData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.user.UpdatableUserData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.UpdatableUserData.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatableData: (f = msg.getUpdatableData()) && proto.kazatel.user.UpdatableData.toObject(includeInstance, f),
    updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f),
    language: jspb.Message.getFieldWithDefault(msg, 3, ""),
    login: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.user.UpdatableUserData}
 */
proto.kazatel.user.UpdatableUserData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.user.UpdatableUserData;
  return proto.kazatel.user.UpdatableUserData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.user.UpdatableUserData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.user.UpdatableUserData}
 */
proto.kazatel.user.UpdatableUserData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.kazatel.user.UpdatableData;
      reader.readMessage(value,proto.kazatel.user.UpdatableData.deserializeBinaryFromReader);
      msg.setUpdatableData(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.user.UpdatableUserData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.user.UpdatableUserData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.user.UpdatableUserData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.UpdatableUserData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatableData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.kazatel.user.UpdatableData.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional UpdatableData updatable_data = 1;
 * @return {?proto.kazatel.user.UpdatableData}
 */
proto.kazatel.user.UpdatableUserData.prototype.getUpdatableData = function() {
  return /** @type{?proto.kazatel.user.UpdatableData} */ (
    jspb.Message.getWrapperField(this, proto.kazatel.user.UpdatableData, 1));
};


/**
 * @param {?proto.kazatel.user.UpdatableData|undefined} value
 * @return {!proto.kazatel.user.UpdatableUserData} returns this
*/
proto.kazatel.user.UpdatableUserData.prototype.setUpdatableData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.user.UpdatableUserData} returns this
 */
proto.kazatel.user.UpdatableUserData.prototype.clearUpdatableData = function() {
  return this.setUpdatableData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.user.UpdatableUserData.prototype.hasUpdatableData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.kazatel.user.UpdatableUserData.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.kazatel.user.UpdatableUserData} returns this
*/
proto.kazatel.user.UpdatableUserData.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.user.UpdatableUserData} returns this
 */
proto.kazatel.user.UpdatableUserData.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.user.UpdatableUserData.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string language = 3;
 * @return {string}
 */
proto.kazatel.user.UpdatableUserData.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.UpdatableUserData} returns this
 */
proto.kazatel.user.UpdatableUserData.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string login = 4;
 * @return {string}
 */
proto.kazatel.user.UpdatableUserData.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.UpdatableUserData} returns this
 */
proto.kazatel.user.UpdatableUserData.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.user.GetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.user.GetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.user.GetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.GetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    login: jspb.Message.getFieldWithDefault(msg, 1, ""),
    language: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.user.GetRequest}
 */
proto.kazatel.user.GetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.user.GetRequest;
  return proto.kazatel.user.GetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.user.GetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.user.GetRequest}
 */
proto.kazatel.user.GetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.user.GetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.user.GetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.user.GetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.GetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string login = 1;
 * @return {string}
 */
proto.kazatel.user.GetRequest.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.GetRequest} returns this
 */
proto.kazatel.user.GetRequest.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string language = 2;
 * @return {string}
 */
proto.kazatel.user.GetRequest.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.GetRequest} returns this
 */
proto.kazatel.user.GetRequest.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.kazatel.user.ChangeVerificationCode.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.kazatel.user.ChangeVerificationCode.HashTypeCase = {
  HASH_TYPE_NOT_SET: 0,
  PHONE_HASH: 1,
  MAIL_HASH: 2
};

/**
 * @return {proto.kazatel.user.ChangeVerificationCode.HashTypeCase}
 */
proto.kazatel.user.ChangeVerificationCode.prototype.getHashTypeCase = function() {
  return /** @type {proto.kazatel.user.ChangeVerificationCode.HashTypeCase} */(jspb.Message.computeOneofCase(this, proto.kazatel.user.ChangeVerificationCode.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.user.ChangeVerificationCode.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.user.ChangeVerificationCode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.user.ChangeVerificationCode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.ChangeVerificationCode.toObject = function(includeInstance, msg) {
  var f, obj = {
    phoneHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mailHash: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.user.ChangeVerificationCode}
 */
proto.kazatel.user.ChangeVerificationCode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.user.ChangeVerificationCode;
  return proto.kazatel.user.ChangeVerificationCode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.user.ChangeVerificationCode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.user.ChangeVerificationCode}
 */
proto.kazatel.user.ChangeVerificationCode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneHash(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMailHash(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.user.ChangeVerificationCode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.user.ChangeVerificationCode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.user.ChangeVerificationCode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.ChangeVerificationCode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string phone_hash = 1;
 * @return {string}
 */
proto.kazatel.user.ChangeVerificationCode.prototype.getPhoneHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.ChangeVerificationCode} returns this
 */
proto.kazatel.user.ChangeVerificationCode.prototype.setPhoneHash = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.kazatel.user.ChangeVerificationCode.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.user.ChangeVerificationCode} returns this
 */
proto.kazatel.user.ChangeVerificationCode.prototype.clearPhoneHash = function() {
  return jspb.Message.setOneofField(this, 1, proto.kazatel.user.ChangeVerificationCode.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.user.ChangeVerificationCode.prototype.hasPhoneHash = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string mail_hash = 2;
 * @return {string}
 */
proto.kazatel.user.ChangeVerificationCode.prototype.getMailHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.ChangeVerificationCode} returns this
 */
proto.kazatel.user.ChangeVerificationCode.prototype.setMailHash = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.kazatel.user.ChangeVerificationCode.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.user.ChangeVerificationCode} returns this
 */
proto.kazatel.user.ChangeVerificationCode.prototype.clearMailHash = function() {
  return jspb.Message.setOneofField(this, 2, proto.kazatel.user.ChangeVerificationCode.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.user.ChangeVerificationCode.prototype.hasMailHash = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 code = 3;
 * @return {number}
 */
proto.kazatel.user.ChangeVerificationCode.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.user.ChangeVerificationCode} returns this
 */
proto.kazatel.user.ChangeVerificationCode.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.user.ParentWork.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.user.ParentWork.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.user.ParentWork} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.ParentWork.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workplace: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.user.ParentWork}
 */
proto.kazatel.user.ParentWork.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.user.ParentWork;
  return proto.kazatel.user.ParentWork.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.user.ParentWork} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.user.ParentWork}
 */
proto.kazatel.user.ParentWork.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkplace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.user.ParentWork.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.user.ParentWork.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.user.ParentWork} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.ParentWork.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getWorkplace();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.kazatel.user.ParentWork.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.user.ParentWork} returns this
 */
proto.kazatel.user.ParentWork.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string workplace = 2;
 * @return {string}
 */
proto.kazatel.user.ParentWork.prototype.getWorkplace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.ParentWork} returns this
 */
proto.kazatel.user.ParentWork.prototype.setWorkplace = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.user.TeacherWork.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.user.TeacherWork.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.user.TeacherWork} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.TeacherWork.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    workplace: jspb.Message.getFieldWithDefault(msg, 2, ""),
    experience: jspb.Message.getFieldWithDefault(msg, 3, ""),
    post: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.user.TeacherWork}
 */
proto.kazatel.user.TeacherWork.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.user.TeacherWork;
  return proto.kazatel.user.TeacherWork.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.user.TeacherWork} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.user.TeacherWork}
 */
proto.kazatel.user.TeacherWork.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkplace(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExperience(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.user.TeacherWork.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.user.TeacherWork.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.user.TeacherWork} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.TeacherWork.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getWorkplace();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExperience();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPost();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.kazatel.user.TeacherWork.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.user.TeacherWork} returns this
 */
proto.kazatel.user.TeacherWork.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string workplace = 2;
 * @return {string}
 */
proto.kazatel.user.TeacherWork.prototype.getWorkplace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.TeacherWork} returns this
 */
proto.kazatel.user.TeacherWork.prototype.setWorkplace = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string experience = 3;
 * @return {string}
 */
proto.kazatel.user.TeacherWork.prototype.getExperience = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.TeacherWork} returns this
 */
proto.kazatel.user.TeacherWork.prototype.setExperience = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string post = 4;
 * @return {string}
 */
proto.kazatel.user.TeacherWork.prototype.getPost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.TeacherWork} returns this
 */
proto.kazatel.user.TeacherWork.prototype.setPost = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.user.UpdatableTeacherWork.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.user.UpdatableTeacherWork.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.user.UpdatableTeacherWork} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.UpdatableTeacherWork.toObject = function(includeInstance, msg) {
  var f, obj = {
    teacherWork: (f = msg.getTeacherWork()) && proto.kazatel.user.TeacherWork.toObject(includeInstance, f),
    updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.user.UpdatableTeacherWork}
 */
proto.kazatel.user.UpdatableTeacherWork.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.user.UpdatableTeacherWork;
  return proto.kazatel.user.UpdatableTeacherWork.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.user.UpdatableTeacherWork} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.user.UpdatableTeacherWork}
 */
proto.kazatel.user.UpdatableTeacherWork.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.kazatel.user.TeacherWork;
      reader.readMessage(value,proto.kazatel.user.TeacherWork.deserializeBinaryFromReader);
      msg.setTeacherWork(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.user.UpdatableTeacherWork.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.user.UpdatableTeacherWork.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.user.UpdatableTeacherWork} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.UpdatableTeacherWork.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeacherWork();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.kazatel.user.TeacherWork.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional TeacherWork teacher_work = 1;
 * @return {?proto.kazatel.user.TeacherWork}
 */
proto.kazatel.user.UpdatableTeacherWork.prototype.getTeacherWork = function() {
  return /** @type{?proto.kazatel.user.TeacherWork} */ (
    jspb.Message.getWrapperField(this, proto.kazatel.user.TeacherWork, 1));
};


/**
 * @param {?proto.kazatel.user.TeacherWork|undefined} value
 * @return {!proto.kazatel.user.UpdatableTeacherWork} returns this
*/
proto.kazatel.user.UpdatableTeacherWork.prototype.setTeacherWork = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.user.UpdatableTeacherWork} returns this
 */
proto.kazatel.user.UpdatableTeacherWork.prototype.clearTeacherWork = function() {
  return this.setTeacherWork(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.user.UpdatableTeacherWork.prototype.hasTeacherWork = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.kazatel.user.UpdatableTeacherWork.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.kazatel.user.UpdatableTeacherWork} returns this
*/
proto.kazatel.user.UpdatableTeacherWork.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.user.UpdatableTeacherWork} returns this
 */
proto.kazatel.user.UpdatableTeacherWork.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.user.UpdatableTeacherWork.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.user.TeacherEducation.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.user.TeacherEducation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.user.TeacherEducation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.TeacherEducation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    institutionName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    specialization: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.user.TeacherEducation}
 */
proto.kazatel.user.TeacherEducation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.user.TeacherEducation;
  return proto.kazatel.user.TeacherEducation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.user.TeacherEducation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.user.TeacherEducation}
 */
proto.kazatel.user.TeacherEducation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecialization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.user.TeacherEducation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.user.TeacherEducation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.user.TeacherEducation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.TeacherEducation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getInstitutionName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSpecialization();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.kazatel.user.TeacherEducation.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.user.TeacherEducation} returns this
 */
proto.kazatel.user.TeacherEducation.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string institution_name = 2;
 * @return {string}
 */
proto.kazatel.user.TeacherEducation.prototype.getInstitutionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.TeacherEducation} returns this
 */
proto.kazatel.user.TeacherEducation.prototype.setInstitutionName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string specialization = 3;
 * @return {string}
 */
proto.kazatel.user.TeacherEducation.prototype.getSpecialization = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.TeacherEducation} returns this
 */
proto.kazatel.user.TeacherEducation.prototype.setSpecialization = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.user.UpdatableTeacherEducation.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.user.UpdatableTeacherEducation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.user.UpdatableTeacherEducation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.UpdatableTeacherEducation.toObject = function(includeInstance, msg) {
  var f, obj = {
    teacherEducation: (f = msg.getTeacherEducation()) && proto.kazatel.user.TeacherEducation.toObject(includeInstance, f),
    updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.user.UpdatableTeacherEducation}
 */
proto.kazatel.user.UpdatableTeacherEducation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.user.UpdatableTeacherEducation;
  return proto.kazatel.user.UpdatableTeacherEducation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.user.UpdatableTeacherEducation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.user.UpdatableTeacherEducation}
 */
proto.kazatel.user.UpdatableTeacherEducation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.kazatel.user.TeacherEducation;
      reader.readMessage(value,proto.kazatel.user.TeacherEducation.deserializeBinaryFromReader);
      msg.setTeacherEducation(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.user.UpdatableTeacherEducation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.user.UpdatableTeacherEducation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.user.UpdatableTeacherEducation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.UpdatableTeacherEducation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeacherEducation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.kazatel.user.TeacherEducation.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional TeacherEducation teacher_education = 1;
 * @return {?proto.kazatel.user.TeacherEducation}
 */
proto.kazatel.user.UpdatableTeacherEducation.prototype.getTeacherEducation = function() {
  return /** @type{?proto.kazatel.user.TeacherEducation} */ (
    jspb.Message.getWrapperField(this, proto.kazatel.user.TeacherEducation, 1));
};


/**
 * @param {?proto.kazatel.user.TeacherEducation|undefined} value
 * @return {!proto.kazatel.user.UpdatableTeacherEducation} returns this
*/
proto.kazatel.user.UpdatableTeacherEducation.prototype.setTeacherEducation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.user.UpdatableTeacherEducation} returns this
 */
proto.kazatel.user.UpdatableTeacherEducation.prototype.clearTeacherEducation = function() {
  return this.setTeacherEducation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.user.UpdatableTeacherEducation.prototype.hasTeacherEducation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.kazatel.user.UpdatableTeacherEducation.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.kazatel.user.UpdatableTeacherEducation} returns this
*/
proto.kazatel.user.UpdatableTeacherEducation.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.user.UpdatableTeacherEducation} returns this
 */
proto.kazatel.user.UpdatableTeacherEducation.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.user.UpdatableTeacherEducation.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.user.BonusInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.user.BonusInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.user.BonusInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.BonusInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sum: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.user.BonusInfo}
 */
proto.kazatel.user.BonusInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.user.BonusInfo;
  return proto.kazatel.user.BonusInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.user.BonusInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.user.BonusInfo}
 */
proto.kazatel.user.BonusInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.user.BonusInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.user.BonusInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.user.BonusInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.BonusInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSum();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.kazatel.user.BonusInfo.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.BonusInfo} returns this
 */
proto.kazatel.user.BonusInfo.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 sum = 2;
 * @return {number}
 */
proto.kazatel.user.BonusInfo.prototype.getSum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.user.BonusInfo} returns this
 */
proto.kazatel.user.BonusInfo.prototype.setSum = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.user.BonusOperation.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.user.BonusOperation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.user.BonusOperation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.BonusOperation.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operationDate: (f = msg.getOperationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    operationTypeId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    countBonus: jspb.Message.getFieldWithDefault(msg, 4, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 5, ""),
    operationName: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.user.BonusOperation}
 */
proto.kazatel.user.BonusOperation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.user.BonusOperation;
  return proto.kazatel.user.BonusOperation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.user.BonusOperation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.user.BonusOperation}
 */
proto.kazatel.user.BonusOperation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setOperationDate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOperationTypeId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCountBonus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperationName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.user.BonusOperation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.user.BonusOperation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.user.BonusOperation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.user.BonusOperation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperationDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOperationTypeId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCountBonus();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOperationName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.kazatel.user.BonusOperation.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.BonusOperation} returns this
 */
proto.kazatel.user.BonusOperation.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp operation_date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.kazatel.user.BonusOperation.prototype.getOperationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.kazatel.user.BonusOperation} returns this
*/
proto.kazatel.user.BonusOperation.prototype.setOperationDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.user.BonusOperation} returns this
 */
proto.kazatel.user.BonusOperation.prototype.clearOperationDate = function() {
  return this.setOperationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.user.BonusOperation.prototype.hasOperationDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 operation_type_id = 3;
 * @return {number}
 */
proto.kazatel.user.BonusOperation.prototype.getOperationTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.user.BonusOperation} returns this
 */
proto.kazatel.user.BonusOperation.prototype.setOperationTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 count_bonus = 4;
 * @return {number}
 */
proto.kazatel.user.BonusOperation.prototype.getCountBonus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.user.BonusOperation} returns this
 */
proto.kazatel.user.BonusOperation.prototype.setCountBonus = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string comment = 5;
 * @return {string}
 */
proto.kazatel.user.BonusOperation.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.BonusOperation} returns this
 */
proto.kazatel.user.BonusOperation.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string operation_name = 6;
 * @return {string}
 */
proto.kazatel.user.BonusOperation.prototype.getOperationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.user.BonusOperation} returns this
 */
proto.kazatel.user.BonusOperation.prototype.setOperationName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


goog.object.extend(exports, proto.kazatel.user);
