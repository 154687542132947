import { useStore } from 'vuex';
import { fetchNodes, fetchBook } from "@/services/TaskReader";

import STATES from '@/utils/states';
import { createTree } from '@/hooks/books/utils.js';


const store = useStore();


export const bookContent = {
  namespaced: true,

  state: () => ({
    // Book nodes accessed by node id
    nodes: null,
    nodesLoadingState: STATES.INIT,
    nodesLoadingError: null,
    overview: null,
    overviewLoadingState: STATES.INIT,
    overviewLoadingError: null,
  }),

  getters: {
    getNodes: state => state.nodes,
    getNodesLoadingState: state => state.nodesLoadingState,
    getNodesLoadingError: state => state.nodesLoadingError,
    
    createNodesTree(state, getters, rootState, rootGetters) {
      const nodes = [];
      try {
        if (state.nodesLoadingState === STATES.LOADED) {
          const nodesCopy = structuredClone(state.nodes);
          const rootNode = createTree(nodesCopy, rootGetters['bookTree/getNodeTypes']);
          if (rootNode) {
            for (const key in rootNode.children) {
              nodes.push(rootNode.children[key]);
            }
          }
        }
      } catch (error) {
        console.warn('CREATING TREE:', error);
      } finally {
        return nodes;
      }
    },

    getOverview: state => state.overview,
    getOverviewLoadingState: state => state.overviewLoadingState,
    getOverviewLoadingError: state => state.overviewLoadingError,
  },

  mutations: {
    setNodes(state, newNodes) {
      state.nodes = newNodes;
    },
    setNodesLoadingState(state, newState) {
      state.nodesLoadingState = newState;
    },
    setNodesLoadingError(state, newError) {
      state.nodesLoadingError = newError;
    },
    setOverview(state, newOverview) {
      state.overview = newOverview;
    },
    setOverviewLoadingState(state, newState) {
      state.overviewLoadingState = newState;
    },
    setOverviewLoadingError(state, newError) {
      state.overviewLoadingError = newError;
    }
  },

  actions: {
    createBookContent({getters}) {
      const paragraphs = getters.getParagraphs.map(par => ({
        ...par,
        content: [...getters.getTasks]
      }))
      
      const bookContent = getters.getChapters.map(chp => ({
        ...chp,
        children: [...paragraphs]
      }))

      return bookContent
    },

    async fetchBookNodes({ commit, dispatch }, bookId) {
      try {
        commit('setNodesLoadingState', STATES.LOADING);
        await dispatch('bookTree/fetchNodeTypes', null, { root: true });
        const response = await fetchNodes(bookId);
        const newNodes = {};
        // console.log('BOOK CONTENT LENGTH:', response.length);
        response.forEach(res => {
          const id = res.getId();
          newNodes[id] = {
            id,
            parentId: res.getParentId(),
            title: res.getTitle(),
            prevId: res.getPrevId(),
            nextId: res.getNextId(),
            typeId: res.getTypeId(),
            children: [],
            content: [],
            competition: res.getContest(),
          };
        });
        commit('setNodes', newNodes);
        commit('setNodesLoadingState', STATES.LOADED);
      } catch (error) {
        commit('setNodesLoadingError', error);
        commit('setNodesLoadingState', STATES.ERROR);
        throw error;
      }
    },

    async fetchBookOverview({ commit }, bookId) {
      try {
        commit('setOverviewLoadingError', null);
        commit('setOverviewLoadingState', STATES.LOADING);
        const response = await fetchBook(bookId);
        const book = response.toObject().book;
        if (!book) {
          throw new Error('Book response is undefined');
        }
        commit('setOverview', book);
        commit('setOverviewLoadingState', STATES.LOADED);
      } catch (error) {
        commit('setOverviewLoadingError', error);
        commit('setOverviewLoadingState', STATES.ERROR);
        throw error;
      }
    }
  }
}