<template>
  <card-background class="editor-intro">
    <div class="editor-intro__header">
      <heading-size-h2>Инструкция по получению «денежки»</heading-size-h2>
      <button class="editor-intro__button" @click="visibility = !visibility">
        <svg v-if="!visibility" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7068 15.7083C12.5193 15.8958 12.265 16.0011 11.9998 16.0011C11.7347 16.0011 11.4803 15.8958 11.2928 15.7083L5.63582 10.0513C5.54031 9.95907 5.46413 9.84873 5.41172 9.72672C5.35931 9.60472 5.33172 9.4735 5.33057 9.34072C5.32942 9.20794 5.35472 9.07626 5.405 8.95337C5.45528 8.83047 5.52953 8.71882 5.62343 8.62492C5.71732 8.53103 5.82897 8.45678 5.95187 8.4065C6.07476 8.35622 6.20644 8.33092 6.33922 8.33207C6.472 8.33322 6.60322 8.36081 6.72522 8.41322C6.84723 8.46563 6.95757 8.54181 7.04982 8.63732L11.9998 13.5873L16.9498 8.63732C17.1384 8.45516 17.391 8.35437 17.6532 8.35665C17.9154 8.35892 18.1662 8.46409 18.3516 8.6495C18.537 8.83491 18.6422 9.08572 18.6445 9.34792C18.6468 9.61011 18.546 9.86272 18.3638 10.0513L12.7068 15.7083Z" fill="#97989C"/>
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2932 8.29168C11.4807 8.10421 11.735 7.99889 12.0002 7.99889C12.2653 7.99889 12.5197 8.10421 12.7072 8.29168L18.3642 13.9487C18.4597 14.0409 18.5359 14.1513 18.5883 14.2733C18.6407 14.3953 18.6683 14.5265 18.6694 14.6593C18.6706 14.7921 18.6453 14.9237 18.595 15.0466C18.5447 15.1695 18.4705 15.2812 18.3766 15.3751C18.2827 15.469 18.171 15.5432 18.0481 15.5935C17.9252 15.6438 17.7936 15.6691 17.6608 15.6679C17.528 15.6668 17.3968 15.6392 17.2748 15.5868C17.1528 15.5344 17.0424 15.4582 16.9502 15.3627L12.0002 10.4127L7.05018 15.3627C6.86158 15.5448 6.60898 15.6456 6.34678 15.6434C6.08458 15.6411 5.83377 15.5359 5.64836 15.3505C5.46295 15.1651 5.35778 14.9143 5.35551 14.6521C5.35323 14.3899 5.45402 14.1373 5.63618 13.9487L11.2932 8.29168Z" fill="#D8473A"/>
        </svg>
      </button>
    </div>
    <div class="editor-intro__main" v-show="visibility">
      <div class="progress-container">
        <div class="edit-progress">
          <template v-for="(step, ind) in getProgressSteps">
            <div class="edit-progress__target" :class="produceTargetClas(ind)">
              <span v-if="ind === getCurrentStep" class="target__title">
                {{ ind + 1 }}
              </span>
              <div class="target__description">{{ step }}</div>
            </div>
            <div
              v-if="ind !== getProgressSteps.length - 1"
              class="edit-progress__link"
              :class="{'edit-progress__link_active': ind < getCurrentStep}"
            ></div>
          </template>
        </div>
      </div>
      <h3 class="editor-intro__subtitle">Посмотри видео «Как добавить свое решение»</h3>
      <div class="video-container">
        <player-video borderRadius="20px" :links="[videoInstruction]"/>
      </div>
    </div>
  </card-background>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { mapGetters, mapMutations } from 'vuex';

import CardBackground from '@/components/UI/card/CardBackground.vue';
import ContainerAccordion from '@/components/UI/containers/ContainerAccordion.vue';
import HeadingSizeH2 from '@/components/UI/texts/headings/HeadingSizeH2.vue';


export default {
  name: 'editor-intro',

  components: {
    CardBackground,
    HeadingSizeH2,
    ContainerAccordion,
    PlayerVideo: defineAsyncComponent({
      loader: () => import('@/components/UI/media/PlayerVideo.vue')
    }),
  },

  data() {
    return {
      visibility: true,
      videoInstruction: {
        src: "https://kazatel.su/images/kazatel.mp4",
        type: 'video/mp4'
      }
    }
  },

  watch: {
    $route: {
      handler() {
        switch(this.$route.name) {
          case 'editor-resh':
            this.setStepNumber(0);
            break;
          case 'editor-course':
            this.setStepNumber(1);
            break;
          case 'editor-book-content':
            this.setStepNumber(2);
            break;
          case 'editor-task':
            this.setStepNumber(3);
            break;
        }
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters({
      getProgressSteps: 'editorIntro/getSteps',
      getCurrentStep: 'editorIntro/getCurrentStep'
    })
  },

  methods: {
    ...mapMutations({
      setStepNumber: 'editorIntro/setStepNumber'
    }),

    produceTargetClas(ind) {
      const targetClass = []
      if (ind < this.getCurrentStep) {
        targetClass.push('edit-progress__target_passed');
      } else if ( ind === this.getCurrentStep) {
        targetClass.push('edit-progress__target_current');
      } else {
        targetClass.push('edit-progress__target_next');
      }
      return targetClass
    }
  }
}
</script>

<style scoped>
.editor-intro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}
.editor-intro__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  width: 100%;
}
.editor-intro__button {
  border: none;
  background-color: transparent;
  padding: 0;
}
.editor-intro__subtitle {
  color: var(--text-black);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.06px;
  }
.editor-intro__main {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}
.video-container {
  width: 100%;
  height: auto;
}
.progress-container {
  width: 100%;
  min-height: 76px;
}
.edit-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: min-content;
}
.edit-progress__target {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.edit-progress__target_passed {
  background-color: var(--tag-active);
}
.edit-progress__target_passed::before {
  --br: 2px solid white;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 8px;
  border-bottom: var(--br);
  border-left: var(--br);
  transform: translate(-50%, -75%) rotate(-45deg);
}
/*.edit-progress__target_passed {
  background-color: var(--tag-active);
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.99713 16.2003L5.49713 12.7003C5.4056 12.6076 5.2966 12.5341 5.17644 12.4839C5.05628 12.4337 4.92735 12.4078 4.79713 12.4078C4.6669 12.4078 4.53797 12.4337 4.41781 12.4839C4.29765 12.5341 4.18865 12.6076 4.09713 12.7003C4.00449 12.7918 3.93094 12.9008 3.88074 13.0209C3.83054 13.1411 3.80469 13.27 3.80469 13.4003C3.80469 13.5305 3.83054 13.6594 3.88074 13.7796C3.93094 13.8997 4.00449 14.0087 4.09713 14.1003L8.28713 18.2903C8.67713 18.6803 9.30713 18.6803 9.69713 18.2903L20.2971 7.70025C20.3898 7.60873 20.4633 7.49973 20.5135 7.37957C20.5637 7.25941 20.5896 7.13048 20.5896 7.00025C20.5896 6.87003 20.5637 6.7411 20.5135 6.62094C20.4633 6.50078 20.3898 6.39177 20.2971 6.30025C20.2056 6.20761 20.0966 6.13406 19.9764 6.08386C19.8563 6.03366 19.7274 6.00781 19.5971 6.00781C19.4669 6.00781 19.338 6.03366 19.2178 6.08386C19.0977 6.13406 18.9887 6.20761 18.8971 6.30025L8.99713 16.2003Z' fill='white'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
}*/
.edit-progress__target_current {
  width: 40px;
  height: 40px;
  background-color: var(--text-light);
}
.edit-progress__target_next {
  background-color: var(--background-light-20);
}
.target__title {
  display: inline-block;
  color: var(--bg-white, #FFF);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.06px;
}
.target__description {
  position: absolute;
  bottom: 50%;
  left: calc(100% + 22px);
  transform: translateY(50%);
  text-wrap: nowrap;

  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.048px;
}
.edit-progress__target_passed > .target__description {
  color: var(--text-black, #505156);
  font-size: 14px;
}
.edit-progress__target_current > .target__description {
  left: calc(100% + 16px);
  color: var(--text-black, #505156);
  font-size: 16px;
  font-weight: 600;
}
.edit-progress__target_next > .target__description {
  color: var(--text-secondary, #97989C);
  font-size: 14px;
}
.edit-progress__link {
  width: 5px;
  height: 40px;
  background-color: var(--background-light);
  border-radius: 4px;
}
.edit-progress__link_active {
  background-color: var(--tag-active);
}

@media screen and (min-width: 992px) {
  .progress-container {
    width: 90%;
    min-height: 76px;
  }
  .edit-progress {
    flex-direction: row;
    gap: 12px;
    width: 100%;
  }
  .target__description {
    position: absolute;
    bottom: -38px;
    left: 0;
    transform: none;
  }
  .edit-progress__target_passed > .target__description {
    font-size: 16px;
  }
  .edit-progress__target_current > .target__description {
    bottom: -32px;
    left: 0;
    font-size: 18px;
  }
  .edit-progress__target_next > .target__description {
    font-size: 16px;
  }
  .target__description_last {
    left: initial;
    right: 0;
  }
  .edit-progress__link {
    flex-grow: 1;
    width: initial;
    height: 5px;
  }
  .video-container {
    width: 660px;
    height: 370px;
  }
}
</style>
