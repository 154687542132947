<template>
  <div class="divide-wrapper">
    <main class="container__main" ref="mainContent">
      <router-view />
    </main>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import SubjectClassFilter from '@/components/common/SubjectClassFilter.vue';
import HeaderComponent from '@/components/common/HeaderComponent';
import FooterComponent from '@/components/common/FooterComponent';
import FavoritesComponent from '@/components/common/FavoritesComponent.vue';
import SubHeaderComponent from '@/components/common/SubHeaderComponent';
import EditorIntro from '@/views/tasks/components/EditorIntro.vue';
import CookieNotify from '@/components/UI/CookieNotify.vue';

import CardBackground from '@/components/UI/card/CardBackground.vue';
import TabsNavigation from '@/components/KazUI/atoms/tabsNavigation';
import Loader from '@/components/KazUI/atoms/loader'
import Button from '@/components/KazUI/atoms/button'


import HeaderCommon from '@/components/common/Header';


export default {
  name: 'gpt-layout',

  components: {
    HeaderComponent,
    FooterComponent,
    SubjectClassFilter,
    FavoritesComponent,
    SubHeaderComponent,
    EditorIntro,
    CookieNotify,
    CardBackground,
    TabsNavigation,
    HeaderCommon,
    Loader,
    Button
},

  data() {
    return {
 
    }
  },

  computed: {
    ...mapGetters({
      getGrades: 'gradeData/getGrades',
      sessionActive: 'authData/sessionActive',
      isModerator: 'userData/isModerator',
    }),

  },

  methods: {
    ...mapActions({
      fetchGrades: 'gradeData/fetchGrades',
      fetchCourses: 'courseData/fetchCourses',
      fetchUser: 'userData/fetchUser',
      fetchUserRoles: 'userData/fetchUserRoles'
    }),

    async getUser() {
      try {
        await this.fetchUser();
      }
      catch(err) {
        console.log('Error in getUser:', err);
      }
    },

    async getRoles() {
      try {
        await this.fetchUserRoles();
      }
      catch(err) {
        console.log('Error in getRoles:', err);
      }
    },

  },

  async created() {
    if (this.sessionActive) {
      console.warn('session is active, fetching user data...');
      await this.getUser();
      await this.getRoles();
    } else {
      console.warn('session is nonactive');
    }
    // console.log('BIG PROBLEM:', this.$getTokenInfo());
  },

  mounted() {

  },
}
</script>

<style scoped>
.divide-wrapper{
  width: 100%;
  max-height: 100%;
  padding: 0;
  
}
.container__main{
  /* background-color: var(--kaz-base-base-09) */
}
</style>
