
class Logger {
  constructor(prefix) {
    console.log('Logger:', prefix);
    this.prefix = prefix;
  }

  log(message) {
    console.log(`${this.prefix}: ${message}`);
  }
}


function fetchStreamData(stream, prefix = '-') {
  return new Promise((resolve, reject) => {
    const streamData = [];
    
    stream.on('data', (response) => {
      streamData.push(response);
    });

    stream.on('end', (end) => {
      console.log(`Fetch stream data [END]: DONE, ${prefix + ', ' + streamData.length}`);
      stream.cancel();
      resolve(streamData);
    });

    stream.on('status', (status) => {
      if (status.code) {
        reject(status);
      } else {
        console.log('Fetch stream data [STATUS.code]:', status.code);
        console.log('Fetch stream data [STATUS.details]:', status.details);
        console.log('Fetch stream data [STATUS.metadata]:', status.metadata);
      }
    })
  })
}

function fetchMethodData(service, method, args) {
  return new Promise((resolve, reject) => {
    if (!('request' in args)) {
      reject(new Error('Request is required.'));
    }
    const request = args['request'];
    let metadata = {};
    if ('metadata' in args) {
      metadata = args['metadata'];
    }

    try {
      service[method](request, metadata, (err, res) => {
        if (err) {
          reject(err);
        }
        resolve(res);
      }); 
    } catch (error) {
      reject(error);
    }
  })
}


export { fetchStreamData, fetchMethodData }
