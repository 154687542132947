function openPublicFile(link, target = '_blank') {
  try {
    window.open(link, target);
  } catch (error) {
    console.error(error);   
  }
}


export {
  openPublicFile,
}