function loadUint8ArrayFile(file) {
    /*
    file - object of File class.
    */
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = () => {
        resolve(new Uint8Array(reader.result));
      }
  
      reader.onerror = () => {
        reject(new Error(`Error when reading file ${file}`));
      }
  
      reader.readAsArrayBuffer(file);
    })
  }

function _arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
}

function hexToRgba(hex) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if(c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c>>16)&255, (c>>8)&255, c&255].join(',') + ',1)';
  }
  throw new Error('Incorrect hex format');
}

export {loadUint8ArrayFile, _arrayBufferToBase64, hexToRgba}