<template>
  <main>
    <div class="auth__wrapper">
      <router-view/>
    </div>
  </main>
</template>

<script>
// import 'bootstrap';

export default {
  name: 'auth-layout',
  async created() {
    // await import('bootstrap/dist/css/bootstrap.min.css');
  }
}
</script>

<style scoped>
  .auth__wrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
    height: 95vh;
  }
</style>