<template>
<button class="button button_type_text" @click="emitClick">
  <slot></slot>
</button>
</template>

<script>
export default {
  name: 'button-text',
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
};
</script>

<style src="./button.css"></style>
<style scoped>
.button_type_text {
  color: var(--background-brown);
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;

  transition: .3s;

  max-width: fit-content;
}
.button_type_text:hover {
  color: var(--background-red);
  transition: .3s;
}
</style>
