<template>
  <h3 :style="{ color: textColor, fontWeight: textWeight }" class="heading heading_type_H3">
    <slot></slot>
  </h3>
</template>

<script>
export default {
  name: 'heading-size-h3',

  props: {
    textColor: {
      type: String,
      default: "var(--text-black)"
    },
    textWeight: {
      type: String,
      default: '600',
    }
  }
};
</script>

<style scoped>

.heading_type_H3{
  font-size: 20px;
  font-weight: 600;
  /* line-height: 100%; */
  line-height: 26px;
  letter-spacing: 0.2px;
  color: var(--text-black);
}
</style>