!<template>
  <a
    class="kaz-action" 
    :class="getWrapperClasses"
    @keydown.enter="$emit('click')"
    @keydown.space.prevent="$emit('click')"
    role="button"
    tabindex="0"
  >
    <div :class="getHeaderClasses">
      <span :class="getTitleClasses">
        {{ title }}
      </span>
      <span
        v-if="description" 
        :class="[
          'kaz-action__description', 
          {
            'label_size_XS' : size === 'S', 
            'label_size_S' : size === 'M', 
            'label_size_M' : size === 'L',
          }
        ]"
      >
        {{ description }}
      </span>
    </div>
    <div 
      class="icon-wrapper"
      :class="`icon-wrapper_size_${size}`"
      :style="{ 'background-color': iconBgColor }"
    >
      <slot name="icon"></slot>
    </div>
  </a>
</template>

<script>
const SIZES = ['S', 'M', 'L'];
const SEMANTICS = ['button', 'link'];

const _INCLUDES = arr => val => arr.includes(val);
const SIZE_VALIDATOR = _INCLUDES(SIZES);


export default {
  name: 'kaz-action',

  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'M',
      validator: SIZE_VALIDATOR
    },
    vertical: {
      type: Boolean,
      default: false
    },
    island: {
      type:  Boolean,
      default: true
    },
    iconBgColor: {
      type: String,
      default: 'transparent'
    },
    iconSize: {
      type: String,
      default: 'M',
      validator: SIZE_VALIDATOR
    }
  },

  computed: {
    getWrapperClasses() {
      const classes = [];

      if (this.island) {
        classes.push('kaz-action_island');
        classes.push(`kaz-action_padding_${this.size}`);
        classes.push('kaz-action_alignment_start');
      } else {
        if (this.vertical) {
          classes.push('kaz-action_alignment_center');
        } else {
          classes.push('kaz-action_alignment_start');
        }
      }

      if (this.vertical) {
        classes.push('kaz-action_vertical');
        kaz-action_gap
      } else {
        classes.push('kaz-action_horizontal');
        classes.push('kaz-action_gap');
      }
      return classes
    },
    getHeaderClasses() {
      const classes = ['kaz-action__header'];
      if (this.island) {
        if (this.vertical) {
          if (this.size === 'S') {
            classes.push('kaz-action__header_w88_h36');
          } else if (this.size === 'M') {
            classes.push('kaz-action__header_w108_h44');
          } else if (this.size === 'L') {
            // classes.push('kaz-action__header_w106_h50');
            classes.push('kaz-action__header_w_100');
          }
        } else {
          if (this.size === 'S') {
            classes.push('kaz-action__header_w130_h40');
          } else if (this.size === 'M') {
            classes.push('kaz-action__header_w114_h44');
          } else if (this.size === 'L') {
            // classes.push('kaz-action__header_w106_h50');
            classes.push('kaz-action__header_w_100');
          }
        }
      } else {
        if (this.size === 'S') {
          classes.push('kaz-action__header_w58_h36');
        } else if (this.size === 'M') {
          classes.push('kaz-action__header_w68_h44');
        } else if (this.size === 'L') {
          classes.push('kaz-action__header_w68_h44');
        }
      }

      if (this.vertical && !this.island) {
        classes.push('kaz-action__header_align_center');
      } else {
        classes.push('kaz-action__header_align_start');
      }
      return classes
    },
    getTitleClasses() {
      if(this.size === 'S' || this.size === 'M'){
        const classes = [`label_size_${this.size}`];
          if (this.island) {
            classes.push('kaz-action__title_color_base');
          } else {
            classes.push('kaz-action__title_color_link');
          }
          return classes
      }
      else {
        const classes = ['heading', 'heading_size_h3'];
          if (this.island) {
            classes.push('kaz-action__title_color_base');
          } else {
            classes.push('kaz-action__title_color_link');
          }
          return classes
      }
    }
  }
}
</script>

<style scoped>
.kaz-action {
  display: flex;
  text-decoration: none;
  cursor: pointer;
}

.kaz-action:hover {
  box-shadow: var(--kaz-shadow-default-hover);
}

.kaz-action:focus-visible {
  outline: 2px solid var(--kaz-base-clear-active);
  outline-offset: 1px;
}

.kaz-action_semantic_button {
  border: none;
  padding: 0;
  background: none;
}

.kaz-action_horizontal {
  flex-direction: row;
}

.kaz-action_vertical {
  flex-direction: column;
}

.kaz-action_alignment_center {
  align-items: center;
}

.kaz-action_alignment_start {
  align-items: flex-start;
}

.kaz-action_padding_S {
  padding: 16px;
}

.kaz-action_padding_M {
  padding: 16px;
}

.kaz-action_padding_L {
  padding: 20px;
}

.kaz-action_padding_S {
  padding: 16px;
}

.kaz-action_island {
  border-radius: 16px;
  background: var(--kaz-base-base-01, #fff);
  box-shadow: var(--kaz-shadow-default);
  justify-content: space-between;
}

.kaz-action_gap {
  gap: 12px;
}

.kaz-action__header {
  flex: 0 0 auto;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.kaz-action__header_w130_h40 {
  width: 130px;
  height: 40px;
}

.kaz-action__header_w114_h44 {
  width: 130px;
  height: 44px;
}

.kaz-action__header_w106_h50 {
  width: 130px;
  height: 40px;
}

.kaz-action__header_w58_h36 {
  width: 130px;
  height: 40px;
}

.kaz-action__header_w68_h44 {
  width: 130px;
  height: 40px;
}

.kaz-action__header_w88_h36 {
  width: 130px;
  height: 40px;
}

.kaz-action__header_w108_h44 {
  width: 130px;
  height: 40px;
}

.kaz-action__header_w_100 {
  width: fit-content;
  height: fit-content;
}

.kaz-action__header_align_start {
  text-align: start;
  width: calc(100% - 68px);
}

.kaz-action__header_align_center {
  text-align: center;
}

.kaz-action__title {
  display: block;
}

.kaz-action__title_color_base {
  color: var(--kaz-textIcons-text-01, rgba(25, 28, 48, 0.9));
}

.kaz-action__title_color_link {
  color: var(--kaz-textIcons-link, #A43B31);
}

.kaz-action__description {
  display: block;
  color: var( --kaz-textIcons-text-02, rgba(27, 31, 59, 0.65));
  width: 100%;
}




.icon-wrapper {
  flex: 0 0 auto;
  display: grid;
  place-items: center;
  border-radius: 50%;
}

.icon-wrapper_size_S {
  width: 32px;
  height: 32px;
}

.icon-wrapper_size_M {
  width: 48px;
  height: 48px;
}

.icon-wrapper_size_L {
  width: 48px;
  height: 48px;
}
</style>
