export const relatedLessons = {
  namespaced: true,

  state: () => ({
    // lessons must correspond to subjectId and levelId only
    lessons: null,
    subjectId: null,
    levelId: null,
  }),

  getters: {
    getLessons: state => state.lessons,
  },

  mutations: {
    setLessons(state, { subjectId, levelId, lessons }) {
      try {
        if (!(subjectId && levelId)) {
          throw new Error('subjectId and levelId are required');
        }
        state.subjectId = subjectId;
        state.levelId = levelId;
        state.lessons = lessons;
      } catch (error) {
        console.warn(error);
      }
    }
  }
}