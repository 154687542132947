import {fetchStreamData} from '@/utils/loaders';
import STATES from '@/utils/states';

import { getCustomOrder } from '@/utils/courses';


const {TaskReaderClient, default: books} = require(
  '@/generated/taskreader/taskreader_grpc_web_pb.js'
);


export const courseData = {
  namespaced: true,
  state: () => ({
    courses: [],
    dataState: STATES.INIT,
  }),

  getters: {
    getCourses(state) {
      return state.courses;
    },
    coursesEmpty(state){
      if (state.courses.length > 0)
        return false;
      else
        return true;
    },
    getState(state) {
      return state.dataState;
    }
  },

  mutations: {
    addCourse(state, newCourse) {
      state.courses.push(newCourse)
    },
    clearCourses(state) {
      state.courses = [];
    },
    setState(state, newState) {
      state.dataState = newState;
    },
  },

  actions: {
    async fetchCourses({ commit }) {
      console.log('start to fetch courses from vuex...');
      commit('clearCourses');
      commit('setState', STATES.LOADING);
      try {
        const serviceURL = process.env.VUE_APP_REGISTRATION_SERVICE_URL;
        const taskreaderService = new TaskReaderClient(serviceURL, null, null);
        const streamRequest = new proto.google.protobuf.Empty();
        const stream = taskreaderService.levelsBySubject(streamRequest, {});
        const streamData = await fetchStreamData(stream);

        setTimeout(() => {
          const customOrder = getCustomOrder();
          streamData.forEach(res => {
            const arr = res.getLevelsList();
            if (arr) {
              const newArr = [];
              arr.forEach(element => {
                newArr.push(element.getId());
              });
              // console.log(`order ${res.getName()}:`, customOrder(res.getName()));
              commit('addCourse', {
                id: res.getId(),
                title: res.getName(),
                order: customOrder(res.getName()),
                availableLevel: new Set(newArr)
              });
            }
          });
          commit('setState', STATES.LOADED);
          console.log('fetch courses has been done');
        }, 0);
      } catch (error) {
        commit('setState', STATES.ERROR);
        throw new Error(error)
      }
    }
  }
}