<template>
  <div class="order__view">
    <div class="order__view order__view_row">
      <card-background class="order__block order__block_book">
        <row-book-card
          :bookInfo="bookForOrder"
        />
        <KazButton
          :label="'Перейти к учебнику'"
          :appearance="'secondary'"
          @click="goToBookLink"
        />
      </card-background>

      <card-background class="order__block order__block_info">
        <order-status
          :orderInfo="orderInfo"
        />
        <div class="info__buttons">
          <KazButton
            v-if="orderInfo.status === STATES.NEW || orderInfo.status === STATES.DECLINED"
            :label="'Взять в работу'"
            :appearance="'primary'"
            @click="takeOrderInWork"
          />
          <template v-if="orderInfo.status === STATES.MODERATED">
            <KazButton
              :label="'Одобрить для публикации'"
              :appearance="'primary'"
              @click="publish"
            />
            <KazButton
              :label="'Вернуть задание на доработку'"
              :appearance="'secondary'"
              @click="declinePopupVisibility = !declinePopupVisibility"
            />
          </template>
          <KazButton
            v-if="orderInfo.status === STATES.ACCEPTED"
            :label="'Заблокировать задание'"
            :appearance="'secondary'"
            @click="blockOrder"
          />
        </div>
      </card-background>
    </div>

    <card-background class="order__view">
      <h1 class="heading heading_size_h1">
        Контент для модерации
      </h1>
      <div class="viewer-container">
        <h2 class="heading heading_size_h2">{{ getContentTitle }}</h2>
        <quill-viewer 
          v-if="contentType === CONTENT_TYPES.IMAGE || contentType === CONTENT_TYPES.QUILL"
          :id="contentId"
        />
        <player-audio
          v-else-if="contentType === CONTENT_TYPES.AUDIO"
          :id="contentId"
        />
        <player-video
          v-else-if="contentType === CONTENT_TYPES.VIDEO"
          :id="contentId"
        />
        <div class="viewer__actions">
          <kaz-button
            v-if="orderInfo.status === STATES.MODERATED || orderInfo.status === STATES.OPERATED"
            class="viewer-container__button"
            label="Открыть задание в редакторе"
            appearance="secondary"
            @click="goToEditorPage"
          />
          <kaz-button
            class="viewer-container__button"
            label="Открыть источник задания"
            appearance="outline"
            @click="goToContentPage"
          />
        </div>
      </div>
    </card-background>

    <div class="order__view order__view_row">
      <card-background class="order__block order__block_comments">
        <h2 class="heading heading_size_h2">
          История комментариев к решению
        </h2>
        <div class="block__content-wrapper block__content-wrapper_gap">
          <mod-message
            v-for="message in messages"
            :message="message"
          />
          <div v-if="messages.length === 0">
            Нет комментариев к запросу
          </div>
        </div>

        <!-- <KazButton
          v-if="messages.length > 3"
          :label="'Показать еще комментарии'"
          :size="'M'"
          :appearance="'flat'"
          :mode="'normal'"
          :rightIcon="true"
          :rightIconName="'kuiIconChevronDownLarge'"
        /> -->

      </card-background>

      <card-background class="order__block order__block_tasks">
        <h2 class="heading heading_size_h2">
          Связанные задачи от пользователя {{ orderInfo.author }}
        </h2>

        <div class="block__content-wrapper">
          <kaz-loader v-if="relatedOrdersLoading" size="L"/>
          <template v-else>
            <connected-order-card v-for="order in relatedOrders" :orderInfo="order"/>
            <div v-if="relatedOrders.length === 0">
              Нет связанных задач к запросу
            </div>
          </template>
        </div>

        <!-- <KazButton
          v-if="connectedOrders.length > 3"
          :label="'Показать еще связанные задачи'"
          :size="'M'"
          :appearance="'flat'"
          :mode="'normal'"
          :rightIcon="true"
          :rightIconName="'kuiIconChevronDownLarge'"
        /> -->
      </card-background>
    </div>
  </div>

  <popup-return-order
    :visible="declinePopupVisibility"
    @close="declinePopupVisibility = false"
    @on-submit="handlePopupSubmit"
  />

  <notification
    :visibility="showNotification"
    :mode="'toast'"
    :appearance="'success'"
    :label="'Заявка взята в работу'"
    :actionsNeeded="false"
    :descriptionNeeded="false"
    :closeNeeded="false"
  />
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { mapGetters } from 'vuex';

import CardBackground from '@/components/UI/card/CardBackground.vue';
import KazButton from '@/components/KazUI/atoms/button';
import LinkElement from '@/components/KazUI/atoms/linkElement';
import Notification from '@/components/KazUI/molecules/notification';

import rowBookCard from './components/rowBookCard'
import orderStatus from './components/orderStatus'
import modMessage from './components/modMessage'
import connectedOrderCard from './components/connectedOrderCard'
import popupReturnOrder from './components/popupReturnOrder';
import PlayerAudio from './components/viewers/AudioViewer.vue';
import KazLoader from '@/components/KazUI/atoms/loader';

import STATES from '@/components/moderation/utils/states.js';
import STATES_NAMES from '@/components/moderation/utils/statesNames.js';
import useTaskTypes from '@/views/tasks/hooks/useTaskTypes.js';
import useContentTypes from '@/views/tasks/hooks/useContentTypes.js';
import { DateFormatter } from '@/utils/formatters.js';
import { fetchStreamData } from '@/utils/loaders';
import { fetchBook, fetchTask } from '@/services/TaskReader';
import { blockContent } from '@/services/Moderation';
import useLoadingStates from '@/hooks/loading/useLoadingStates.js';

import { ModerationServiceClient } from '@/generated/moderation/moderation_service_grpc_web_pb.js';

import { deriveContentName } from './utils';


const SERVICE_URL = process.env.VUE_APP_REGISTRATION_SERVICE_URL;
const LOCALE = 'ru-RU';
const ORDER_DATE_OPTIONS = {
  year: '2-digit',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
};
const COMMENT_DATE_OPTIONS = {
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
};

const moderationService = new ModerationServiceClient(SERVICE_URL, null, null);
const dateFormatterOrder = new DateFormatter(LOCALE, ORDER_DATE_OPTIONS);
const dateFormatterComment = new DateFormatter(LOCALE, COMMENT_DATE_OPTIONS);

function removeCurlyBraces(str) {
  // Removes wrapped curly braces
  let formattedStr = str;
  if (typeof str === 'string') {
    const result = str.match(/^\{.*\}$/);
    if (result) {
      formattedStr = str.slice(1, -1);
    }
  }
  return formattedStr
}

export default {
  name: 'moderation-order',

  components: {
    CardBackground,
    rowBookCard,
    orderStatus,
    modMessage,
    connectedOrderCard,
    KazButton,
    LinkElement,
    Notification,
    popupReturnOrder,
    PlayerAudio,
    KazLoader,
    QuillViewer: defineAsyncComponent({
      loader: () => import('./components/viewers/QuillViewer.vue')
    }),
    PlayerVideo: defineAsyncComponent({
      loader: () => import('./components/viewers/VideoViewer.vue')
    }),
  },

  setup() {
    const { 
      TASK_TYPES, 
      BACK_TO_FRONT_TYPES: BACK_TO_FRONT_ENTITY_TYPES 
    } = useTaskTypes();
    const { CONTENT_TYPES } = useContentTypes();
    const { LOADING_STATES } = useLoadingStates();
    return { TASK_TYPES, CONTENT_TYPES, BACK_TO_FRONT_ENTITY_TYPES, LOADING_STATES }
  },

  data() {
    return {
      nodeId: null,
      orderId: null,  // content ID
      bookId: null,
      showNotification: false,
      declinePopupVisibility: false,
      contentTitle: 'Текст решения',
      statesNames: Object.keys(STATES_NAMES).map(k => STATES_NAMES[k]),

      taskId: null,
      entityType: null,
      contentType: null,
      contentId: null,
      ownerLogin: null,

      bookInfoLoading: this.LOADING_STATES.INIT,
      bookForOrder: {
        id: 'c0a83801-893c-12d1-8189-3c6e13dd12ae',
        titleSeo: 'ГДЗ проверочные работы по математике за 8 класс Буцко ФГОС',
        titleSystem: 'Математика: 8-й класс: рабочая тетрадь',
        level: 8,
        subjectId: null,
        subject: 'Математика',
        cover: 'https://kazatel.su/images/c0a83801-893e-1ea7-8189-40fb821a501a/cover.jpg',
        type: 'Рабочая тетрадь',
        authors: ['Соловейчик М.С.', 'Соловейчик М.С.'],
        year: 2020,
        publisher: 'Ассоциация 21 век',
        studyDepth: 'Углубленный уровень',
        parts: [1, 2],
        FGOS: true
      },

      orderInfo:{
        currentModerator: null,
        author: 'Василий Яковлев',
        status: STATES.NEW,
        type: 'Текст решения',
        time:{
          creation: '25/01/24 12:44',
          end: null
        }
      },

      messages:[],

      relatedOrdersLoading: true,
      relatedOrders: [
        /*{
          orderId: 'c0a83801-894f-10f8-8189-112313131',
          orderBook: {
            cover: 'https://kazatel.su/images/c0a83801-893e-1ea7-8189-40fb821a501a/cover.jpg',
            titleSeo: 'ГДЗ проверочные работы по математике за 8 класс Буцко ФГОС',
          },
          order: {
            type: null,   // Видео решение задачи, Аудио решение задачи, ...
            status: null, // На модерации, Новое для модерации, ...
          }
        }*/
      ]
    }
  },

  watch: {
    async $route() {
      await this.fetchDataFull();
    },
    getBookInfoLoadingState(newState) {
      let isReady = true;
      for (const state of newState) {
        if (state !== this.LOADING_STATES.LOADED) {
          isReady = false;
          break;
        }
      }
      if (isReady) {
        const subjId = this.bookForOrder.subjectId;
        if (subjId) {
          const subjTitle = this.getCourses.find(sub => sub.id === +subjId).title;
          this.bookForOrder.subject = subjTitle;
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      getCourses: 'courseData/getCourses',
      getLoadingStateCourses: 'courseData/getState',
      getUser: 'userData/getStudent',
    }),
    getBookInfoLoadingState() {
      return [ this.getLoadingStateCourses, this.bookInfoLoading ];
    },
    getContentTitle() {
      return deriveContentName(this.contentType, this.entityType);
    }
  },

  methods: {
    handlePopupSubmit(data) {
      this.returnOrder(data);
    },
    takeOrderInWork() {
      try {
        const request = new proto.google.protobuf.StringValue();
        request.setValue(this.orderId);
        const tokens = this.$getTokenInfo();
        const metadata = { 'token': tokens.accessToken.token };
        const stream = moderationService.inWork(request, metadata, this.resultHandlerInWork);
      } catch (error) {
        console.error('In Work:', error);
      }
    },
    resultHandlerInWork(err, response) {
      if (err) {
        console.warn('Result Handler In Work:', err);
      } else {
        try {
          this.orderInfo.status = STATES.MODERATED;
          const moderatorLogin = this.getUser.login;
          if (moderatorLogin) {
            this.orderInfo.currentModerator = moderatorLogin;
          }
          this.showNotification = true;
          setTimeout(() => {
            this.showNotification = false;
          }, 5000);
        } catch (error) {
          console.warn(error);
        }
      }

    },
    async returnOrder(data) {
      try {
        const request = new proto.kazatel.moderation.ReturnRequest();
        request.setContentId(this.orderId);
        request.setComment(data.comment);
        const tokens = this.$getTokenInfo();
        const metadata = { 'token': tokens.accessToken.token };
        const stream = moderationService.return(request, metadata, this.resultHandlerReturn);
      } catch (error) {
        console.error('Return:', error);
      }
    },
    resultHandlerReturn(err, response) {
      if (err) {
        console.warn('Result Handler Return:', err);
      } else {
        try {
          this.orderInfo.status = STATES.OPERATED;
          console.log('Returned!');
        } catch (error) {
          console.warn(error);
        }
      }

    },
    publish() {
      try {
        const request = new proto.google.protobuf.StringValue();
        request.setValue(this.orderId);
        const tokens = this.$getTokenInfo();
        const metadata = { 'token': tokens.accessToken.token };
        const stream = moderationService.publish(request, metadata, this.resultHandlerPublish);
      } catch (error) {
        console.error('Publish:', error);
      }
    },
    resultHandlerPublish(err, response) {
      if (err) {
        console.warn('Result Handler Publish:', err);
      } else {
        try {
          this.orderInfo.status = STATES.ACCEPTED;
          console.log('Published!');
        } catch (error) {
          console.warn(error);
        }
      }
    },
    async blockOrder() {
      try {
        await blockContent(this.orderId);
        this.orderInfo.status = STATES.DECLINED;
        console.log('Blocked!');
      } catch (error) {
        console.error(error);
      }
    },
    goToBookLink() {
      if (this.bookId) {
        this.$router.push({ name: 'book-content', params: { bid: this.bookId } });
      }
    },
    goToContentPage() {
      if (this.bookId && this.nodeId) {
        this.$router.push({
          name: 'task', 
          params: { tid: this.nodeId, bid: this.bookId } 
        });
      }
    },
    goToEditorPage() {
      if (this.bookId && this.nodeId) {
        const author = this.orderInfo.author;
        window.open(`/editor/resh/books/${this.bookId}/editor/${this.nodeId}?author=${author}`);
      }
    },
    async loadTasks(id) {
      try {
        console.log('TASKS [LOADING]:', id);
        const request = new proto.kazatel.moderation.ContentRequest();
        request.setContentIdList([id]);
        const tokens = this.$getTokenInfo();
        const metadata = { 'token': tokens.accessToken.token };
        const stream = moderationService.content(request, metadata);
        const streamData = await fetchStreamData(stream);
        streamData.forEach(response => {
          const content = response.toObject();
          // console.log('TASKS [RESPONSE]:', content);

          let year, month, day, hours, minutes;
          ({ year, month, day, hours, minutes } = content.content.created);
          const created = dateFormatterOrder.format(new Date(year, month - 1, day, hours, minutes));

          ({ year, month, day, hours, minutes } = content.moderationEnd);
          const end = dateFormatterOrder.format(new Date(year, month - 1, day, hours, minutes));

          this.entityType = this.BACK_TO_FRONT_ENTITY_TYPES[content.content.type];
          this.contentType = content.content.contentMeta.type;
          this.nodeId = removeCurlyBraces(content.node.id);
          this.contentId = removeCurlyBraces(content.content.id);
          this.bookId = removeCurlyBraces(content.book.id);
          this.taskId = removeCurlyBraces(content.content.taskId);

          this.orderInfo.currentModerator = content.moderator === '' ? 'Не назначен' : content.moderator;
          this.orderInfo.author = content.owner;
          this.ownerLogin = content.owner;
          this.orderInfo.status = this.getStatus(content.content.statusId);
          this.orderInfo.type = deriveContentName(
            content.content.contentMeta.type, 
            this.BACK_TO_FRONT_ENTITY_TYPES[content.content.type]
          );
          this.orderInfo.time.creation = created;
          this.orderInfo.time.end = content.moderator === '' ? '-' : end;
        });
      } catch (err) {
        console.error('TASKS [ERROR]:', err);
      }
    },
    getStatus(statusId) {
      let status = STATES.NEW;
      switch(statusId){
        case 1: status = STATES.NEW;
        break;
        case 2: status = STATES.NEW;
        break;
        case 3: status = STATES.MODERATED;
        break;
        case 4: status = STATES.OPERATED;
        break;
        case 5: status = STATES.ACCEPTED;
        break;
        case 6: status = STATES.DECLINED;
        break;
     }
      return status;
    },
    async loadBookInfo(bookId) {
      try {
        console.log('BOOK-INFO [LOADING]:', bookId);
        this.bookInfoLoading = this.LOADING_STATES.LOADING;
        const response = await fetchBook(bookId);
        const responseObject = response.toObject();
        console.log('BOOK-INFO [RESPONSE]:', responseObject);

        this.bookForOrder.id = bookId;
        this.bookForOrder.authors = responseObject.book.authors.split(/,\s*/);
        this.bookForOrder.level = responseObject.book.levelsList?.[0];
        this.bookForOrder.type = responseObject.book.type;
        this.bookForOrder.year = responseObject.book.publishYear;
        this.bookForOrder.publisher = responseObject.book.publisher;
        this.bookForOrder.cover = responseObject.book.viewUrl;
        this.bookForOrder.titleSystem = responseObject.book.name;
        this.bookForOrder.titleSeo = responseObject.book.name;
        this.bookForOrder.subjectId = responseObject.book.subjectId;
      } catch (error) {
        console.error(error);
      } finally {
        this.bookInfoLoading = this.LOADING_STATES.LOADED;
      }
    },
    async loadHistory(id) {
      try {
        console.log('HISTORY [LOADING]:', id);
        this.messages.length = 0;
        const request = new proto.google.protobuf.StringValue();
        request.setValue(id);
        const tokens = this.$getTokenInfo();
        const metadata = { 'token': tokens.accessToken.token };
        moderationService.history(request, metadata, this.resultHandlerHistory);
      } catch (err) {
        console.error('HISTORY [ERROR]:', err);
      }
    },

    resultHandlerHistory(err, response) {
      if (err) {
        console.warn('Result Handler History:', err);
      } else {
        try {
          this.messages.length = 0;
          const objResponse = response.toObject();
          console.log('HISTORY [RESPONSE]:', objResponse);
          for(const history of objResponse.unitList) {
            const { year, month, day, hours, minutes } = history.created;
            const created = dateFormatterComment.format(new Date(year, month - 1, day, hours, minutes));
            this.messages.push({
              author:{
                avatar: '',
                name: history.login
              },
              message: history.textValue,
              time: created
            });
          }
        } catch (error) {
          console.warn(error);
        }
      }
    },

    async loadRelatedOrders(taskId) {
      try {
        console.log('RELATED-ORDERS [LOADING]:', taskId);
        this.relatedOrders.length = 0;
        this.relatedOrdersLoading = true;
        const response = await fetchTask(taskId);
        const objResponse = response.toObject();
        console.log('RELATED-ORDERS [RESPONSE]:', objResponse);

        const userLogin = objResponse.userId;
        if (userLogin !== this.ownerLogin) {
          console.warn(`User [${this.ownerLogin}] doesnt have related tasks`);
          return;
        }

        const orderBook = {
          cover: this.bookForOrder.cover,
          titleSeo: this.bookForOrder.titleSystem
        };
        // Dont include moderationId = 1 (DRAFT) and contentMeta = undefined
        for (const contentList of objResponse.versionsList) {
          // Extract solutions
          for (const contentWrapper of contentList.solutionsList) {
            const contentId = removeCurlyBraces(contentWrapper.id);
            if (contentId !== this.orderId 
              && contentWrapper.contentMeta !== undefined 
              && contentWrapper.statusId !== 1
            ) {
              this.relatedOrders.push({
                orderBook,
                orderId: contentId,
                order: { 
                  type: deriveContentName(
                    contentWrapper.contentMeta?.type || this.CONTENT_TYPES.QUILL, 
                    this.BACK_TO_FRONT_ENTITY_TYPES[contentWrapper.type]
                  ),
                  status: this.getStatus(contentWrapper.statusId)
                }
              });
            }
          }
          // Extract conditions
          for (const contentWrapper of contentList.conditionsList) {
            const contentId = removeCurlyBraces(contentWrapper.id);
            if (contentId !== this.orderId 
              && contentWrapper.contentMeta !== undefined 
              && contentWrapper.statusId !== 1
            ) {
              this.relatedOrders.push({
                orderBook,
                orderId: contentId,
                order: { 
                  type: deriveContentName(
                    contentWrapper.contentMeta?.type || this.CONTENT_TYPES.QUILL, 
                    this.BACK_TO_FRONT_ENTITY_TYPES[contentWrapper.type]
                  ), 
                  status: this.getStatus(contentWrapper.statusId)
                }
              }); 
            }
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.relatedOrdersLoading = false;
      }
    },
    
    async fetchDataFull() {
      try {
        this.orderId = removeCurlyBraces(this.$route.params.mid);    // content ID
        if (this.orderId) {
          await this.loadTasks(this.orderId);     // this method must be called first
          await this.loadBookInfo(this.bookId);
          await this.loadHistory(this.orderId);
          await this.loadRelatedOrders(this.taskId);
        }
      } catch (error) {
        console.error(error);
      }
    }
  },

  async created() {
    this.STATES = STATES;
    await this.fetchDataFull();
  }
}
</script>

<style scoped>
.order__view{
  display: flex;
  gap: 16px;
  flex-direction: column;
}
.order__view_row{
  flex-direction: row
}
.order__view_item{
  width: 100%;
}
.order__block{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.order__block_book{
  justify-content: space-between;
  max-width: 792px;
}
.order__block_info{
  gap: 16px;
}
.info__buttons{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
}
.order__block_comments{
  gap: 16px;
}
.order__block_tasks{
  gap: 16px;
  align-items: normal;
}
.block__content-wrapper{
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.block__content-wrapper_gap{
  gap: 16px;
}

.viewer-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--kaz-base-secondary);
  gap: 16px;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
}

.viewer__actions{
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-left: auto;
}

.viewer-container__button {
  margin-left: auto;
}

@media screen and (max-width: 1600px) {
  .order__view_row{
    flex-direction: column;
  }
  .order__block_book{
    justify-content: flex-start;
    gap: 16px;
    max-width: initial;
  }
}
</style>