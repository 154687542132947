const routes = [
  {
    path: '/competition',
    name: 'competition',
    meta: { layout: 'auth' },
    beforeEnter() {
      window.location.href = `/competition.pdf`;
    },
  },
  {
    path: '/offer',
    name: 'offer',
    meta: { layout: 'auth' },
    beforeEnter() {
      window.location.href = `/oferta.pdf`;
    },
  },
  {
    path: '/policy',
    name: 'policy',
    meta: { layout: 'auth' },
    beforeEnter() {
      window.location.href = `/personal-data.pdf`;
    },
  },
  {
    path: '/terms_of_use',
    name: 'terms_of_use',
    meta: { layout: 'auth' },
    beforeEnter() {
      window.location.href = `/user-agreement.pdf`;
    },
  },
  {
    path: '/autopayment-agreement',
    name: 'autopayment-agreement',
    meta: { layout: 'auth' },
    beforeEnter() {
      window.location.href = `/autopayment-agreement.pdf`;
    },
  },
  {
    path: '/manual-money',
    name: 'manual-money',
    meta: { layout: 'auth' },
    beforeEnter() {
      window.location.href = `/manual-money.pdf`;
    },
  },
  {
    path: '/manual-promocodes',
    name: 'manual-promocodes',
    meta: { layout: 'auth' },
    beforeEnter() {
      window.location.href = `/manual-promocodes.pdf`;
    },
  },

  {
    path: '/docs',
    children: [
      {
        path: 'user-agreement',
        name: 'user-agreement',
        meta: { layout: 'docs' },
        component: () => import('@/views/docs/UserAgreement.vue')
      },
      {
        path: 'personal-data',
        name: 'personal-data',
        meta: { layout: 'docs' },
        component: () => import('@/views/docs/PersonalData.vue')
      },
      {
        path: 'user-oferta',
        name: 'user-oferta',
        meta: { layout: 'docs' },
        component: () => import('@/views/docs/UserOferta.vue')
      },
      {
        path: 'agent-oferta',
        name: 'agent-oferta',
        meta: { layout: 'docs' },
        component: () => import('@/views/docs/AgentOferta.vue')
      },
    ]
  },
];

export default routes;
