import { ref,  onBeforeUnmount } from "vue";


export default function useIntersectionObserver({options = null, callback = null} = {}) {
  if (!(options && callback)) {
    console.error('Options and callback parameters MUST be defined.');
  }

  let intersectionObserver = null;
  let observed = false;
  const observedElement = ref(null);
  const observedElements = ref({});

  try {
    intersectionObserver =  new IntersectionObserver(callback, options); 
  } catch (error) {
    console.error(error);
  }

  function startObservation(element) {
    if (!intersectionObserver) {
      console.warn('Intersection Observer instance is not defined.');
      return
    }
    if (!observed) {
      observedElement.value = element;
      intersectionObserver.observe(element);
      observed = true;
    }
  }

  function startMultipleObservation(elementKey, element) {
    if (!intersectionObserver) {
      console.warn('Intersection Observer instance is not defined.');
      return
    }
    if (!observedElements.value[elementKey]) {
      intersectionObserver.observe(element);
      observedElements.value[elementKey] = true;
    }
  }

  onBeforeUnmount(() => {
    if (intersectionObserver) {
      intersectionObserver.disconnect();
      console.log('Intersection Observer has been disconnected');
    }
  });

  return {
    observed,
    observedElement,
    observedElements,
    intersectionObserver,
    startObservation,
    startMultipleObservation,
  }
}