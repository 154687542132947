/**
 * @fileoverview gRPC-Web generated client stub for kazatel.user
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.23.1
// source: user/registration.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var user_user_pb = require('../user/user_pb.js')
const proto = {};
proto.kazatel = {};
proto.kazatel.user = require('./registration_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.kazatel.user.RegistrationClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.kazatel.user.RegistrationPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.user.LiteRegistrationInfo,
 *   !proto.kazatel.user.LiteRegistrationInfoResponse>}
 */
const methodDescriptor_Registration_CreateLite = new grpc.web.MethodDescriptor(
  '/kazatel.user.Registration/CreateLite',
  grpc.web.MethodType.UNARY,
  proto.kazatel.user.LiteRegistrationInfo,
  proto.kazatel.user.LiteRegistrationInfoResponse,
  /**
   * @param {!proto.kazatel.user.LiteRegistrationInfo} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.user.LiteRegistrationInfoResponse.deserializeBinary
);


/**
 * @param {!proto.kazatel.user.LiteRegistrationInfo} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.user.LiteRegistrationInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.user.LiteRegistrationInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.RegistrationClient.prototype.createLite =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.Registration/CreateLite',
      request,
      metadata || {},
      methodDescriptor_Registration_CreateLite,
      callback);
};


/**
 * @param {!proto.kazatel.user.LiteRegistrationInfo} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.user.LiteRegistrationInfoResponse>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.RegistrationPromiseClient.prototype.createLite =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.Registration/CreateLite',
      request,
      metadata || {},
      methodDescriptor_Registration_CreateLite);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.user.VerificationCode,
 *   !proto.kazatel.user.UserTokens>}
 */
const methodDescriptor_Registration_VerifyLiteRegistration = new grpc.web.MethodDescriptor(
  '/kazatel.user.Registration/VerifyLiteRegistration',
  grpc.web.MethodType.UNARY,
  proto.kazatel.user.VerificationCode,
  user_user_pb.UserTokens,
  /**
   * @param {!proto.kazatel.user.VerificationCode} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  user_user_pb.UserTokens.deserializeBinary
);


/**
 * @param {!proto.kazatel.user.VerificationCode} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.user.UserTokens)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.user.UserTokens>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.RegistrationClient.prototype.verifyLiteRegistration =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.Registration/VerifyLiteRegistration',
      request,
      metadata || {},
      methodDescriptor_Registration_VerifyLiteRegistration,
      callback);
};


/**
 * @param {!proto.kazatel.user.VerificationCode} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.user.UserTokens>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.RegistrationPromiseClient.prototype.verifyLiteRegistration =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.Registration/VerifyLiteRegistration',
      request,
      metadata || {},
      methodDescriptor_Registration_VerifyLiteRegistration);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.kazatel.user.UserTokens>}
 */
const methodDescriptor_Registration_ChangePassword = new grpc.web.MethodDescriptor(
  '/kazatel.user.Registration/ChangePassword',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.StringValue,
  user_user_pb.UserTokens,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  user_user_pb.UserTokens.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.user.UserTokens)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.user.UserTokens>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.RegistrationClient.prototype.changePassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.Registration/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_Registration_ChangePassword,
      callback);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.user.UserTokens>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.RegistrationPromiseClient.prototype.changePassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.Registration/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_Registration_ChangePassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Registration_Delete = new grpc.web.MethodDescriptor(
  '/kazatel.user.Registration/Delete',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.RegistrationClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.Registration/Delete',
      request,
      metadata || {},
      methodDescriptor_Registration_Delete,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.RegistrationPromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.Registration/Delete',
      request,
      metadata || {},
      methodDescriptor_Registration_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.kazatel.user.UserTokens>}
 */
const methodDescriptor_Registration_RefreshTokens = new grpc.web.MethodDescriptor(
  '/kazatel.user.Registration/RefreshTokens',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.StringValue,
  user_user_pb.UserTokens,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  user_user_pb.UserTokens.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.user.UserTokens)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.user.UserTokens>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.RegistrationClient.prototype.refreshTokens =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.Registration/RefreshTokens',
      request,
      metadata || {},
      methodDescriptor_Registration_RefreshTokens,
      callback);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.user.UserTokens>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.RegistrationPromiseClient.prototype.refreshTokens =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.Registration/RefreshTokens',
      request,
      metadata || {},
      methodDescriptor_Registration_RefreshTokens);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.user.AuthorizationParams,
 *   !proto.kazatel.user.UserTokens>}
 */
const methodDescriptor_Registration_Login = new grpc.web.MethodDescriptor(
  '/kazatel.user.Registration/Login',
  grpc.web.MethodType.UNARY,
  proto.kazatel.user.AuthorizationParams,
  user_user_pb.UserTokens,
  /**
   * @param {!proto.kazatel.user.AuthorizationParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  user_user_pb.UserTokens.deserializeBinary
);


/**
 * @param {!proto.kazatel.user.AuthorizationParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.user.UserTokens)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.user.UserTokens>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.RegistrationClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.Registration/Login',
      request,
      metadata || {},
      methodDescriptor_Registration_Login,
      callback);
};


/**
 * @param {!proto.kazatel.user.AuthorizationParams} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.user.UserTokens>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.RegistrationPromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.Registration/Login',
      request,
      metadata || {},
      methodDescriptor_Registration_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Registration_Logout = new grpc.web.MethodDescriptor(
  '/kazatel.user.Registration/Logout',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.RegistrationClient.prototype.logout =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.Registration/Logout',
      request,
      metadata || {},
      methodDescriptor_Registration_Logout,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.RegistrationPromiseClient.prototype.logout =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.Registration/Logout',
      request,
      metadata || {},
      methodDescriptor_Registration_Logout);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.kazatel.user.Devices>}
 */
const methodDescriptor_Registration_ListDevices = new grpc.web.MethodDescriptor(
  '/kazatel.user.Registration/ListDevices',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.kazatel.user.Devices,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.user.Devices.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.user.Devices)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.user.Devices>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.RegistrationClient.prototype.listDevices =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.Registration/ListDevices',
      request,
      metadata || {},
      methodDescriptor_Registration_ListDevices,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.user.Devices>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.RegistrationPromiseClient.prototype.listDevices =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.Registration/ListDevices',
      request,
      metadata || {},
      methodDescriptor_Registration_ListDevices);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Registration_LogoutFromAllDevices = new grpc.web.MethodDescriptor(
  '/kazatel.user.Registration/LogoutFromAllDevices',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.RegistrationClient.prototype.logoutFromAllDevices =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.Registration/LogoutFromAllDevices',
      request,
      metadata || {},
      methodDescriptor_Registration_LogoutFromAllDevices,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.RegistrationPromiseClient.prototype.logoutFromAllDevices =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.Registration/LogoutFromAllDevices',
      request,
      metadata || {},
      methodDescriptor_Registration_LogoutFromAllDevices);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.user.LiteRegistrationInfo,
 *   !proto.kazatel.user.LiteRegistrationInfoResponse>}
 */
const methodDescriptor_Registration_RestoreAccess = new grpc.web.MethodDescriptor(
  '/kazatel.user.Registration/RestoreAccess',
  grpc.web.MethodType.UNARY,
  proto.kazatel.user.LiteRegistrationInfo,
  proto.kazatel.user.LiteRegistrationInfoResponse,
  /**
   * @param {!proto.kazatel.user.LiteRegistrationInfo} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.user.LiteRegistrationInfoResponse.deserializeBinary
);


/**
 * @param {!proto.kazatel.user.LiteRegistrationInfo} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.user.LiteRegistrationInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.user.LiteRegistrationInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.RegistrationClient.prototype.restoreAccess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.Registration/RestoreAccess',
      request,
      metadata || {},
      methodDescriptor_Registration_RestoreAccess,
      callback);
};


/**
 * @param {!proto.kazatel.user.LiteRegistrationInfo} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.user.LiteRegistrationInfoResponse>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.RegistrationPromiseClient.prototype.restoreAccess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.Registration/RestoreAccess',
      request,
      metadata || {},
      methodDescriptor_Registration_RestoreAccess);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.user.VerificationCode,
 *   !proto.kazatel.user.UserTokens>}
 */
const methodDescriptor_Registration_VerifyRestoreAccess = new grpc.web.MethodDescriptor(
  '/kazatel.user.Registration/VerifyRestoreAccess',
  grpc.web.MethodType.UNARY,
  proto.kazatel.user.VerificationCode,
  user_user_pb.UserTokens,
  /**
   * @param {!proto.kazatel.user.VerificationCode} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  user_user_pb.UserTokens.deserializeBinary
);


/**
 * @param {!proto.kazatel.user.VerificationCode} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.user.UserTokens)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.user.UserTokens>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.RegistrationClient.prototype.verifyRestoreAccess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.Registration/VerifyRestoreAccess',
      request,
      metadata || {},
      methodDescriptor_Registration_VerifyRestoreAccess,
      callback);
};


/**
 * @param {!proto.kazatel.user.VerificationCode} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.user.UserTokens>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.RegistrationPromiseClient.prototype.verifyRestoreAccess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.Registration/VerifyRestoreAccess',
      request,
      metadata || {},
      methodDescriptor_Registration_VerifyRestoreAccess);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.StringValue>}
 */
const methodDescriptor_Registration_CreateGuest = new grpc.web.MethodDescriptor(
  '/kazatel.user.Registration/CreateGuest',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_wrappers_pb.StringValue,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_wrappers_pb.StringValue.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.StringValue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.StringValue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.RegistrationClient.prototype.createGuest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.Registration/CreateGuest',
      request,
      metadata || {},
      methodDescriptor_Registration_CreateGuest,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.StringValue>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.RegistrationPromiseClient.prototype.createGuest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.Registration/CreateGuest',
      request,
      metadata || {},
      methodDescriptor_Registration_CreateGuest);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.kazatel.user.UserTokens>}
 */
const methodDescriptor_Registration_LoginGuest = new grpc.web.MethodDescriptor(
  '/kazatel.user.Registration/LoginGuest',
  grpc.web.MethodType.UNARY,
  google_protobuf_wrappers_pb.StringValue,
  user_user_pb.UserTokens,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  user_user_pb.UserTokens.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.user.UserTokens)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.user.UserTokens>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.user.RegistrationClient.prototype.loginGuest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.user.Registration/LoginGuest',
      request,
      metadata || {},
      methodDescriptor_Registration_LoginGuest,
      callback);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.user.UserTokens>}
 *     Promise that resolves to the response
 */
proto.kazatel.user.RegistrationPromiseClient.prototype.loginGuest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.user.Registration/LoginGuest',
      request,
      metadata || {},
      methodDescriptor_Registration_LoginGuest);
};


module.exports = proto.kazatel.user;

