export const monetizationReports = {
  namespaced: true,

  state: () => ({
    selectedReports: [],
    selectedCard: null,
    chooseReport: null,
  }),

  mutations: {
    addSelectedReport(state, report) {
      state.selectedReports.push(report);
    },
    removeSelectedReport(state, reportId) {
      state.selectedReports = state.selectedReports.filter(report => report.reportId !== reportId);
    },
    clearSelectedReports(state) {
      state.selectedReports = [];
    },
    setReportSelected(state, { reportId, isSelected }) {
      const report = state.selectedReports.find(report => report.id === reportId);
      if (report) {
        report.isReportSelected = isSelected;
      }
    },
    setSelectedCard(state, card) {
      state.selectedCard = card;
    },
    clearSelectedCard(state) {
      state.selectedCard = null;
    },
    setChooseReport(state, report) {
      state.chooseReport = report;
    }
  },

  actions: {
    addReport({ commit }, report) {
      commit('addSelectedReport', report);
    },
    removeReport({ commit }, reportId) {
      commit('removeSelectedReport', reportId);
    },
    clearReports({ commit }) {
      commit('clearSelectedReports');
    },
    selectCard({ commit }, card) {
      commit('setSelectedCard', card);
    },
    clearCardSelection({ commit }) {
      commit('clearSelectedCard');
    },
    chooseReport({ commit }, report) {
      commit('setChooseReport', report);
    }
  },

  getters: {
    getSelectedReportsCount: state => state.selectedReports.length,
    getSelectedReportsSum: state => state.selectedReports.reduce((totalSum, report) => totalSum + report.reportSum, 0),
    getSelectedCard: state => state.selectedCard,
    getSelectedReports: state => state.selectedReports,
    getChooseReport: state => state.chooseReport,
  },
}
