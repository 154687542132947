<template>
  <card-background class="header">
    <router-link class="link header__heading-wrapper" :to="{name: 'tasks'}">
      <img src="@/assets/images/kazatel-logo.svg" class="logo" alt="Казатель Лого"/>
      <heading-size-h2>Казатель</heading-size-h2>
    </router-link>
    <button
      v-if="windowWidth < 992" 
      class="navbar-toggler"
      aria-label="кнопка шапки сайта" 
      @click="toggleNavbar"
    >
      <svg v-if="isNavbarActive" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.3125 10.6875L10.6875 25.3125M10.6875 10.6875L25.3125 25.3125" stroke="#D8473A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <svg v-else width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 8H28M4 16H28M4 24H28" stroke="#D8473A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>

    <navbar-profile v-if="windowWidth <= 992 && isNavbarActive"/>
    <navbar-profile v-if="windowWidth > 992"/>
  </card-background>
</template>

<script>
import HeadingSizeH2 from '@/components/UI/texts/headings/HeadingSizeH2.vue'
import CardBackground from '@/components/UI/card/CardBackground.vue';
import NavbarLinks from './components/NavbarLinks.vue';
import NavbarProfile from './components/NavbarProfile.vue';


export default {
  name: 'header-component',

  components: {
    HeadingSizeH2,
    CardBackground,
    NavbarLinks,
    NavbarProfile
  },

  data() {
    return {
      isTasksActive: false,
      windowWidth: window.innerWidth,
      showPopupRegistration: false,
      isNavbarActive: false,
    };
  },

  methods: {
    toggleNavbar() {
      this.isNavbarActive = !this.isNavbarActive;
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    }
  },

  mounted() {
    window.addEventListener('resize', this.updateWindowWidth);
  }
}
</script>

<style scoped>
.header {
  padding: 8px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.navbar-toggler{
  display: none;
  position: absolute;
  top: 20px;
  right: 32px;
}

.header__heading-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
}
.logo {
  width: 40px;
  height: 40px;
}

@media screen and (max-width: 992px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    padding: 16px;
  }
  .navbar-toggler {
    display: block;
  }

}
</style>
