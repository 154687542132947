<!-- <template>
  <card-background class="favorites" v-if="userHaveBooks">
    <div class="heading__wrapper">
      <img class="heading__image" src="@/assets/images/headings/backpack.png" />
      <div class="heading__counter-wrapper">
        <heading-size-h2>Мой рюкзак</heading-size-h2>
        <paragraph-size-medium :textColor="'var(--text-secondary)'" :textWeight="'600'">{{ books.length }}</paragraph-size-medium>
      </div>
    </div>
    <div class="books-slider" v-if="windowSize.width >= 1080 && !noBooksInFavs">
      <div class="books-scroll-container">
        <transition-group name="book-card" tag="div" class="books-wrapper">
          <fav-book-card
            v-for="(book, index) in books"
            :key="book.id"
            :id="book.id"
            :bookTitle="book.bookTitle"
            :bookImg="book.bookImg"
            :bookDescription="book.bookDescription"
            :authors="book.authors"
          />
        </transition-group>
      </div>
      <button class="button__slider" @click="scrollBooks('left')" v-show="isLeftButtonVisible">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M15 19L9 12L15 5" stroke="#9C9CC9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
      <button class="button__slider" @click="scrollBooks('right')" v-show="isRightButtonVisible">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M9 5L15 12L9 19" stroke="#9C9CC9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>

    <div class="books-slider" v-if="windowSize.width < 1080 && windowSize.width > 569 && !noBooksInFavs">
      <div class="books-wrapper" v-if="books.length > 0">
        <fav-book-card
          v-for="(book, index) in books.slice(currentIndex, currentIndex + 3)"
          :key="index"
          :id="book.id"
          :bookTitle="book.bookTitle"
          :bookImg="book.bookImg"
          :bookDescription="book.bookDescription"
          :authors="book.authors"
        />
      </div>
    </div>
    <div class="books-slider" v-if="windowSize.width < 568 && !noBooksInFavs">
      <div class="books-wrapper" v-if="books.length > 0">
        <fav-book-card
          v-for="(book, index) in books.slice(currentIndex, currentIndex + 4)"
          :key="index"
          :id="book.id"
          :bookTitle="book.bookTitle"
          :bookImg="book.bookImg"
          :bookDescription="book.bookDescription"
          :authors="book.authors"
        />
      </div>
    </div>
    <button-text @click="pushToFavorites" v-if="!noBooksInFavs">Все учебники в рюкзаке</button-text>

    <div class="empty-wrapper" v-if="noBooksInFavs">
      <div class="empty__text-wrapper">
        <heading-size-h3>
          Пока что тут ничего нет
        </heading-size-h3>
        <paragraph-size-medium :textColor="'var(--text-black)'">
          Нажимай <span class="red">&#10084;</span> на учебнике и добавляй сюда те книги, которые чаще всего нужны.
        </paragraph-size-medium>
      </div>
    </div>
  </card-background>
</template> -->


<template>
  <card-background class="favs">
    <div class="favs__heading">
      <h2 class="heading heading_size_h2">
        Книги в твоём рюкзаке
      </h2>
      <div class="heading__scroll" v-if="!noBooksInFavs">
        <kaz-button
          :size="'S'"
          :leftIcon="true"
          :leftIconName="'kuiIconChevronLeft'"
          :appearance="'flat'"
          :mode="'normal'"
          @click="scrollBooks('left')"
        />
        <kaz-button
          :size="'S'"
          :leftIcon="true"
          :leftIconName="'kuiIconChevronRight'"
          :appearance="'flat'"
          :mode="'normal'"
          @click="scrollBooks('right')"
        />
      </div>
    </div>

    <ul class="favs__cards books-scroll-container" v-if="!noBooksInFavs">
      <li v-for="(book, index) in books.slice(currentIndex, currentIndex + 4)">
        <fav-book-card
          :key="index"
          :id="book.id"
          :bookTitle="book.bookTitle"
          :bookImg="book.bookImg"
          :bookDescription="book.bookDescription"
          :authors="book.authors"
        />
      </li>
    </ul>


    <div class="empty-wrapper" v-if="noBooksInFavs">
      <div class="empty__text-wrapper">
        <h3 class="heading heading_size_h3">
          Пока что тут ничего нет
        </h3>
        <p class="label label_size_M">
          Нажимай
          <span>
            <svg-icon class="empty__icon" :iconName="'kuiIconHeart'" :size="'16px'"/>
          </span>
          на пособии и добавляй в рюкзак те книги, которые чаще всего нужны.
        </p>
      </div>
    </div>

  </card-background>
</template>

<script>
import CardBackground from '@/components/UI/card/CardBackground.vue';
import KazButton from '@/components/KazUI/atoms/button'


import FavBookCard from '@/components/cards/FavBookCard.vue'

import { FavoritesClient } from '@/generated/favorites/favorites_grpc_web_pb.js';
import { TaskReaderClient } from '@/generated/taskreader/taskreader_grpc_web_pb.js';

import { fetchStreamData } from '@/utils/loaders';


export default {
  name: 'favorites-component',

  components: {
    CardBackground,
    KazButton,

    FavBookCard,

  },

  props: {
    userHaveBooks: Boolean,
    default: false
  },

  data() {
    return {
      isFavoritesOpen: true,
      books:[],
      serviceURL: process.env.VUE_APP_REGISTRATION_SERVICE_URL,
      favoritesService: null,
      favoritesBooks: [],
      currentIndex: 0,
      noBooksInFavs: false,
      windowSize: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      isLeftButtonVisible: true,
      isRightButtonVisible: true,
    }
  },

  computed: {
    updateWindowSize() {
      return () => {
        this.windowSize = {
          width: window.innerWidth,
          height: window.innerHeight,
        };
      }
    },
    showLeftButton() {
      const container = this.$el.querySelector('.books-wrapper');
      return container.scrollLeft > 0;
    },
    showRightButton() {
      const container = this.$el.querySelector('.books-wrapper');
      return container.scrollLeft < container.scrollWidth - container.clientWidth - 1;
    },
  },
  mounted() {
    this.checkButtonsVisibility();
  },

  methods: {
    checkButtonsVisibility() {
      const container = this.$el.querySelector('.books-wrapper');
      if (container) {
        this.isLeftButtonVisible = true;
        this.isRightButtonVisible = true;
      }
    },
    scrollBooks(direction) {
      const container = this.$el.querySelector('.books-scroll-container');
      // const scrollDistance = this.$el.querySelector('.book-card').offsetWidth + 16;
      const scrollDistance = 320;
      if (direction === 'left') {
        container.scrollBy({
          left: -scrollDistance,
          behavior: 'smooth',
        });
      } else if (direction === 'right') {
        container.scrollBy({
          left: scrollDistance,
          behavior: 'smooth',
        });
      }
    },
    toggleFavorites() {
      this.isFavoritesOpen = !this.isFavoritesOpen;
    },
    pushToFavorites(){
      this.$router.push('/favorites')
    },
    initContent() {
      this.favoritesService = new FavoritesClient(this.serviceURL, null, null);
      try {
        const requestFavorites = new proto.kazatel.favorites.ResourceData();
        const tokens = this.$getTokenInfo();
        const metadata = { 'token': tokens.accessToken.token };
        const stream = this.favoritesService.get(requestFavorites, metadata);
        stream.on('data', this.fetchStreamData);
        stream.on('end', this.fetchStreamEnd);
        stream.on('status', (status) => {
          // if (status.code) {
          //   reject(status);
          // } else {
          // console.log('fetchStreamData.code:', status.code);
          // console.log('fetchStreamData.details:', status.details);
          // console.log('fetchStreamData.metadata:', status.metadata);
          // }
        })
      } catch (error) {
        console.error('fetchFavorites:', e)
        alert('Ошибка запроса информации', e)
      }
    },
    fetchStreamData(response) {
      // console.log(response.toObject());
      this.favoritesBooks.push(response.getId());
    },
    fetchStreamEnd() {
      // alert('sdghsdfbdgfb');
      this.loadBooks();
    },
    async loadBooks() {
      this.books = [];
      if(!this.favoritesBooks.length){
        this.noBooksInFavs = true
        return
      }
      try {
        const taskreaderService = new TaskReaderClient(this.serviceURL, null, null);
        const request = new proto.kazatel.books.BooksRequest();
        request.setBooksList(this.favoritesBooks);
        request.setStatusList([7]);
        const tokens = this.$getTokenInfo();
        const metadata = { 'token': tokens.accessToken.token };
        const stream = taskreaderService.books(request, metadata);
        const streamData = await fetchStreamData(stream);
        streamData.forEach(response => {
          // console.error('loadBooks:', response.toObject());
          this.books.push({
            id: response.getId(),
            bookTitle: response.getName(),
            bookImg: response.getViewUrl(),
            authors: response.getAuthors().split(',').map(
              (a, i) => ({
                id: 1 + i,
                name: a.trim(),
              })
            ),
            bookDescription: {
              isPremium: response.getAttrPremium(),
              year: response.getPublishYear(),
              type: response.getType(),
              part: response.getPart(),
            }
          });
        })
        this.isLoading = false
      } catch (err) {
        this.isLoading = false
        // console.error('loadBooks:', err);
      }
    },
  },

  created() {
    this.initContent();
    window.addEventListener('resize', this.updateWindowSize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowSize);
  },
};
</script>
<style scoped>
.favs{
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.favs__heading{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.heading__scroll{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.favs__cards{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  overflow-x: auto;

  scroll-behavior: smooth;
  background-color: transparent;
  /* padding: 12px; */
}

.favs__cards::-webkit-scrollbar{
  display: none;
}

.empty__text-wrapper{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media screen and (max-width: 568px) {
  .heading__scroll{
    display: none;
  }
}
/* .books-scroll-container {
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
  position: relative;
}

.books-wrapper {
  padding: 8px;
  display: flex;
  gap: 16px;
}
.book-card-enter-active{
  transition: opacity 0.1s ease-in-out;
}
.book-card-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.book-card-enter{
  opacity: 0;
}
.book-card-leave-to{
  opacity: 0;
}
.books-slider{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  position: relative;
}
.favorites {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.heading__wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.heading__image {
  width: 24px;
  aspect-ratio: 1;
}
.heading__counter-wrapper{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
}
.button__close{
  background-color: transparent;
  outline: none;
  border: none;
}
.rotated {
  transform: rotate(180deg);
}
.books-wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: nowrap;
  width: fit-content;
}


.button__slider{
  border: none;
  outline: none;
  background-color: transparent;
  width: fit-content;
  padding: 0;
  background-color: var(--white);
  padding: 8px;
  border-radius: 8px;
  box-shadow: 4px 0px 16px 0px rgba(74, 74, 74, 0.15);
}
.button__slider:first-of-type{
  position: absolute;
  left: 16px;
}
.button__slider:last-of-type{
  position: absolute;
  right: 16px;
}

.button__slider:hover svg path{
  stroke: var(--background-red);
}

.empty-wrapper{
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  margin-top: 32px;
  text-align: center;
}
.empty__text-wrapper{
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}
.red{
  color: var(--background-red);
}

@media screen and (max-width: 768px) {
  .books-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-template-rows: auto;
    row-gap: 16px;
    width: 100%;
  }
}
@media screen and (max-width: 568px) {
  .button__close svg{
    width: 36px;
    height: 36px;
  }
  .books-wrapper {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 8px;
  }
} */
</style>
