<template>
  <div class="wrapper">
    <div class="badges__wrapper" v-if="badges.length > 0">

    </div>

    <div class="book-info__wrapper">
      <img class="book-info__cover" :src="bookInfo.cover" @error="handleImageError" />
      <div class="book-info__text-wrapper">
        <div class="text-wrapper__headings">
          <h3 class="heading heading_size_h3" :title="bookInfo.titleSeo">
            {{ bookInfo.titleSeo }}
          </h3>
          <h4 class="heading heading_size_h4" :title="bookInfo.titleSystem">
            {{ bookInfo.titleSystem }}
          </h4>
        </div>
        <div class="text-wrapper__description">
          <div class="description__column">
            <div class="description__column_item">
              <p class="label label_size_M">класс:</p>
              <p class="label label_size_M">{{ bookInfo.level }} класс</p>
            </div>
            <div class="description__column_item">
              <p class="label label_size_M">предмет:</p>
              <p class="label label_size_M">{{ bookInfo.subject }}</p>
            </div>
            <div class="description__column_item">
              <p class="label label_size_M">тип:</p>
              <p class="label label_size_M">{{ bookInfo.type }}</p>
            </div>
            <div class="description__column_item">
              <p class="label label_size_M">уровень:</p>
              <p class="label label_size_M">{{ bookInfo.studyDepth }}</p>
            </div>
          </div>
          <div class="description__column">
            <div class="description__column_item">
              <p class="label label_size_M">автор:</p>
              <p class="label label_size_M">{{ bookInfo.authors[0] }}</p>
            </div>
            <div class="description__column_item">
              <p class="label label_size_M">год издания:</p>
              <p class="label label_size_M">{{ bookInfo.year }}</p>
            </div>
            <div class="description__column_item">
              <p class="label label_size_M">издатель:</p>
              <p class="label label_size_M">{{ bookInfo.publisher }}</p>
            </div>
            <div class="description__column_item">
              <p class="label label_size_M">часть:</p>
              <p class="label label_size_M" v-for="part in bookInfo.parts" >{{ part }} часть</p>
            </div>
          </div>
          <div class="description__column">


            <div class="description__column_item">
              <p class="label label_size_M">ФГОС:</p>
              <p class="label label_size_M">{{ bookInfo.FGOS? 'Да' : 'Нет' }}</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default{
  name: 'row-book-card',
  components:{

  },
  props:{
    badges:{
      type: Array,
      default: [],
      requred: false,
    },
    creationDate:{
      type: String,
      default: '01/01/2024',
      requred: false,
    },
    bookInfo:{
      type: Object,
      default: {},
      requred: false,
    },
    // mainActionName:{
    //   type: String,
    //   default: 'Опубликовать учебник',
    //   requred: false,
    // },
    // secondaryActionName:{
    //   type: String,
    //   default: 'Редактировать',
    //   requred: false,
    // },
  },
  methods:{
    handleImageError() {
      this.bookInfo.cover = require('@/assets/images/books/not-loaded-book2.png');
    },
  }
}
</script>

<style scoped>
.wrapper {
  width: 100%;
}
.book-info__wrapper{
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.book-info__cover{
  width: 130px;
  height: 180px;
  object-fit: cover;
  border-radius: 4px;
}
.book-info__text-wrapper{
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  overflow-x: auto;
}
.text-wrapper__headings{
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-wrapper__headings h3,h4{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 32px)
}
.text-wrapper__headings h4{
  color: var(--kaz-textIcons-text-02);
}
.heading{
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-wrapper__description{
  display: flex;
  flex-direction: row;
  gap: 24px;
}
.description__column{
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.description__column_item{
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.description__column_item p:first-of-type{
  color: var(--kaz-textIcons-text-02);
}
.description__column_item p{
  white-space: nowrap;
}


@media screen and (max-width: 992px) {
  .book-info__wrapper{
    flex-direction: column;
  }
  .book-info__cover{
    width: 110px;
    height: 152px;
    object-fit: cover;
    border-radius: 4px;
  }
  .book-info__text-wrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .text-wrapper__headings{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .text-wrapper__headings h3{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: calc(100% - 32px)
  }
  .text-wrapper__headings h4{
    color: var(--kaz-textIcons-text-02);
  }
  .heading{
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .text-wrapper__description{
    display: flex;
    flex-direction: row;
    gap: 24px;
  }
  .description__column{
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .description__column_item{
    display: flex;
    flex-direction: row;
    gap: 4px;
  }
  .description__column_item p:first-of-type{
    color: var(--kaz-textIcons-text-02);
  }
  .description__column_item p{
    white-space: nowrap;
  }
}
</style>