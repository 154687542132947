<template>
  <div 
    v-if="isVisible"
    class="popup" 
    :class="{ 'popup_opened': isVisible }"
    @click.stop="closePopup"
  >
    <div 
      class="popup_type_base"
      :class="[ title ? 'popup_type_solutions' : 'popup_type_headless' ]"
      @click.stop
    >
      <div class="popup__heading-wrapper">
        <heading-size-h2 :textColor="headingColor" :textWeight="headingWeight">
          {{ title }}
        </heading-size-h2>
        <button-close @click.stop="closePopup"/>
      </div>

      <div class="popup__text-content">
        <div class="text-content__section">
          <slot name="text"></slot>
        </div>
      </div>

      <div v-if="!!$slots.button" class="popup__button-content">
        <slot name="button"></slot>
      </div>
      
      <div v-if="!!$slots.footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import ParagraphSizeLarge from '@/components/UI/texts/paragraphs/ParagraphSizeLarge.vue'
import HeadingSizeH4 from '@/components/UI/texts/headings/HeadingSizeH4.vue';
import HeadingSizeH2 from '@/components/UI/texts/headings/HeadingSizeH2.vue';
import ButtonClose from '@/components/UI/buttons/ButtonClose.vue';

export default {
  name: 'popup-window',

  components: {
    ParagraphSizeLarge,
    HeadingSizeH4,
    HeadingSizeH2,
    ButtonClose,
  },

  props: {
    title: {
      type: String,
      default: 'Оформление тарифа'
    },
    isVisible: {
      type: Boolean,
      default: false,
      required: true,
    },
    headingColor: {
      type: String,
      default: 'var(--text-black)',
    },
    headingWeight: {
      type: Number,
      default: 600,
    }
  },

  emits: ['close'],

  methods: {
    closePopup() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease, visibility 0s linear .5s;
  -webkit-transition: opacity .5s ease, visibility 0s linear .5s;
  -moz-transition: opacity .5s ease, visibility 0s linear .5s;
  -ms-transition: opacity .5s ease, visibility 0s linear .5s;
  -o-transition: opacity .5s ease, visibility 0s linear .5s;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
}

.popup_opened {
  opacity: 1;
  visibility: visible;
  z-index: 100;
  transition: opacity .5s ease, visibility 0s linear 0s;
  -webkit-transition: opacity .5s ease, visibility 0s linear 0s;
  -moz-transition: opacity .5s ease, visibility 0s linear 0s;
  -ms-transition: opacity .5s ease, visibility 0s linear 0s;
  -o-transition: opacity .5s ease, visibility 0s linear 0s;
}

 .popup__heading-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
 }

 .popup__text-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
 }

 .text-content__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
 }

.popup_type_base {
  border-radius: 12px;
  background-color: var(--white);
  padding: 24px 32px 28px 32px;
  display: flex;
  flex-direction: column;
  max-width: 576px;
}

.popup_type_solutions {
  gap: 24px;
}

.popup_type_headless {
  gap: 8px;
}

:slotted(.popup__button-content > *:not(:first-child)) {
  margin-top: 8px;
}

@media screen and (min-width: 768px) {
  .popup_type_base {
    min-width: 516px;
  }
}
</style>