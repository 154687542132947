const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {layout: 'auth'},
    component: () => import('@/views/auth/Login')
  },
  {
    path: '/register',
    name: 'register',
    meta: {layout: 'auth'},
    component: () => import('@/views/auth/Register')
  },
  {
    path: '/email-confirm',
    name: 'email-confirm',
    meta: {layout: 'auth'},
    component: () => import('@/views/auth/EmailConfirm')
  },
  {
    path: '/tel-login',
    name: 'tel-login',
    meta: {layout: 'auth'},
    component: () => import('@/views/auth/TelLogin')
  },
  {
    path: '/tel-confirm',
    name: 'tel-confirm',
    meta: {layout: 'auth'},
    component: () => import('@/views/auth/TelConfirm')
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    meta: {layout: 'auth'},
    component: () => import('@/views/auth/PasswordReset')
  },
  {
    path: '/password-reset-success',
    name: 'password-reset-success',
    meta: {layout: 'auth'},
    component: () => import('@/views/auth/PasswordResetSuccess')
  },
  {
    path: '/password-confirm',
    name: 'password-confirm',
    meta: {layout: 'auth'},
    component: () => import('@/views/auth/PasswordConfirm')
  }
]

export default routes
