<template>
  <div
    v-if="isBookPremium"
    class="premium-tag"
    :class="{ 'premium-tag-hover': isHovered }"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <g clip-path="url(#clip0_4625_48554)">
          <path d="M14.4085 9.04394L14.5618 7.41994C14.6818 6.14527 14.7418 5.50727 14.5232 5.24394C14.4682 5.17528 14.4001 5.11828 14.3228 5.07627C14.2455 5.03426 14.1607 5.00808 14.0732 4.99927C13.7558 4.97127 13.3578 5.4246 12.5612 6.33127C12.1492 6.8006 11.9432 7.0346 11.7138 7.07127C11.5857 7.09124 11.4546 7.07024 11.3392 7.01127C11.1265 6.9046 10.9858 6.6146 10.7025 6.03527L9.21115 2.97927C8.67649 1.88394 8.40915 1.33594 8.00249 1.33594C7.59582 1.33594 7.32849 1.88394 6.79382 2.97927L5.30249 6.03527C5.01982 6.61527 4.87849 6.9046 4.66582 7.01127C4.55034 7.07015 4.41925 7.09114 4.29115 7.07127C4.06182 7.0346 3.85582 6.8006 3.44382 6.33127C2.64715 5.4246 2.24915 4.97127 1.93182 4.99927C1.84431 5.00808 1.75944 5.03426 1.68217 5.07627C1.6049 5.11828 1.53679 5.17528 1.48182 5.24394C1.26382 5.50727 1.32382 6.14527 1.44382 7.41994L1.59649 9.04394C1.84849 11.7186 1.97382 13.0566 2.76315 13.8626C3.55049 14.6693 4.73182 14.6693 7.09582 14.6693H8.90849C11.2718 14.6693 12.4532 14.6693 13.2418 13.8626C14.0305 13.0559 14.1565 11.7186 14.4085 9.04394Z" stroke="white" stroke-width="1.2"/>
          <path d="M6 12H10" stroke="white" stroke-width="1.2" stroke-linecap="round"/>
        </g>
        <defs>
          <clipPath id="clip0_4625_48554">
            <rect width="16" height="16" fill="white"/>
          </clipPath>
        </defs>
    </svg>
    <paragraph-size-extra-small
      :textColor="'var(--white)'"
      textWeight="500"
      v-show="isHovered"
      class="premium-text"
    >
      премиум
    </paragraph-size-extra-small>
  </div>
</template>

<script>
import ParagraphSizeExtraSmall from '@/components/UI/texts/paragraphs/ParagraphSizeExtraSmall.vue'

export default {
  name: 'premium-tag',

  components: {
    ParagraphSizeExtraSmall
  },

  props: {
    isBookPremium: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isHovered: false
    }
  }
}
</script>

<style scoped>
.premium-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 4px;
  background-color: #E9B910;
  padding: 4px;
  max-width: fit-content;
  transition: width 0.3s ease-in-out; /* Adding transition for width change */
}

.premium-tag:hover {
  background-color: #E9B910;
  width: 82px; /* Adjust the width for hover state */
  transition: width 0.3s ease-in-out;
}

.premium-text {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.premium-tag:hover .premium-text {
  opacity: 1;
}
</style>
