function formatBytes(bytes, args = {decimals: 2, binary: false}) {
  if (!+bytes) {
    return '0 bytes'
  }

  const sign = bytes < 0 ? '-' : '';
  bytes = Math.abs(bytes);

  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'].map(
    (u, i) => {
      if (i > 0) {
        return args.binary ? u[0] + 'i' + u[1] : u
      } else {
        return u
      }
    }
  );
  const multiplier = args.binary ? 1024 : 1000;
  const position = Math.floor(Math.log(bytes) / Math.log(multiplier));
  const quotient = parseFloat(bytes / Math.pow(multiplier, position)).toFixed(args.decimals);
  return `${sign}${quotient} ${units[position]}`
}

class DateFormatter {
  constructor(locale, options) {
    try {
      this.locale = locale;
      this.options = options;
      this._initFormatter();
    } catch (error) {
      throw error;
    }
  }
  _initFormatter() {
    try {
      this.dateFormatter = new Intl.DateTimeFormat(this.locale, this.options);
    } catch (error) {
      throw error;
    }
  }
  format(date) {
    try {
      return this.dateFormatter.format(date); 
    } catch (error) {
      throw error;
    }
  }
  setOption(key, value) {
    try {
      this.options[key] = value;
      this._initFormatter();
    } catch (error) {
      throw error;
    }
  }
  deleteOption(key) {
    try {
      if (this.options.hasOwnProperty(key)) {
        delete this.options.key;
        this._initFormatter();
      }
    } catch (error) {
      throw error;
    }
  }
}


export { formatBytes, DateFormatter }
