<template>
  <card-background class="related-lessons-wrapper" v-if="relatedLessons.length">
    <h2 class="heading heading_size_h2">
      Уроки, где объясняются решения из этого пособия
    </h2>
    <ul class="related-lessons">
      <li
        v-for="lesson in relatedLessons"
        :key="lesson.id"
        class="related-lesson__item"
      >
        <router-link
          class="lesson-card"
          :to="`/lesson/${lesson.id}`"
          :title="lesson.title"
        >
          <span class="lesson-card__title heading heading_size_h4">{{ lesson.title }}</span>
          <!-- <div class="lesson-card__tags">
            <kaz-tag
              v-for="tag in lesson.tags"
              :label="tag"
              size="S"
            />
          </div> -->
        </router-link>
      </li>
    </ul>
    <!-- <kaz-button
      :label="`Перейти ко всем урокам для ${subjectName}, ${levelName}`"
      size="L"
      appearance="secondary"
      style="margin-left: auto;"
      @click="console.log('go to na...')"
    /> -->
  </card-background>
</template>

<script>
import CardBackground from '@/components/UI/card/CardBackground.vue';
import KazTag from '@/components/KazUI/atoms/tag';
import KazButton from '@/components/KazUI/atoms/button';
import { TaskReaderClient } from '@/generated/taskreader/taskreader_grpc_web_pb';
import { fetchStreamData } from '@/utils/loaders';
import { createMetadataWithToken } from '@/services/utils';


const SERVICE_URL = process.env.VUE_APP_REGISTRATION_SERVICE_URL;
const taskReaderService = new TaskReaderClient(SERVICE_URL, null, null);

export default {
  name: 'related-lessons-for-task',

  components: {
    CardBackground,
    KazTag,
    KazButton,
  },

  props: {
    bookId: {
      type: String,
      default: null,
    },
    nodeId: {
      type: String,
      required: true,
    },
    srcLessons: {
      type: Array,
      required: true
    },
  },

  data() {
    return {
      levelId: 7,
      levelName: '8 класс',
      subjectId: 13,
      subjectName: 'Математика',
      relatedLessons: [
        // {
        //   id: 1,
        //   title: 'Урок 1. Многоугольники. Четырёхугольник',
        //   tags: ['Конспект', 'Тест']
        // },
        // {
        //   id: 2,
        //   title: 'Урок 2. Параллелограмм. Свойства элиптического  параллелограмма',
        //   tags: ['Конспект', 'Тест']
        // },
        // {
        //   id: 3,
        //   title: 'Урок 3. Признаки параллелограмма',
        //   tags: ['Конспект', 'Тест']
        // },
        // {
        //   id: 4,
        //   title: 'Урок 4. Трапеция',
        //   tags: ['Конспект', 'Тест']
        // },
        // {
        //   id: 5,
        //   title: 'Урок 5. Теорема Фалеса',
        //   tags: ['Конспект', 'Тест']
        // },
      ],
      linkedLessons: [],
    }
  },

  watch: {
    srcLessons: {
      async handler(newValue) {
        this.relatedLessons = [];
        this.linkedLessons = await this.loadLinkedLessons();
        for(const k in newValue){
          if(this.isLinked(newValue[k].id)) {
            this.relatedLessons.push({
              id: newValue[k].id,
              title: newValue[k].title,
              tags: ['Конспект', 'Тест']
            });
          }
        }
      },
      immediate: true
    }
  },

  methods: {
    async loadLinkedLessons() {
      try {
        const metadata = createMetadataWithToken();
        const request = new proto.google.protobuf.StringValue();

        console.log('nodeId', this.nodeId);
        let stream;
        if(this.nodeId) {
          request.setValue(this.nodeId);
          stream = taskReaderService.nodeLinks(request, metadata);
        } else {
          request.setValue(this.bookId);
          stream = taskReaderService.bookLinks(request, metadata);
        }
        return await fetchStreamData(stream);
      } catch (error) {
        throw error;
      }
    },
    isLinked(id) {
      let bLinked = false;
      for (const k in this.linkedLessons) {
        if (this.linkedLessons[k].getId() == id ) {
          bLinked = true;
          break;
        }
      }
      return bLinked;
    }
  },

  mounted(){
    console.log(this.relatedLessons)
  }
}
</script>

<style scoped>
.related-lessons-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.related-lessons {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex-wrap: wrap;
}

.related-lesson__item {
  flex: 1 0 32%;
  min-width: 392px;
  height: 94px;
}

.lesson-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 16px;
  text-decoration: none;
  box-shadow: var(--kaz-shadow-default);
  transition: transform var(--kaz-transition-molecules);
}

.lesson-card:hover {
  transform: translateY(-4px);
}

.lesson-card__title {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.lesson-card__tags {
  display: flex;
  gap: 8px;
  margin-top: auto;
}
</style>