/**
 * @fileoverview gRPC-Web generated client stub for kazatel.accessmanager
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.23.1
// source: accessmanager/accessmanager.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')
const proto = {};
proto.kazatel = {};
proto.kazatel.accessmanager = require('./accessmanager_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.kazatel.accessmanager.AccessManagerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.kazatel.accessmanager.AccessManagerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.accessmanager.PermissionByLogin,
 *   !proto.kazatel.accessmanager.PermissionStatus>}
 */
const methodDescriptor_AccessManager_CheckPermission = new grpc.web.MethodDescriptor(
  '/kazatel.accessmanager.AccessManager/CheckPermission',
  grpc.web.MethodType.UNARY,
  proto.kazatel.accessmanager.PermissionByLogin,
  proto.kazatel.accessmanager.PermissionStatus,
  /**
   * @param {!proto.kazatel.accessmanager.PermissionByLogin} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.accessmanager.PermissionStatus.deserializeBinary
);


/**
 * @param {!proto.kazatel.accessmanager.PermissionByLogin} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.kazatel.accessmanager.PermissionStatus)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.accessmanager.PermissionStatus>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.accessmanager.AccessManagerClient.prototype.checkPermission =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.accessmanager.AccessManager/CheckPermission',
      request,
      metadata || {},
      methodDescriptor_AccessManager_CheckPermission,
      callback);
};


/**
 * @param {!proto.kazatel.accessmanager.PermissionByLogin} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kazatel.accessmanager.PermissionStatus>}
 *     Promise that resolves to the response
 */
proto.kazatel.accessmanager.AccessManagerPromiseClient.prototype.checkPermission =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.accessmanager.AccessManager/CheckPermission',
      request,
      metadata || {},
      methodDescriptor_AccessManager_CheckPermission);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.kazatel.accessmanager.Permission>}
 */
const methodDescriptor_AccessManager_GetAllPermissions = new grpc.web.MethodDescriptor(
  '/kazatel.accessmanager.AccessManager/GetAllPermissions',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.kazatel.accessmanager.Permission,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.accessmanager.Permission.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.accessmanager.Permission>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.accessmanager.AccessManagerClient.prototype.getAllPermissions =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.accessmanager.AccessManager/GetAllPermissions',
      request,
      metadata || {},
      methodDescriptor_AccessManager_GetAllPermissions);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.accessmanager.Permission>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.accessmanager.AccessManagerPromiseClient.prototype.getAllPermissions =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.accessmanager.AccessManager/GetAllPermissions',
      request,
      metadata || {},
      methodDescriptor_AccessManager_GetAllPermissions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.kazatel.accessmanager.PermissionLite>}
 */
const methodDescriptor_AccessManager_GetUserPermissions = new grpc.web.MethodDescriptor(
  '/kazatel.accessmanager.AccessManager/GetUserPermissions',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_wrappers_pb.StringValue,
  proto.kazatel.accessmanager.PermissionLite,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.accessmanager.PermissionLite.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.accessmanager.PermissionLite>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.accessmanager.AccessManagerClient.prototype.getUserPermissions =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.accessmanager.AccessManager/GetUserPermissions',
      request,
      metadata || {},
      methodDescriptor_AccessManager_GetUserPermissions);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.accessmanager.PermissionLite>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.accessmanager.AccessManagerPromiseClient.prototype.getUserPermissions =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.accessmanager.AccessManager/GetUserPermissions',
      request,
      metadata || {},
      methodDescriptor_AccessManager_GetUserPermissions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Int32Value,
 *   !proto.kazatel.accessmanager.PermissionLite>}
 */
const methodDescriptor_AccessManager_GetRolePermissions = new grpc.web.MethodDescriptor(
  '/kazatel.accessmanager.AccessManager/GetRolePermissions',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_wrappers_pb.Int32Value,
  proto.kazatel.accessmanager.PermissionLite,
  /**
   * @param {!proto.google.protobuf.Int32Value} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kazatel.accessmanager.PermissionLite.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Int32Value} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.accessmanager.PermissionLite>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.accessmanager.AccessManagerClient.prototype.getRolePermissions =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.accessmanager.AccessManager/GetRolePermissions',
      request,
      metadata || {},
      methodDescriptor_AccessManager_GetRolePermissions);
};


/**
 * @param {!proto.google.protobuf.Int32Value} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.kazatel.accessmanager.PermissionLite>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.accessmanager.AccessManagerPromiseClient.prototype.getRolePermissions =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.accessmanager.AccessManager/GetRolePermissions',
      request,
      metadata || {},
      methodDescriptor_AccessManager_GetRolePermissions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.accessmanager.RolePermissions,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_AccessManager_ChangeRolePermissions = new grpc.web.MethodDescriptor(
  '/kazatel.accessmanager.AccessManager/ChangeRolePermissions',
  grpc.web.MethodType.UNARY,
  proto.kazatel.accessmanager.RolePermissions,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.kazatel.accessmanager.RolePermissions} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.kazatel.accessmanager.RolePermissions} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.accessmanager.AccessManagerClient.prototype.changeRolePermissions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.accessmanager.AccessManager/ChangeRolePermissions',
      request,
      metadata || {},
      methodDescriptor_AccessManager_ChangeRolePermissions,
      callback);
};


/**
 * @param {!proto.kazatel.accessmanager.RolePermissions} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.accessmanager.AccessManagerPromiseClient.prototype.changeRolePermissions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.accessmanager.AccessManager/ChangeRolePermissions',
      request,
      metadata || {},
      methodDescriptor_AccessManager_ChangeRolePermissions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.StringValue,
 *   !proto.google.protobuf.Int32Value>}
 */
const methodDescriptor_AccessManager_GetUserRoles = new grpc.web.MethodDescriptor(
  '/kazatel.accessmanager.AccessManager/GetUserRoles',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_wrappers_pb.StringValue,
  google_protobuf_wrappers_pb.Int32Value,
  /**
   * @param {!proto.google.protobuf.StringValue} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_wrappers_pb.Int32Value.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.StringValue} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Int32Value>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.accessmanager.AccessManagerClient.prototype.getUserRoles =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.accessmanager.AccessManager/GetUserRoles',
      request,
      metadata || {},
      methodDescriptor_AccessManager_GetUserRoles);
};


/**
 * @param {!proto.google.protobuf.StringValue} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Int32Value>}
 *     The XHR Node Readable Stream
 */
proto.kazatel.accessmanager.AccessManagerPromiseClient.prototype.getUserRoles =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/kazatel.accessmanager.AccessManager/GetUserRoles',
      request,
      metadata || {},
      methodDescriptor_AccessManager_GetUserRoles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kazatel.accessmanager.UserRoles,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_AccessManager_ChangeUserRoles = new grpc.web.MethodDescriptor(
  '/kazatel.accessmanager.AccessManager/ChangeUserRoles',
  grpc.web.MethodType.UNARY,
  proto.kazatel.accessmanager.UserRoles,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.kazatel.accessmanager.UserRoles} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.kazatel.accessmanager.UserRoles} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.accessmanager.AccessManagerClient.prototype.changeUserRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.accessmanager.AccessManager/ChangeUserRoles',
      request,
      metadata || {},
      methodDescriptor_AccessManager_ChangeUserRoles,
      callback);
};


/**
 * @param {!proto.kazatel.accessmanager.UserRoles} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.kazatel.accessmanager.AccessManagerPromiseClient.prototype.changeUserRoles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.accessmanager.AccessManager/ChangeUserRoles',
      request,
      metadata || {},
      methodDescriptor_AccessManager_ChangeUserRoles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.StringValue>}
 */
const methodDescriptor_AccessManager_GetLogin = new grpc.web.MethodDescriptor(
  '/kazatel.accessmanager.AccessManager/GetLogin',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_wrappers_pb.StringValue,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_wrappers_pb.StringValue.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.StringValue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.StringValue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kazatel.accessmanager.AccessManagerClient.prototype.getLogin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kazatel.accessmanager.AccessManager/GetLogin',
      request,
      metadata || {},
      methodDescriptor_AccessManager_GetLogin,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.StringValue>}
 *     Promise that resolves to the response
 */
proto.kazatel.accessmanager.AccessManagerPromiseClient.prototype.getLogin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kazatel.accessmanager.AccessManager/GetLogin',
      request,
      metadata || {},
      methodDescriptor_AccessManager_GetLogin);
};


module.exports = proto.kazatel.accessmanager;

