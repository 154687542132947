<template>
  <spinner :isActive="isNodesLoading">
    <div v-if="nodesList.length" class="heading__nav">
      <heading-size-h2>Содержание раздела</heading-size-h2>
      <input-search
        :value="searchQuery"
        @update:value="filterNodes"
        :placeholder="'Поиск по урокам из раздела'"
        :size="'S'"
      />
    </div>
    <!-- <Filter/> -->
    <div v-if="filteredNodesList.length" class="content__wrapper">
      <better-lesson-card
        v-for="card in filteredNodesList" :key="card.index"
        :lessonName="card.title"
        :id="card.id"
        :lessonGrade="route.query.level"
      />
    </div>

    <div v-if="!filteredNodesList.length && nodesList.length" class="content__wrapper_empty">
      <img src="@/assets/KazIllustrations/states/KazIllustrationNoSearchResult.png" />

      <div class="wrapper__text">
        <h2 class="heading heading_size_h2">По такому запросу у нас и показать нечего</h2>
        <p class="body body_size_M">Давай попробуем изменить что-то в строке поиска</p>
      </div>
    </div>
  </spinner>
  <lesson-card-empty v-if="!isNodesLoading && !nodesList.length"/>
</template>

<script>
import { defineAsyncComponent } from 'vue';

import CardBackground from '@/components/UI/card/CardBackground.vue';
import HeadingSizeH2 from '@/components/UI/texts/headings/HeadingSizeH2.vue';

import BetterLessonCard from '@/components/KazUI/molecules/cards/betterLessonCard';
import Filter from '@/components/KazUI/molecules/filter'

import FilterItem from '@/components/KazUI/atoms/filter-item';
import InputSearch from '@/components/KazUI/atoms/inputs/search';

// import useNodesTree from '@/hooks/books/useNodesTree.js';
import useEditorIntro from '@/hooks/routes/useEditorIntro.js';

import { useRoute } from 'vue-router';

export default {
  name: 'lesson-content',

  components:{
    CardBackground,
    HeadingSizeH2,
    BetterLessonCard,
    Filter,
    FilterItem,
    InputSearch,
    ContentNodeCard: defineAsyncComponent({
      loader: () => import('@/views/lessons/components/ContentNodeCard.vue')
    }),
  },



  props: {
    nodesList: {
      type: Array,
      required: true
    },
    isNodesLoading: {
      type: Boolean,
      required: true
    },
    noResultsFound: false,
  },

  data() {
    return {
      searchQuery: '',
      filteredNodesList: this.nodesList,
      searchTimeout: null
    }
  },
  watch: {
    nodesList: function(newVal) {
      this.filteredNodesList = newVal;
    }
  },
  setup(props) {
    const route = useRoute();
    const {getDestRouteName} = useEditorIntro('lesson');
    return { getDestRouteName, route }
  },
  methods: {
    filterNodes(newQuery) {
      this.searchQuery = newQuery;
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        if (this.searchQuery) {
          this.filteredNodesList = this.nodesList.filter(node =>
            node.title.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        } else {
          this.filteredNodesList = this.nodesList;
        }
      }, 500);
    }
  }
}
</script>

<style scoped>
.content__wrapper > * {
  animation: fadeIn 0.7s;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.heading__nav{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.content__wrapper{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  /* background-color: var(--background-light); */
  background-color: var(--kaz-base-secondary);
  padding: 16px;
  border-radius: 8px;
}

.content__wrapper_empty{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.wrapper__text{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

@media screen and (max-width: 992px) {
  .content__wrapper{
    grid-template-columns: repeat(1, 1fr);
  }
  .heading__nav{
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}
@media screen and (max-width: 568px) {
  .content__wrapper{
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>