!<template>
  <div class="task__assessment">
    <button
      class="task__button" 
      :class="{ 'active-selection': state === STATES.LIKE }"
      @click="assessment(STATES.LIKE)"
    >
      <span>
        <svg-icon iconName="kuiIconLikeLarge" size="24px" />
      </span>
    </button>
    <p
      class="body body_size_M" 
      :class="{ 'active-selection': state === STATES.LIKE }"
      :title="getLikes"
    >
      {{ getLikes }}
    </p>
    <span class="task__delimiter"></span>
    <button
      class="task__button"
      :class="{ 'active-selection': state === STATES.DISLIKE }"
      @click="assessment(STATES.DISLIKE)"
    >
      <span>
        <svg-icon iconName="kuiIconDislikeLarge" size="24px" />
      </span>
    </button>
    <p
      class="body body_size_M"
      :class="{ 'active-selection': state === STATES.DISLIKE }"
      :title="getDislikes"
    >
      {{ getDislikes }}
    </p>
  </div>
</template>

<script>
import { STATES } from '@/views/tasks/utils/assessmentStates.js';


function nonNegativeNumber(value) {
 return value >= 0;
}

function validState(value) {
  return [-1, 0, 1].includes(value);
}

export default {
  name: 'assessment',

  props: {
    likes: {
      type: Number,
      default: 0,
      validator: nonNegativeNumber
    },
    dislikes: {
      type: Number,
      default: 0,
      validator: nonNegativeNumber
    },
    state: {
      type: Number,
      default: STATES.INIT,
      validator: validState
    }
  },

  emits: ['assessment'],

  data() {
    return {
      STATES,
    }
  },

  computed: {
    getLikes() {
      return this.likes < 0 ? 0 : this.likes;
    },
    getDislikes() {
      return this.dislikes < 0 ? 0 : this.dislikes;
    }
  },

  methods: {
    assessment(targetState) {
      // this.setState(targetState);
      this.$emit('assessment', this.state === targetState ? STATES.INIT : targetState);
    },
    setState(groundState) {
      if (this.state === groundState) {
        this.state = STATES.INIT;
      } else {
        this.state = groundState;
      }
    }
  }
}
</script>

<style scoped>
.task__assessment {
  display: flex;
  align-items: center;
  align-self: flex-end;
  gap: 10px;
  padding: 4px 16px;
  background: var(--kaz-base-base-01);
  box-shadow: 4px 0px 16px rgba(74, 74, 74, 0.15);
  border-radius: 12px;
}

.task__delimiter {
  width: 1px;
  align-self: stretch;
  background: var(--kaz-base-base-04);
}

.task__button {
  background: transparent;
  border: none;
  padding: 8px;
  border-radius: 12px;
}

.active-selection {
  --kaz-base-base-09: var(--kaz-base-accent);
  color: var(--kaz-base-accent);
}

.task__button:hover {
  background-color: var(--kaz-base-clear);
  /* --kaz-base-base-09: var(--kaz-base-accent-hover); */
}
</style>
