<template>
  <h1
    :style="{ color: textColor }"
    :class="{ 'heading_size_h1_small': smallSize }"
    class="heading heading_type_H1"
  >
    <slot></slot>
  </h1>
</template>

<script>
export default {
  name: 'heading-size-h2',
  props: {
    textColor: {
      type: String,
      default: "var(--text-black)"
    },
    smallSize: {
      type: Boolean,
      default: false
    },
  }
};
</script>

<style scoped>
.heading_type_H1 {
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.24px;
  color: var(--text-black);
}
.heading_size_h1_small {
  font-size: 25px;
  line-height: 30px;
}

@media screen and (max-width: 992px) {
  .heading_type_H1 {
    font-size: 25px;
    line-height: 30px;
  }
}


</style>