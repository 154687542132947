export const paragraphData = {
  namespaced: true,
  state: () => ({
    paragraphs: [
      {
        id: 1, 
        title: '§ 1. Основные понятия',
        tasks: []
      },
      {
        id: 2, 
        title: '§ 2. Основное свойство алгебраической дроби',
        tasks: []
      },
      {
        id: 3, 
        title: '§ 3. Сложение и вычитание алгебраических дробей с одинаковыми знаменателями',
        tasks: []
      },
      {
        id: 4, 
        title: '§ 4. Умножение и деление алгебраических дробей с разными знаменателями.',
        tasks: []
      },
      {
        id: 5, 
        title: '§ 5. Умножение и деление алгебраических дробей. Возведение алгебраической дроби в степень.',
        tasks: []
      },
    ],

    // only for testing
    tasks: [
      {id: 10, title: 1, page: 6},
      {id: 12, title: 7, page: 6},
      {id: 13, title: 10, page: 6},
      {id: 14, title: 13, page: 16},
      {id: 15, title: 15, page: 16},
      {id: 16, title: 16, page: 16},
      {id: 17, title: 19, page: 26},
      {id: 18, title: 21, page: 26},
      {id: 19, title: 25, page: 36},
      {id: 20, title: 26, page: 36},
    ]
  }),

  getters: {
    getParagraphs(state) {
      return state.paragraphs;
    },
    getTasks(state) {
      return state.tasks;
    }
  },

  actions: {
    fillParagraphs({getters}) {
      const paragraphs = []
      for (const paragraph of getters.getParagraphs) {
        paragraph.tasks = [...getters.getTasks];
        paragraphs.push(paragraph);
      }
      return paragraphs
    }
  }
}