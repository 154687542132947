<template>
  <div class="checkbox" @click.stop>
    <input
      class="checkbox__input"
      type="checkbox"
      :id="id"
      :checked="isChecked"
      @change="updateValue($event.target.checked)"
      :filterId="filterId"
    />
    <label class="checkbox__label label label_size_M" :for="id">
      <svg v-show="isChecked" class="checkbox__icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="24px / tuiIconCheckLarge">
        <path id="Union" fill-rule="evenodd" clip-rule="evenodd" d="M17.7304 7.31701C18.1076 7.72041 18.0864 8.35322 17.683 8.73043L11.058 16.7304C10.6736 17.0898 10.0765 17.0899 9.69209 16.7305L6.31709 13.6405C5.91365 13.2633 5.89234 12.6305 6.2695 12.2271C6.64667 11.8236 7.27947 11.8023 7.68292 12.1795L10.3749 14.631L16.317 7.26958C16.7204 6.89237 17.3532 6.91361 17.7304 7.31701Z" fill="var(--kaz-base-base-01)"/>
        </g>
      </svg>
      {{ label }}
    </label>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name:'checkbox',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    filterId: {
      type: [String, Number],
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Я чекбокс',
    },
  },
  data() {
    return {
      // isChecked: this.checked
    }
  },

  methods: {
    ...mapActions('moderationFilters', ['updateFilter']),


    updateValue(value) {
      this.isChecked = value;

      this.updateFilter({ filterId: this.filterId, id: this.id, label: this.label, value });
    },
  },

  computed: {
    ...mapGetters('moderationFilters', ['getFilters']),

    isChecked: {
      get() {
        return !!this.getFilters.find(filter => filter.label === this.label);
      },
      set(value) {
        this.updateFilter({ filterId: this.filterId, id: this.id, label: this.label, value });
      },
    },
  },
};
</script>

<style scoped>
.checkbox {
  display: inline-flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.checkbox__input {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  z-index: 1;
  cursor: pointer;
}

.checkbox__label {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding-left: 32px;
}

.checkbox__label:before {
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 8px;
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--kaz-base-secondary-hover);
  transition: background-color 0.3s ease-in-out;
}

/* .checkbox__label:hover::before {
  background-color: var(--kaz-base-secondary-hover);
  transition: background-color 0.3s ease-in-out;
} */

.checkbox__input:checked + .checkbox__label:before {
  border-color: var(--kaz-base-primary);
  background-color: var(--kaz-base-primary);
}

.checkbox__icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  transition: visibility 0.3s ease;
}

.checkbox__input:checked + .checkbox__label .checkbox__icon {
  visibility: visible;
}
</style>
