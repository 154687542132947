!<template>
  <popup-window
    :isVisible="isVisible" 
    :title="getTitleText"
    @close="$emit('close')"
  >
    <template v-slot:text>
      <paragraph-size-medium textColor="var(--text-secondary)">{{ getMessageText }}</paragraph-size-medium>
    </template>
    <template v-slot:button>
      <div class="buttons">
        <button-primary
          style="width: 100%;"
          @click="changeTarif"
        >
          Оформлю другой тариф
        </button-primary>
        <!-- <button class="button" @click="$emit('close')">
          <paragraph-size-medium textColor="var(--background-brown)" textWeight="600">
            Просто подожду
          </paragraph-size-medium>
        </button> -->
      </div>
    </template>
  </popup-window>
</template>

<script>
import PopupWindow from '@/components/UI/popups/PopupWindow.vue';
import ParagraphSizeMedium from '@/components/UI/texts/paragraphs/ParagraphSizeMedium.vue';
import ButtonPrimary from '@/components/UI/buttons/ButtonPrimary.vue';


export default {
  name: 'popup-tarif-change',

  components: {
    PopupWindow,
    ParagraphSizeMedium,
    ButtonPrimary,
  },

  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: {}
    }
  },

  emits: ['close'],

  computed: {
    getTitleText() {
      let title = 'У тебя закончились просмотры';
      if (this.options?.isPremium) {
        title = 'В твоем тарифе нет премиум просмотров';
      }
      return title
    },
    getMessageText() {
      let paragraph = 'Мы заметили, что лимит на просмотры по твоей подписке закончился. Можно просто подождать до завтра или оформить тариф лучше.';
      if (this.options?.isPremium) {
        paragraph = 'Мы заметили, что ты хочешь посмотреть премиум решение, но в твоем тарифе нет просмотров для таких задач. Посмотри тарифы.'
      }
      return paragraph
    }
  },

  methods: {
    changeTarif() {
      // query param 'tc' is indicator of redirection from this popup
      this.$router.push({name: 'personal-info', query: {tc: 1}})
    }
  }
}
</script>

<style src="@/components/UI/buttons/button.css"></style>
<style scoped>
.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
</style>
