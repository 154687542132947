function isQuillEmpty(quill) {
  /**
   * Whitespaces are considered as empty symbols.
   * 
   * Args:
   *  - quill is an instance of QuillJS
   */
  if ((quill.getContents()['ops'] || []).length !== 1) { return false }
  return quill.getText().trim().length === 0
}

function isDeltaEmpty(delta) {
  /**
   * Whitespaces are considered as empty symbols.
   * 
   * Args:
   *  - delta is an instance of DeltaJs.
   */
  if (delta === null || delta.ops.length === 0) {
    return true;
  }
  for (let i = 0; i < delta.ops.length; i++) {
    if (typeof delta.ops[i].insert === 'object' || delta.ops[i].insert.trim() !== '') {
      return false;
    }
  }
  return true;
}

const toolbarTooltips = {
  'font': 'Выбрать шрифт',
  'size': 'Выбрать размер шрифта',
  'header': 'Стиль текста',
  'bold': 'Полужирный',
  'italic': 'Курсивный',
  'underline': 'Подчеркнутый',
  'strike': 'Перечеркнутый',
  'color' : 'Цвет текста',
  'background': 'Цвет фона',
  'script': {
    'sub' : 'Подстрочный индекс',
    'super': 'Надстрочный индекс'
  },
  'list': {
    'ordered':'Нумерованный список',
    'bullet': 'Маркированный список'
  },
  'indent': {
    '-1': 'Уменьшить отступ',
    '+1':  'Увеличить отступ'
  },
  'direction': {
    'rtl': 'Направление текста (справа налево)',
    'ltr': 'Text direction (слева направо)'
  },
  'align': 'Выравнивание текста',
  'blockquote': 'Цитирование',
  'code-block': 'Блок кода',
  'link': 'Вставить ссылку',
  'image': 'Вставить изображение',
  'formula': 'Вставить формулу',
  'clean': 'Очистить форматирование',
  'add-table': 'Добавить таблицу',
  'table-row': 'Добавить строку к выбранной таблице',
  'table-column': 'Добавить столбец к выбранной таблице',
  'remove-table': 'Удалить таблицу',
  'help': 'Помощь'
};

function showTooltips() {
  let showTooltip = (which,el) => {
    if (which === 'button') {
      var tool = el.className.replace('ql-', '');
    } else if (which === 'span') {
      var tool = el.className.replace('ql-','');
      tool=tool.substr(0,tool.indexOf(' '));
    }
    if (tool) {
      //if element has value attribute.. handling is different
      //buttons without value
      if (el.value =='') {
        if (toolbarTooltips[tool])
          el.setAttribute('title',toolbarTooltips[tool]);
      } else if (typeof el.value !=='undefined') {
        // buttons with value
        if (toolbarTooltips[tool][el.value])
          el.setAttribute('title',toolbarTooltips[tool][el.value]);
      } else
        // default
        el.setAttribute('title',toolbarTooltips[tool]);
    }
  };

  let toolbarElement = document.querySelector('.ql-toolbar');
  if (toolbarElement) {
    let matchesButtons = toolbarElement.querySelectorAll('button');
    for (let el of matchesButtons) {
      showTooltip('button',el);
    }
    //for submenus inside 
    let matchesSpans = toolbarElement.querySelectorAll('.ql-toolbar > span > span');
    for (let el of matchesSpans) {
      showTooltip('span',el);
    }
  }
}


export {
  isQuillEmpty,
  isDeltaEmpty,
  toolbarTooltips,
  showTooltips,
}