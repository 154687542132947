import { extractTokenInfo } from '@/utils/auth';


function createMetadataWithToken() {
  var tokens = extractTokenInfo();
  var metadata = { 'token': tokens.accessToken.token };
  return metadata;
}


export {
  createMetadataWithToken,
}