!<template>
  <CardBackground>
    <h2 class="heading heading_size_h2">
      Связанные учебники
    </h2>
    <KazLoader v-if="isLoading" size="L"/>
    <ul v-show="!isLoading" class="linked-books">
      <li v-for="book in linkedBooks" :key="book.id">
        <book-card
          :key="book.id"
          :id="book.id"
          :bookTitle="book.title"
          :bookImg="book.cover"
          :bookDescription="getDescription(book)"
          :authors="book.authors.slice(0, 2)"
        />
      </li>
    </ul>
  </CardBackground>
</template>

<script setup>
import { ref, watch, computed } from 'vue';

import CardBackground from '@/components/UI/card/CardBackground.vue';
import BookCard from '@/components/cards/BookCard';
import KazLoader from '@/components/KazUI/atoms/loader';

import { fetchLinkedBooks, fetchBooks } from '@/services/TaskReader';


const props = defineProps({
  bookId: {
    type: [String, null],
    default: null,
    required: true,
  }
});


const emit = defineEmits(['has-books']);


const isLoading = ref(true);
const linkedBooks = ref([]);


watch(
  () => props.bookId,
  async (newId) => {
    try {
      isLoading.value = true;
      linkedBooks.value = [];
      const booksIdListRes = await fetchLinkedBooks(newId);
      if (booksIdListRes.length) {
        const booksListRes = await fetchBooks({
          statusList: [7],
          searchValue: '',
          pageSize: 10,
          booksList: booksIdListRes.map(res => res.getId()),
        });
        
        linkedBooks.value = booksListRes.map(res => {
          const b = res.toObject();
          // console.log('lb:', b);
          return {
            id: b.id,
            title: b.nameOfficial,
            subject: b.subject,
            level: b.levelsList[0],
            type: b.type,
            cover: b.viewUrl,
            isPremium: b.attrPremium,
            authors: b.authors?.split(', ') || [],
            year: b.publishYear,
            type: b.type,
            part: b.part,
          };
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      isLoading.value = false;
      emit('has-books', linkedBooks.value.length > 0);
    }
  },
  { immediate: true }
);


function getDescription(book) {
  return {
    isPremium: book.isPremium,
    year: book.year,
    type: book.type,
    part: book.part,
  }
}
</script>

<style scoped>
.linked-books {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  margin-top: 24px;
}
</style>
