<template>
  <button
    class="button button_type_like"
    :class="{ 'button_type_like_active': isLiked }"
  >
  
  </button>
</template>

<script>
export default {
  name: 'like-button',

  props: {
    isLiked: {
      type: Boolean,
      required: true
    }
  },

  emits: [
    'emitClick'
  ],

  methods: {
    toggleLike() {
      this.isLiked = !this.isLiked;
      this.$emit('emitClick', this.isLiked);
    },
  },
};
</script>

<style src="./button.css"></style>
<style scoped>
.button_type_like {
  width: 24px;
  height: 24px;
  background-image: url('@/assets/images/fav-button.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  transition: background-image 0.3s ease;
}

.button_type_like:hover {
  background-image: url('@/assets/images/fav-button-hover.svg');
}
.button_type_like_active {
  background-image: url('@/assets/images/fav-button-filled.svg');
}
.button_type_like_active:hover {
  background-image: url('@/assets/images/fav-button-filled.svg');
}
@media screen and (max-width: 568px) {
  .button_type_like {
    width: 36px;
    height: 36px;
  }
}
</style>
