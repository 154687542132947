// source: entities/entities.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.kazatel.entities.EntitiesRequest', null, global);
goog.exportSymbol('proto.kazatel.entities.Entity', null, global);
goog.exportSymbol('proto.kazatel.entities.EntityDetail', null, global);
goog.exportSymbol('proto.kazatel.entities.Level', null, global);
goog.exportSymbol('proto.kazatel.entities.LevelsBySubject', null, global);
goog.exportSymbol('proto.kazatel.entities.Link', null, global);
goog.exportSymbol('proto.kazatel.entities.Links', null, global);
goog.exportSymbol('proto.kazatel.entities.Node', null, global);
goog.exportSymbol('proto.kazatel.entities.NodeRequest', null, global);
goog.exportSymbol('proto.kazatel.entities.NodeType', null, global);
goog.exportSymbol('proto.kazatel.entities.NodesRequest', null, global);
goog.exportSymbol('proto.kazatel.entities.Subject', null, global);
goog.exportSymbol('proto.kazatel.entities.SubjectsByLevel', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.entities.Level = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.entities.Level, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.entities.Level.displayName = 'proto.kazatel.entities.Level';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.entities.Subject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.entities.Subject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.entities.Subject.displayName = 'proto.kazatel.entities.Subject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.entities.SubjectsByLevel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.entities.SubjectsByLevel.repeatedFields_, null);
};
goog.inherits(proto.kazatel.entities.SubjectsByLevel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.entities.SubjectsByLevel.displayName = 'proto.kazatel.entities.SubjectsByLevel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.entities.LevelsBySubject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.entities.LevelsBySubject.repeatedFields_, null);
};
goog.inherits(proto.kazatel.entities.LevelsBySubject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.entities.LevelsBySubject.displayName = 'proto.kazatel.entities.LevelsBySubject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.entities.NodeType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.entities.NodeType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.entities.NodeType.displayName = 'proto.kazatel.entities.NodeType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.entities.Entity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.entities.Entity.repeatedFields_, null);
};
goog.inherits(proto.kazatel.entities.Entity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.entities.Entity.displayName = 'proto.kazatel.entities.Entity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.entities.EntityDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.entities.EntityDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.entities.EntityDetail.displayName = 'proto.kazatel.entities.EntityDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.entities.Node = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.entities.Node, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.entities.Node.displayName = 'proto.kazatel.entities.Node';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.entities.EntitiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.entities.EntitiesRequest.repeatedFields_, null);
};
goog.inherits(proto.kazatel.entities.EntitiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.entities.EntitiesRequest.displayName = 'proto.kazatel.entities.EntitiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.entities.NodeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.entities.NodeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.entities.NodeRequest.displayName = 'proto.kazatel.entities.NodeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.entities.NodesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.entities.NodesRequest.repeatedFields_, null);
};
goog.inherits(proto.kazatel.entities.NodesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.entities.NodesRequest.displayName = 'proto.kazatel.entities.NodesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.entities.Link = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.entities.Link, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.entities.Link.displayName = 'proto.kazatel.entities.Link';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.entities.Links = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.entities.Links.repeatedFields_, null);
};
goog.inherits(proto.kazatel.entities.Links, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.entities.Links.displayName = 'proto.kazatel.entities.Links';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.entities.Level.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.entities.Level.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.entities.Level} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.Level.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.entities.Level}
 */
proto.kazatel.entities.Level.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.entities.Level;
  return proto.kazatel.entities.Level.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.entities.Level} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.entities.Level}
 */
proto.kazatel.entities.Level.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.entities.Level.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.entities.Level.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.entities.Level} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.Level.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.kazatel.entities.Level.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.entities.Level} returns this
 */
proto.kazatel.entities.Level.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.kazatel.entities.Level.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.Level} returns this
 */
proto.kazatel.entities.Level.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.entities.Subject.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.entities.Subject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.entities.Subject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.Subject.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.entities.Subject}
 */
proto.kazatel.entities.Subject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.entities.Subject;
  return proto.kazatel.entities.Subject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.entities.Subject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.entities.Subject}
 */
proto.kazatel.entities.Subject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.entities.Subject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.entities.Subject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.entities.Subject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.Subject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.kazatel.entities.Subject.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.entities.Subject} returns this
 */
proto.kazatel.entities.Subject.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.kazatel.entities.Subject.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.Subject} returns this
 */
proto.kazatel.entities.Subject.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.entities.SubjectsByLevel.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.entities.SubjectsByLevel.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.entities.SubjectsByLevel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.entities.SubjectsByLevel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.SubjectsByLevel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subjectsList: jspb.Message.toObjectList(msg.getSubjectsList(),
    proto.kazatel.entities.Subject.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.entities.SubjectsByLevel}
 */
proto.kazatel.entities.SubjectsByLevel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.entities.SubjectsByLevel;
  return proto.kazatel.entities.SubjectsByLevel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.entities.SubjectsByLevel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.entities.SubjectsByLevel}
 */
proto.kazatel.entities.SubjectsByLevel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.kazatel.entities.Subject;
      reader.readMessage(value,proto.kazatel.entities.Subject.deserializeBinaryFromReader);
      msg.addSubjects(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.entities.SubjectsByLevel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.entities.SubjectsByLevel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.entities.SubjectsByLevel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.SubjectsByLevel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.kazatel.entities.Subject.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.kazatel.entities.SubjectsByLevel.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.entities.SubjectsByLevel} returns this
 */
proto.kazatel.entities.SubjectsByLevel.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.kazatel.entities.SubjectsByLevel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.SubjectsByLevel} returns this
 */
proto.kazatel.entities.SubjectsByLevel.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Subject subjects = 3;
 * @return {!Array<!proto.kazatel.entities.Subject>}
 */
proto.kazatel.entities.SubjectsByLevel.prototype.getSubjectsList = function() {
  return /** @type{!Array<!proto.kazatel.entities.Subject>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kazatel.entities.Subject, 3));
};


/**
 * @param {!Array<!proto.kazatel.entities.Subject>} value
 * @return {!proto.kazatel.entities.SubjectsByLevel} returns this
*/
proto.kazatel.entities.SubjectsByLevel.prototype.setSubjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.kazatel.entities.Subject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.entities.Subject}
 */
proto.kazatel.entities.SubjectsByLevel.prototype.addSubjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.kazatel.entities.Subject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.entities.SubjectsByLevel} returns this
 */
proto.kazatel.entities.SubjectsByLevel.prototype.clearSubjectsList = function() {
  return this.setSubjectsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.entities.LevelsBySubject.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.entities.LevelsBySubject.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.entities.LevelsBySubject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.entities.LevelsBySubject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.LevelsBySubject.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    levelsList: jspb.Message.toObjectList(msg.getLevelsList(),
    proto.kazatel.entities.Level.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.entities.LevelsBySubject}
 */
proto.kazatel.entities.LevelsBySubject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.entities.LevelsBySubject;
  return proto.kazatel.entities.LevelsBySubject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.entities.LevelsBySubject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.entities.LevelsBySubject}
 */
proto.kazatel.entities.LevelsBySubject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.kazatel.entities.Level;
      reader.readMessage(value,proto.kazatel.entities.Level.deserializeBinaryFromReader);
      msg.addLevels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.entities.LevelsBySubject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.entities.LevelsBySubject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.entities.LevelsBySubject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.LevelsBySubject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLevelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.kazatel.entities.Level.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.kazatel.entities.LevelsBySubject.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.entities.LevelsBySubject} returns this
 */
proto.kazatel.entities.LevelsBySubject.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.kazatel.entities.LevelsBySubject.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.LevelsBySubject} returns this
 */
proto.kazatel.entities.LevelsBySubject.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Level levels = 3;
 * @return {!Array<!proto.kazatel.entities.Level>}
 */
proto.kazatel.entities.LevelsBySubject.prototype.getLevelsList = function() {
  return /** @type{!Array<!proto.kazatel.entities.Level>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kazatel.entities.Level, 3));
};


/**
 * @param {!Array<!proto.kazatel.entities.Level>} value
 * @return {!proto.kazatel.entities.LevelsBySubject} returns this
*/
proto.kazatel.entities.LevelsBySubject.prototype.setLevelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.kazatel.entities.Level=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.entities.Level}
 */
proto.kazatel.entities.LevelsBySubject.prototype.addLevels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.kazatel.entities.Level, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.entities.LevelsBySubject} returns this
 */
proto.kazatel.entities.LevelsBySubject.prototype.clearLevelsList = function() {
  return this.setLevelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.entities.NodeType.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.entities.NodeType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.entities.NodeType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.NodeType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    designation: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.entities.NodeType}
 */
proto.kazatel.entities.NodeType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.entities.NodeType;
  return proto.kazatel.entities.NodeType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.entities.NodeType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.entities.NodeType}
 */
proto.kazatel.entities.NodeType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesignation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.entities.NodeType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.entities.NodeType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.entities.NodeType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.NodeType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDesignation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.kazatel.entities.NodeType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.entities.NodeType} returns this
 */
proto.kazatel.entities.NodeType.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.kazatel.entities.NodeType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.NodeType} returns this
 */
proto.kazatel.entities.NodeType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string designation = 3;
 * @return {string}
 */
proto.kazatel.entities.NodeType.prototype.getDesignation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.NodeType} returns this
 */
proto.kazatel.entities.NodeType.prototype.setDesignation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.entities.Entity.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.entities.Entity.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.entities.Entity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.entities.Entity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.Entity.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    levelsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    publisher: jspb.Message.getFieldWithDefault(msg, 4, ""),
    statusId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    subjectId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    viewUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    authors: jspb.Message.getFieldWithDefault(msg, 8, ""),
    attrPremium: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    publishYear: jspb.Message.getFieldWithDefault(msg, 10, ""),
    type: jspb.Message.getFieldWithDefault(msg, 11, ""),
    part: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.entities.Entity}
 */
proto.kazatel.entities.Entity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.entities.Entity;
  return proto.kazatel.entities.Entity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.entities.Entity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.entities.Entity}
 */
proto.kazatel.entities.Entity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLevels(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublisher(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatusId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSubjectId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setViewUrl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthors(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAttrPremium(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublishYear(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPart(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.entities.Entity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.entities.Entity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.entities.Entity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.Entity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLevelsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
  f = message.getPublisher();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatusId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSubjectId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getViewUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAuthors();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAttrPremium();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getPublishYear();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPart();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.kazatel.entities.Entity.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.Entity} returns this
 */
proto.kazatel.entities.Entity.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.kazatel.entities.Entity.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.Entity} returns this
 */
proto.kazatel.entities.Entity.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int32 levels = 3;
 * @return {!Array<number>}
 */
proto.kazatel.entities.Entity.prototype.getLevelsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.kazatel.entities.Entity} returns this
 */
proto.kazatel.entities.Entity.prototype.setLevelsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.entities.Entity} returns this
 */
proto.kazatel.entities.Entity.prototype.addLevels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.entities.Entity} returns this
 */
proto.kazatel.entities.Entity.prototype.clearLevelsList = function() {
  return this.setLevelsList([]);
};


/**
 * optional string publisher = 4;
 * @return {string}
 */
proto.kazatel.entities.Entity.prototype.getPublisher = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.Entity} returns this
 */
proto.kazatel.entities.Entity.prototype.setPublisher = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 status_id = 5;
 * @return {number}
 */
proto.kazatel.entities.Entity.prototype.getStatusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.entities.Entity} returns this
 */
proto.kazatel.entities.Entity.prototype.setStatusId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 subject_id = 6;
 * @return {number}
 */
proto.kazatel.entities.Entity.prototype.getSubjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.entities.Entity} returns this
 */
proto.kazatel.entities.Entity.prototype.setSubjectId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string view_url = 7;
 * @return {string}
 */
proto.kazatel.entities.Entity.prototype.getViewUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.Entity} returns this
 */
proto.kazatel.entities.Entity.prototype.setViewUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string authors = 8;
 * @return {string}
 */
proto.kazatel.entities.Entity.prototype.getAuthors = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.Entity} returns this
 */
proto.kazatel.entities.Entity.prototype.setAuthors = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool attr_premium = 9;
 * @return {boolean}
 */
proto.kazatel.entities.Entity.prototype.getAttrPremium = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.entities.Entity} returns this
 */
proto.kazatel.entities.Entity.prototype.setAttrPremium = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string publish_year = 10;
 * @return {string}
 */
proto.kazatel.entities.Entity.prototype.getPublishYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.Entity} returns this
 */
proto.kazatel.entities.Entity.prototype.setPublishYear = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string type = 11;
 * @return {string}
 */
proto.kazatel.entities.Entity.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.Entity} returns this
 */
proto.kazatel.entities.Entity.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string part = 12;
 * @return {string}
 */
proto.kazatel.entities.Entity.prototype.getPart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.Entity} returns this
 */
proto.kazatel.entities.Entity.prototype.setPart = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.entities.EntityDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.entities.EntityDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.entities.EntityDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.EntityDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    entity: (f = msg.getEntity()) && proto.kazatel.entities.Entity.toObject(includeInstance, f),
    rootNodeId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.entities.EntityDetail}
 */
proto.kazatel.entities.EntityDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.entities.EntityDetail;
  return proto.kazatel.entities.EntityDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.entities.EntityDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.entities.EntityDetail}
 */
proto.kazatel.entities.EntityDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.kazatel.entities.Entity;
      reader.readMessage(value,proto.kazatel.entities.Entity.deserializeBinaryFromReader);
      msg.setEntity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRootNodeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.entities.EntityDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.entities.EntityDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.entities.EntityDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.EntityDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.kazatel.entities.Entity.serializeBinaryToWriter
    );
  }
  f = message.getRootNodeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Entity entity = 1;
 * @return {?proto.kazatel.entities.Entity}
 */
proto.kazatel.entities.EntityDetail.prototype.getEntity = function() {
  return /** @type{?proto.kazatel.entities.Entity} */ (
    jspb.Message.getWrapperField(this, proto.kazatel.entities.Entity, 1));
};


/**
 * @param {?proto.kazatel.entities.Entity|undefined} value
 * @return {!proto.kazatel.entities.EntityDetail} returns this
*/
proto.kazatel.entities.EntityDetail.prototype.setEntity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.entities.EntityDetail} returns this
 */
proto.kazatel.entities.EntityDetail.prototype.clearEntity = function() {
  return this.setEntity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.entities.EntityDetail.prototype.hasEntity = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string root_node_id = 2;
 * @return {string}
 */
proto.kazatel.entities.EntityDetail.prototype.getRootNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.EntityDetail} returns this
 */
proto.kazatel.entities.EntityDetail.prototype.setRootNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.entities.Node.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.entities.Node.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.entities.Node} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.Node.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    parentId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    prevId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    nextId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    viewUrl: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.entities.Node}
 */
proto.kazatel.entities.Node.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.entities.Node;
  return proto.kazatel.entities.Node.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.entities.Node} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.entities.Node}
 */
proto.kazatel.entities.Node.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrevId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setViewUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.entities.Node.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.entities.Node.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.entities.Node} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.Node.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParentId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getViewUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.kazatel.entities.Node.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.Node} returns this
 */
proto.kazatel.entities.Node.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.kazatel.entities.Node.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.Node} returns this
 */
proto.kazatel.entities.Node.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string parent_id = 3;
 * @return {string}
 */
proto.kazatel.entities.Node.prototype.getParentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.Node} returns this
 */
proto.kazatel.entities.Node.prototype.setParentId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.kazatel.entities.Node.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.Node} returns this
 */
proto.kazatel.entities.Node.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string prev_id = 5;
 * @return {string}
 */
proto.kazatel.entities.Node.prototype.getPrevId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.Node} returns this
 */
proto.kazatel.entities.Node.prototype.setPrevId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.entities.Node} returns this
 */
proto.kazatel.entities.Node.prototype.clearPrevId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.entities.Node.prototype.hasPrevId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string next_id = 6;
 * @return {string}
 */
proto.kazatel.entities.Node.prototype.getNextId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.Node} returns this
 */
proto.kazatel.entities.Node.prototype.setNextId = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.entities.Node} returns this
 */
proto.kazatel.entities.Node.prototype.clearNextId = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.entities.Node.prototype.hasNextId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string view_url = 7;
 * @return {string}
 */
proto.kazatel.entities.Node.prototype.getViewUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.Node} returns this
 */
proto.kazatel.entities.Node.prototype.setViewUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.entities.EntitiesRequest.repeatedFields_ = [1,2,3,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.entities.EntitiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.entities.EntitiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.entities.EntitiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.EntitiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    levelsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    subjectsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    statusList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 5, ""),
    orderBy: jspb.Message.getFieldWithDefault(msg, 6, ""),
    entitiesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    typesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    like: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.entities.EntitiesRequest}
 */
proto.kazatel.entities.EntitiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.entities.EntitiesRequest;
  return proto.kazatel.entities.EntitiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.entities.EntitiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.entities.EntitiesRequest}
 */
proto.kazatel.entities.EntitiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLevels(values[i]);
      }
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSubjects(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatus(values[i]);
      }
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderBy(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addEntities(value);
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTypes(values[i]);
      }
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLike(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.entities.EntitiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.entities.EntitiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.entities.EntitiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.EntitiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLevelsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getSubjectsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrderBy();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEntitiesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getTypesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * repeated int32 levels = 1;
 * @return {!Array<number>}
 */
proto.kazatel.entities.EntitiesRequest.prototype.getLevelsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.kazatel.entities.EntitiesRequest} returns this
 */
proto.kazatel.entities.EntitiesRequest.prototype.setLevelsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.entities.EntitiesRequest} returns this
 */
proto.kazatel.entities.EntitiesRequest.prototype.addLevels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.entities.EntitiesRequest} returns this
 */
proto.kazatel.entities.EntitiesRequest.prototype.clearLevelsList = function() {
  return this.setLevelsList([]);
};


/**
 * repeated int32 subjects = 2;
 * @return {!Array<number>}
 */
proto.kazatel.entities.EntitiesRequest.prototype.getSubjectsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.kazatel.entities.EntitiesRequest} returns this
 */
proto.kazatel.entities.EntitiesRequest.prototype.setSubjectsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.entities.EntitiesRequest} returns this
 */
proto.kazatel.entities.EntitiesRequest.prototype.addSubjects = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.entities.EntitiesRequest} returns this
 */
proto.kazatel.entities.EntitiesRequest.prototype.clearSubjectsList = function() {
  return this.setSubjectsList([]);
};


/**
 * repeated int32 status = 3;
 * @return {!Array<number>}
 */
proto.kazatel.entities.EntitiesRequest.prototype.getStatusList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.kazatel.entities.EntitiesRequest} returns this
 */
proto.kazatel.entities.EntitiesRequest.prototype.setStatusList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.entities.EntitiesRequest} returns this
 */
proto.kazatel.entities.EntitiesRequest.prototype.addStatus = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.entities.EntitiesRequest} returns this
 */
proto.kazatel.entities.EntitiesRequest.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};


/**
 * optional int32 page_size = 4;
 * @return {number}
 */
proto.kazatel.entities.EntitiesRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.entities.EntitiesRequest} returns this
 */
proto.kazatel.entities.EntitiesRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string page_token = 5;
 * @return {string}
 */
proto.kazatel.entities.EntitiesRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.EntitiesRequest} returns this
 */
proto.kazatel.entities.EntitiesRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string order_by = 6;
 * @return {string}
 */
proto.kazatel.entities.EntitiesRequest.prototype.getOrderBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.EntitiesRequest} returns this
 */
proto.kazatel.entities.EntitiesRequest.prototype.setOrderBy = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated string entities = 7;
 * @return {!Array<string>}
 */
proto.kazatel.entities.EntitiesRequest.prototype.getEntitiesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.kazatel.entities.EntitiesRequest} returns this
 */
proto.kazatel.entities.EntitiesRequest.prototype.setEntitiesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.entities.EntitiesRequest} returns this
 */
proto.kazatel.entities.EntitiesRequest.prototype.addEntities = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.entities.EntitiesRequest} returns this
 */
proto.kazatel.entities.EntitiesRequest.prototype.clearEntitiesList = function() {
  return this.setEntitiesList([]);
};


/**
 * repeated int32 types = 8;
 * @return {!Array<number>}
 */
proto.kazatel.entities.EntitiesRequest.prototype.getTypesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.kazatel.entities.EntitiesRequest} returns this
 */
proto.kazatel.entities.EntitiesRequest.prototype.setTypesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.entities.EntitiesRequest} returns this
 */
proto.kazatel.entities.EntitiesRequest.prototype.addTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.entities.EntitiesRequest} returns this
 */
proto.kazatel.entities.EntitiesRequest.prototype.clearTypesList = function() {
  return this.setTypesList([]);
};


/**
 * optional string like = 9;
 * @return {string}
 */
proto.kazatel.entities.EntitiesRequest.prototype.getLike = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.EntitiesRequest} returns this
 */
proto.kazatel.entities.EntitiesRequest.prototype.setLike = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.entities.EntitiesRequest} returns this
 */
proto.kazatel.entities.EntitiesRequest.prototype.clearLike = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.entities.EntitiesRequest.prototype.hasLike = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.entities.NodeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.entities.NodeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.entities.NodeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.NodeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tasksContent: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.entities.NodeRequest}
 */
proto.kazatel.entities.NodeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.entities.NodeRequest;
  return proto.kazatel.entities.NodeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.entities.NodeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.entities.NodeRequest}
 */
proto.kazatel.entities.NodeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTasksContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.entities.NodeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.entities.NodeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.entities.NodeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.NodeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTasksContent();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.kazatel.entities.NodeRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.NodeRequest} returns this
 */
proto.kazatel.entities.NodeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool tasks_content = 2;
 * @return {boolean}
 */
proto.kazatel.entities.NodeRequest.prototype.getTasksContent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.entities.NodeRequest} returns this
 */
proto.kazatel.entities.NodeRequest.prototype.setTasksContent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.entities.NodesRequest.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.entities.NodesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.entities.NodesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.entities.NodesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.NodesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    depth: jspb.Message.getFieldWithDefault(msg, 1, 0),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    parentId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    typesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    levelsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.entities.NodesRequest}
 */
proto.kazatel.entities.NodesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.entities.NodesRequest;
  return proto.kazatel.entities.NodesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.entities.NodesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.entities.NodesRequest}
 */
proto.kazatel.entities.NodesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDepth(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentId(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTypes(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLevels(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.entities.NodesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.entities.NodesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.entities.NodesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.NodesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepth();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParentId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTypesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
  f = message.getLevelsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 depth = 1;
 * @return {number}
 */
proto.kazatel.entities.NodesRequest.prototype.getDepth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.entities.NodesRequest} returns this
 */
proto.kazatel.entities.NodesRequest.prototype.setDepth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string entity_id = 2;
 * @return {string}
 */
proto.kazatel.entities.NodesRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.NodesRequest} returns this
 */
proto.kazatel.entities.NodesRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string parent_id = 3;
 * @return {string}
 */
proto.kazatel.entities.NodesRequest.prototype.getParentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.NodesRequest} returns this
 */
proto.kazatel.entities.NodesRequest.prototype.setParentId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated int32 types = 4;
 * @return {!Array<number>}
 */
proto.kazatel.entities.NodesRequest.prototype.getTypesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.kazatel.entities.NodesRequest} returns this
 */
proto.kazatel.entities.NodesRequest.prototype.setTypesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.entities.NodesRequest} returns this
 */
proto.kazatel.entities.NodesRequest.prototype.addTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.entities.NodesRequest} returns this
 */
proto.kazatel.entities.NodesRequest.prototype.clearTypesList = function() {
  return this.setTypesList([]);
};


/**
 * repeated int32 levels = 5;
 * @return {!Array<number>}
 */
proto.kazatel.entities.NodesRequest.prototype.getLevelsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.kazatel.entities.NodesRequest} returns this
 */
proto.kazatel.entities.NodesRequest.prototype.setLevelsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.entities.NodesRequest} returns this
 */
proto.kazatel.entities.NodesRequest.prototype.addLevels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.entities.NodesRequest} returns this
 */
proto.kazatel.entities.NodesRequest.prototype.clearLevelsList = function() {
  return this.setLevelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.entities.Link.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.entities.Link.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.entities.Link} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.Link.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inherited: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.entities.Link}
 */
proto.kazatel.entities.Link.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.entities.Link;
  return proto.kazatel.entities.Link.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.entities.Link} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.entities.Link}
 */
proto.kazatel.entities.Link.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInherited(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.entities.Link.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.entities.Link.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.entities.Link} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.Link.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.kazatel.entities.Link.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.Link} returns this
 */
proto.kazatel.entities.Link.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool inherited = 2;
 * @return {boolean}
 */
proto.kazatel.entities.Link.prototype.getInherited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.entities.Link} returns this
 */
proto.kazatel.entities.Link.prototype.setInherited = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.entities.Link} returns this
 */
proto.kazatel.entities.Link.prototype.clearInherited = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.entities.Link.prototype.hasInherited = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.entities.Links.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.entities.Links.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.entities.Links.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.entities.Links} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.Links.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    linksList: jspb.Message.toObjectList(msg.getLinksList(),
    proto.kazatel.entities.Link.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.entities.Links}
 */
proto.kazatel.entities.Links.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.entities.Links;
  return proto.kazatel.entities.Links.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.entities.Links} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.entities.Links}
 */
proto.kazatel.entities.Links.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.kazatel.entities.Link;
      reader.readMessage(value,proto.kazatel.entities.Link.deserializeBinaryFromReader);
      msg.addLinks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.entities.Links.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.entities.Links.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.entities.Links} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.entities.Links.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.kazatel.entities.Link.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.kazatel.entities.Links.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.entities.Links} returns this
 */
proto.kazatel.entities.Links.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Link links = 2;
 * @return {!Array<!proto.kazatel.entities.Link>}
 */
proto.kazatel.entities.Links.prototype.getLinksList = function() {
  return /** @type{!Array<!proto.kazatel.entities.Link>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kazatel.entities.Link, 2));
};


/**
 * @param {!Array<!proto.kazatel.entities.Link>} value
 * @return {!proto.kazatel.entities.Links} returns this
*/
proto.kazatel.entities.Links.prototype.setLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.kazatel.entities.Link=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.entities.Link}
 */
proto.kazatel.entities.Links.prototype.addLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.kazatel.entities.Link, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.entities.Links} returns this
 */
proto.kazatel.entities.Links.prototype.clearLinksList = function() {
  return this.setLinksList([]);
};


goog.object.extend(exports, proto.kazatel.entities);
