// source: entities/tasks.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var entities_enums_pb = require('../entities/enums_pb.js');
goog.object.extend(proto, entities_enums_pb);
var google_type_datetime_pb = require('../google/type/datetime_pb.js');
goog.object.extend(proto, google_type_datetime_pb);
goog.exportSymbol('proto.kazatel.tasks.Card', null, global);
goog.exportSymbol('proto.kazatel.tasks.CardData', null, global);
goog.exportSymbol('proto.kazatel.tasks.Common', null, global);
goog.exportSymbol('proto.kazatel.tasks.Content', null, global);
goog.exportSymbol('proto.kazatel.tasks.ContentByFormats', null, global);
goog.exportSymbol('proto.kazatel.tasks.ContentByTypes', null, global);
goog.exportSymbol('proto.kazatel.tasks.ContentMeta', null, global);
goog.exportSymbol('proto.kazatel.tasks.ContentVersion', null, global);
goog.exportSymbol('proto.kazatel.tasks.Residue', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.tasks.ContentMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.tasks.ContentMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.tasks.ContentMeta.displayName = 'proto.kazatel.tasks.ContentMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.tasks.Content = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.tasks.Content, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.tasks.Content.displayName = 'proto.kazatel.tasks.Content';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.tasks.ContentVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.tasks.ContentVersion.repeatedFields_, null);
};
goog.inherits(proto.kazatel.tasks.ContentVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.tasks.ContentVersion.displayName = 'proto.kazatel.tasks.ContentVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.tasks.Card = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.tasks.Card, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.tasks.Card.displayName = 'proto.kazatel.tasks.Card';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.tasks.CardData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.tasks.CardData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.tasks.CardData.displayName = 'proto.kazatel.tasks.CardData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.tasks.Common = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.tasks.Common, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.tasks.Common.displayName = 'proto.kazatel.tasks.Common';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.tasks.ContentByFormats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.tasks.ContentByFormats.repeatedFields_, null);
};
goog.inherits(proto.kazatel.tasks.ContentByFormats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.tasks.ContentByFormats.displayName = 'proto.kazatel.tasks.ContentByFormats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.tasks.ContentByTypes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.tasks.ContentByTypes.repeatedFields_, null);
};
goog.inherits(proto.kazatel.tasks.ContentByTypes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.tasks.ContentByTypes.displayName = 'proto.kazatel.tasks.ContentByTypes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.tasks.Residue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.tasks.Residue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.tasks.Residue.displayName = 'proto.kazatel.tasks.Residue';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.tasks.ContentMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.tasks.ContentMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.tasks.ContentMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.ContentMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    nativeName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    storageId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    size: jspb.Message.getFieldWithDefault(msg, 3, 0),
    format: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.tasks.ContentMeta}
 */
proto.kazatel.tasks.ContentMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.tasks.ContentMeta;
  return proto.kazatel.tasks.ContentMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.tasks.ContentMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.tasks.ContentMeta}
 */
proto.kazatel.tasks.ContentMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNativeName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStorageId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSize(value);
      break;
    case 4:
      var value = /** @type {!proto.kazatel.tasks.ContentFormat} */ (reader.readEnum());
      msg.setFormat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.tasks.ContentMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.tasks.ContentMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.tasks.ContentMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.ContentMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNativeName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStorageId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string native_name = 1;
 * @return {string}
 */
proto.kazatel.tasks.ContentMeta.prototype.getNativeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.tasks.ContentMeta} returns this
 */
proto.kazatel.tasks.ContentMeta.prototype.setNativeName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string storage_id = 2;
 * @return {string}
 */
proto.kazatel.tasks.ContentMeta.prototype.getStorageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.tasks.ContentMeta} returns this
 */
proto.kazatel.tasks.ContentMeta.prototype.setStorageId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 size = 3;
 * @return {number}
 */
proto.kazatel.tasks.ContentMeta.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.tasks.ContentMeta} returns this
 */
proto.kazatel.tasks.ContentMeta.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional ContentFormat format = 4;
 * @return {!proto.kazatel.tasks.ContentFormat}
 */
proto.kazatel.tasks.ContentMeta.prototype.getFormat = function() {
  return /** @type {!proto.kazatel.tasks.ContentFormat} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.kazatel.tasks.ContentFormat} value
 * @return {!proto.kazatel.tasks.ContentMeta} returns this
 */
proto.kazatel.tasks.ContentMeta.prototype.setFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.tasks.Content.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.tasks.Content.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.tasks.Content} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.Content.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    created: (f = msg.getCreated()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    premium: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    data: jspb.Message.getFieldWithDefault(msg, 5, ""),
    meta: (f = msg.getMeta()) && proto.kazatel.tasks.ContentMeta.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.tasks.Content}
 */
proto.kazatel.tasks.Content.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.tasks.Content;
  return proto.kazatel.tasks.Content.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.tasks.Content} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.tasks.Content}
 */
proto.kazatel.tasks.Content.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.kazatel.tasks.ContentType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPremium(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 6:
      var value = new proto.kazatel.tasks.ContentMeta;
      reader.readMessage(value,proto.kazatel.tasks.ContentMeta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.tasks.Content.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.tasks.Content.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.tasks.Content} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.Content.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = message.getPremium();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.kazatel.tasks.ContentMeta.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.kazatel.tasks.Content.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.tasks.Content} returns this
 */
proto.kazatel.tasks.Content.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ContentType type = 2;
 * @return {!proto.kazatel.tasks.ContentType}
 */
proto.kazatel.tasks.Content.prototype.getType = function() {
  return /** @type {!proto.kazatel.tasks.ContentType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.kazatel.tasks.ContentType} value
 * @return {!proto.kazatel.tasks.Content} returns this
 */
proto.kazatel.tasks.Content.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.type.DateTime created = 3;
 * @return {?proto.google.type.DateTime}
 */
proto.kazatel.tasks.Content.prototype.getCreated = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 3));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.kazatel.tasks.Content} returns this
*/
proto.kazatel.tasks.Content.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.tasks.Content} returns this
 */
proto.kazatel.tasks.Content.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.tasks.Content.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool premium = 4;
 * @return {boolean}
 */
proto.kazatel.tasks.Content.prototype.getPremium = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.tasks.Content} returns this
 */
proto.kazatel.tasks.Content.prototype.setPremium = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string data = 5;
 * @return {string}
 */
proto.kazatel.tasks.Content.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.tasks.Content} returns this
 */
proto.kazatel.tasks.Content.prototype.setData = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.tasks.Content} returns this
 */
proto.kazatel.tasks.Content.prototype.clearData = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.tasks.Content.prototype.hasData = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ContentMeta meta = 6;
 * @return {?proto.kazatel.tasks.ContentMeta}
 */
proto.kazatel.tasks.Content.prototype.getMeta = function() {
  return /** @type{?proto.kazatel.tasks.ContentMeta} */ (
    jspb.Message.getWrapperField(this, proto.kazatel.tasks.ContentMeta, 6));
};


/**
 * @param {?proto.kazatel.tasks.ContentMeta|undefined} value
 * @return {!proto.kazatel.tasks.Content} returns this
*/
proto.kazatel.tasks.Content.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.tasks.Content} returns this
 */
proto.kazatel.tasks.Content.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.tasks.Content.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.tasks.ContentVersion.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.tasks.ContentVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.tasks.ContentVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.tasks.ContentVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.ContentVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    contentsList: jspb.Message.toObjectList(msg.getContentsList(),
    proto.kazatel.tasks.Content.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.tasks.ContentVersion}
 */
proto.kazatel.tasks.ContentVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.tasks.ContentVersion;
  return proto.kazatel.tasks.ContentVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.tasks.ContentVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.tasks.ContentVersion}
 */
proto.kazatel.tasks.ContentVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 2:
      var value = new proto.kazatel.tasks.Content;
      reader.readMessage(value,proto.kazatel.tasks.Content.deserializeBinaryFromReader);
      msg.addContents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.tasks.ContentVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.tasks.ContentVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.tasks.ContentVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.ContentVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getContentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.kazatel.tasks.Content.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 version = 1;
 * @return {number}
 */
proto.kazatel.tasks.ContentVersion.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.tasks.ContentVersion} returns this
 */
proto.kazatel.tasks.ContentVersion.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Content contents = 2;
 * @return {!Array<!proto.kazatel.tasks.Content>}
 */
proto.kazatel.tasks.ContentVersion.prototype.getContentsList = function() {
  return /** @type{!Array<!proto.kazatel.tasks.Content>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kazatel.tasks.Content, 2));
};


/**
 * @param {!Array<!proto.kazatel.tasks.Content>} value
 * @return {!proto.kazatel.tasks.ContentVersion} returns this
*/
proto.kazatel.tasks.ContentVersion.prototype.setContentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.kazatel.tasks.Content=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.tasks.Content}
 */
proto.kazatel.tasks.ContentVersion.prototype.addContents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.kazatel.tasks.Content, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.tasks.ContentVersion} returns this
 */
proto.kazatel.tasks.ContentVersion.prototype.clearContentsList = function() {
  return this.setContentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.tasks.Card.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.tasks.Card.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.tasks.Card} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.Card.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nodeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    statusId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    created: (f = msg.getCreated()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f),
    userId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    owner: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.tasks.Card}
 */
proto.kazatel.tasks.Card.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.tasks.Card;
  return proto.kazatel.tasks.Card.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.tasks.Card} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.tasks.Card}
 */
proto.kazatel.tasks.Card.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatusId(value);
      break;
    case 4:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOwner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.tasks.Card.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.tasks.Card.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.tasks.Card} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.Card.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatusId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string task_id = 1;
 * @return {string}
 */
proto.kazatel.tasks.Card.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.tasks.Card} returns this
 */
proto.kazatel.tasks.Card.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string node_id = 2;
 * @return {string}
 */
proto.kazatel.tasks.Card.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.tasks.Card} returns this
 */
proto.kazatel.tasks.Card.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 status_id = 3;
 * @return {number}
 */
proto.kazatel.tasks.Card.prototype.getStatusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.tasks.Card} returns this
 */
proto.kazatel.tasks.Card.prototype.setStatusId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.type.DateTime created = 4;
 * @return {?proto.google.type.DateTime}
 */
proto.kazatel.tasks.Card.prototype.getCreated = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 4));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.kazatel.tasks.Card} returns this
*/
proto.kazatel.tasks.Card.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.tasks.Card} returns this
 */
proto.kazatel.tasks.Card.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.tasks.Card.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string user_id = 5;
 * @return {string}
 */
proto.kazatel.tasks.Card.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.tasks.Card} returns this
 */
proto.kazatel.tasks.Card.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.tasks.Card} returns this
 */
proto.kazatel.tasks.Card.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.tasks.Card.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool owner = 6;
 * @return {boolean}
 */
proto.kazatel.tasks.Card.prototype.getOwner = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.tasks.Card} returns this
 */
proto.kazatel.tasks.Card.prototype.setOwner = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.kazatel.tasks.Card} returns this
 */
proto.kazatel.tasks.Card.prototype.clearOwner = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.tasks.Card.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.tasks.CardData.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.tasks.CardData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.tasks.CardData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.CardData.toObject = function(includeInstance, msg) {
  var f, obj = {
    card: (f = msg.getCard()) && proto.kazatel.tasks.Card.toObject(includeInstance, f),
    content: (f = msg.getContent()) && proto.kazatel.tasks.ContentVersion.toObject(includeInstance, f),
    residue: (f = msg.getResidue()) && proto.kazatel.tasks.Residue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.tasks.CardData}
 */
proto.kazatel.tasks.CardData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.tasks.CardData;
  return proto.kazatel.tasks.CardData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.tasks.CardData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.tasks.CardData}
 */
proto.kazatel.tasks.CardData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.kazatel.tasks.Card;
      reader.readMessage(value,proto.kazatel.tasks.Card.deserializeBinaryFromReader);
      msg.setCard(value);
      break;
    case 2:
      var value = new proto.kazatel.tasks.ContentVersion;
      reader.readMessage(value,proto.kazatel.tasks.ContentVersion.deserializeBinaryFromReader);
      msg.setContent(value);
      break;
    case 3:
      var value = new proto.kazatel.tasks.Residue;
      reader.readMessage(value,proto.kazatel.tasks.Residue.deserializeBinaryFromReader);
      msg.setResidue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.tasks.CardData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.tasks.CardData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.tasks.CardData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.CardData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCard();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.kazatel.tasks.Card.serializeBinaryToWriter
    );
  }
  f = message.getContent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.kazatel.tasks.ContentVersion.serializeBinaryToWriter
    );
  }
  f = message.getResidue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.kazatel.tasks.Residue.serializeBinaryToWriter
    );
  }
};


/**
 * optional Card card = 1;
 * @return {?proto.kazatel.tasks.Card}
 */
proto.kazatel.tasks.CardData.prototype.getCard = function() {
  return /** @type{?proto.kazatel.tasks.Card} */ (
    jspb.Message.getWrapperField(this, proto.kazatel.tasks.Card, 1));
};


/**
 * @param {?proto.kazatel.tasks.Card|undefined} value
 * @return {!proto.kazatel.tasks.CardData} returns this
*/
proto.kazatel.tasks.CardData.prototype.setCard = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.tasks.CardData} returns this
 */
proto.kazatel.tasks.CardData.prototype.clearCard = function() {
  return this.setCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.tasks.CardData.prototype.hasCard = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ContentVersion content = 2;
 * @return {?proto.kazatel.tasks.ContentVersion}
 */
proto.kazatel.tasks.CardData.prototype.getContent = function() {
  return /** @type{?proto.kazatel.tasks.ContentVersion} */ (
    jspb.Message.getWrapperField(this, proto.kazatel.tasks.ContentVersion, 2));
};


/**
 * @param {?proto.kazatel.tasks.ContentVersion|undefined} value
 * @return {!proto.kazatel.tasks.CardData} returns this
*/
proto.kazatel.tasks.CardData.prototype.setContent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.tasks.CardData} returns this
 */
proto.kazatel.tasks.CardData.prototype.clearContent = function() {
  return this.setContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.tasks.CardData.prototype.hasContent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Residue residue = 3;
 * @return {?proto.kazatel.tasks.Residue}
 */
proto.kazatel.tasks.CardData.prototype.getResidue = function() {
  return /** @type{?proto.kazatel.tasks.Residue} */ (
    jspb.Message.getWrapperField(this, proto.kazatel.tasks.Residue, 3));
};


/**
 * @param {?proto.kazatel.tasks.Residue|undefined} value
 * @return {!proto.kazatel.tasks.CardData} returns this
*/
proto.kazatel.tasks.CardData.prototype.setResidue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.tasks.CardData} returns this
 */
proto.kazatel.tasks.CardData.prototype.clearResidue = function() {
  return this.setResidue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.tasks.CardData.prototype.hasResidue = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.tasks.Common.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.tasks.Common.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.tasks.Common} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.Common.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    premium: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    visible: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    userId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.tasks.Common}
 */
proto.kazatel.tasks.Common.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.tasks.Common;
  return proto.kazatel.tasks.Common.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.tasks.Common} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.tasks.Common}
 */
proto.kazatel.tasks.Common.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPremium(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisible(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.tasks.Common.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.tasks.Common.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.tasks.Common} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.Common.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPremium();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getVisible();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.kazatel.tasks.Common.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.tasks.Common} returns this
 */
proto.kazatel.tasks.Common.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool premium = 2;
 * @return {boolean}
 */
proto.kazatel.tasks.Common.prototype.getPremium = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.tasks.Common} returns this
 */
proto.kazatel.tasks.Common.prototype.setPremium = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool visible = 3;
 * @return {boolean}
 */
proto.kazatel.tasks.Common.prototype.getVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.tasks.Common} returns this
 */
proto.kazatel.tasks.Common.prototype.setVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string user_id = 4;
 * @return {string}
 */
proto.kazatel.tasks.Common.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.tasks.Common} returns this
 */
proto.kazatel.tasks.Common.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.tasks.ContentByFormats.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.tasks.ContentByFormats.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.tasks.ContentByFormats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.tasks.ContentByFormats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.ContentByFormats.toObject = function(includeInstance, msg) {
  var f, obj = {
    format: jspb.Message.getFieldWithDefault(msg, 1, 0),
    contentsList: jspb.Message.toObjectList(msg.getContentsList(),
    proto.kazatel.tasks.Common.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.tasks.ContentByFormats}
 */
proto.kazatel.tasks.ContentByFormats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.tasks.ContentByFormats;
  return proto.kazatel.tasks.ContentByFormats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.tasks.ContentByFormats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.tasks.ContentByFormats}
 */
proto.kazatel.tasks.ContentByFormats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.kazatel.tasks.ContentFormat} */ (reader.readEnum());
      msg.setFormat(value);
      break;
    case 2:
      var value = new proto.kazatel.tasks.Common;
      reader.readMessage(value,proto.kazatel.tasks.Common.deserializeBinaryFromReader);
      msg.addContents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.tasks.ContentByFormats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.tasks.ContentByFormats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.tasks.ContentByFormats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.ContentByFormats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getContentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.kazatel.tasks.Common.serializeBinaryToWriter
    );
  }
};


/**
 * optional ContentFormat format = 1;
 * @return {!proto.kazatel.tasks.ContentFormat}
 */
proto.kazatel.tasks.ContentByFormats.prototype.getFormat = function() {
  return /** @type {!proto.kazatel.tasks.ContentFormat} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.kazatel.tasks.ContentFormat} value
 * @return {!proto.kazatel.tasks.ContentByFormats} returns this
 */
proto.kazatel.tasks.ContentByFormats.prototype.setFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated Common contents = 2;
 * @return {!Array<!proto.kazatel.tasks.Common>}
 */
proto.kazatel.tasks.ContentByFormats.prototype.getContentsList = function() {
  return /** @type{!Array<!proto.kazatel.tasks.Common>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kazatel.tasks.Common, 2));
};


/**
 * @param {!Array<!proto.kazatel.tasks.Common>} value
 * @return {!proto.kazatel.tasks.ContentByFormats} returns this
*/
proto.kazatel.tasks.ContentByFormats.prototype.setContentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.kazatel.tasks.Common=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.tasks.Common}
 */
proto.kazatel.tasks.ContentByFormats.prototype.addContents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.kazatel.tasks.Common, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.tasks.ContentByFormats} returns this
 */
proto.kazatel.tasks.ContentByFormats.prototype.clearContentsList = function() {
  return this.setContentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.tasks.ContentByTypes.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.tasks.ContentByTypes.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.tasks.ContentByTypes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.tasks.ContentByTypes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.ContentByTypes.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    formatsList: jspb.Message.toObjectList(msg.getFormatsList(),
    proto.kazatel.tasks.ContentByFormats.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.tasks.ContentByTypes}
 */
proto.kazatel.tasks.ContentByTypes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.tasks.ContentByTypes;
  return proto.kazatel.tasks.ContentByTypes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.tasks.ContentByTypes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.tasks.ContentByTypes}
 */
proto.kazatel.tasks.ContentByTypes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.kazatel.tasks.ContentType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.kazatel.tasks.ContentByFormats;
      reader.readMessage(value,proto.kazatel.tasks.ContentByFormats.deserializeBinaryFromReader);
      msg.addFormats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.tasks.ContentByTypes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.tasks.ContentByTypes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.tasks.ContentByTypes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.ContentByTypes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getFormatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.kazatel.tasks.ContentByFormats.serializeBinaryToWriter
    );
  }
};


/**
 * optional ContentType type = 1;
 * @return {!proto.kazatel.tasks.ContentType}
 */
proto.kazatel.tasks.ContentByTypes.prototype.getType = function() {
  return /** @type {!proto.kazatel.tasks.ContentType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.kazatel.tasks.ContentType} value
 * @return {!proto.kazatel.tasks.ContentByTypes} returns this
 */
proto.kazatel.tasks.ContentByTypes.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated ContentByFormats formats = 2;
 * @return {!Array<!proto.kazatel.tasks.ContentByFormats>}
 */
proto.kazatel.tasks.ContentByTypes.prototype.getFormatsList = function() {
  return /** @type{!Array<!proto.kazatel.tasks.ContentByFormats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kazatel.tasks.ContentByFormats, 2));
};


/**
 * @param {!Array<!proto.kazatel.tasks.ContentByFormats>} value
 * @return {!proto.kazatel.tasks.ContentByTypes} returns this
*/
proto.kazatel.tasks.ContentByTypes.prototype.setFormatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.kazatel.tasks.ContentByFormats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.tasks.ContentByFormats}
 */
proto.kazatel.tasks.ContentByTypes.prototype.addFormats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.kazatel.tasks.ContentByFormats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.tasks.ContentByTypes} returns this
 */
proto.kazatel.tasks.ContentByTypes.prototype.clearFormatsList = function() {
  return this.setFormatsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.tasks.Residue.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.tasks.Residue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.tasks.Residue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.Residue.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordinary: jspb.Message.getFieldWithDefault(msg, 1, 0),
    premium: jspb.Message.getFieldWithDefault(msg, 2, 0),
    overLimit: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.tasks.Residue}
 */
proto.kazatel.tasks.Residue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.tasks.Residue;
  return proto.kazatel.tasks.Residue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.tasks.Residue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.tasks.Residue}
 */
proto.kazatel.tasks.Residue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrdinary(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPremium(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOverLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.tasks.Residue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.tasks.Residue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.tasks.Residue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.Residue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdinary();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPremium();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOverLimit();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 ordinary = 1;
 * @return {number}
 */
proto.kazatel.tasks.Residue.prototype.getOrdinary = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.tasks.Residue} returns this
 */
proto.kazatel.tasks.Residue.prototype.setOrdinary = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 premium = 2;
 * @return {number}
 */
proto.kazatel.tasks.Residue.prototype.getPremium = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.tasks.Residue} returns this
 */
proto.kazatel.tasks.Residue.prototype.setPremium = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool over_limit = 3;
 * @return {boolean}
 */
proto.kazatel.tasks.Residue.prototype.getOverLimit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kazatel.tasks.Residue} returns this
 */
proto.kazatel.tasks.Residue.prototype.setOverLimit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


goog.object.extend(exports, proto.kazatel.tasks);
