import TASK_TYPES from '@/views/tasks/utils/taskTypes.js';
import CONTENT_TYPES from '@/views/tasks/utils/contentTypes.js';


function deriveContentName(contentType, entityType) {
  /**
   * WARNING: the function argument `entityType` MUST use front-end type system.
   * I.e. be sure that entityType was passed through `BACK_TO_FRONT_ENTITY_TYPES` 
   * mapper that is placed in `@/views/tasks/hooks/useTaskTypes.js`.
   */
  let entityName = '';
  switch (contentType) {
    case CONTENT_TYPES.IMAGE:
    case CONTENT_TYPES.QUILL:
      if (entityType === TASK_TYPES.CONDITION) {
        entityName = 'Текст условия';
      } else if (entityType === TASK_TYPES.SOLUTION) {
        entityName = 'Текст решения'
      } else if (entityType === TASK_TYPES.AUDIO) {
        entityName = 'Аудио решения';
      } else if (entityType === TASK_TYPES.VIDEO) {
        entityName = 'Видео решения';
      }
      break;
    case CONTENT_TYPES.AUDIO:
      if (entityType === TASK_TYPES.CONDITION) {
        entityName = 'Аудио условия';
      } else {
        entityName = 'Аудио решения';
      }
      break;
    case CONTENT_TYPES.VIDEO:
      if (entityType === TASK_TYPES.CONDITION) {
        entityName = 'Видео условия';
      } else {
        entityName = 'Видео решения';
      }
      break;
    default:
      console.warn('Unprocessed content name:', contentType, entityType);
  }
  // console.log(`Getting content type [contentId=${contentId}, taskType={taskType}]:`, typeContent);
  return entityName;
}


export {
  deriveContentName,
}