!<template>
  <div class="audio-container">
    <kaz-loader v-if="isLoading" size="L"/>
    <player-audio v-else class="audio-container__player" :srcLink="audioLink"/>
  </div>
</template>

<script>
import PlayerAudio from '@/components/UI/media/PlayerAudio.vue';
import KazLoader from '@/components/KazUI/atoms/loader';

import { fetchContent } from '@/services/TaskReader';


export default {
  name: 'audio-viewer-moderation',

  components: {
    PlayerAudio,
    KazLoader,
  },

  props: {
    id: {
      // Content id
      type: String,
      required: true
    }
  },

  data() {
    return {
      audioLink: null,
      isLoading: true,
    }
  },

  watch: {
    id: {
      handler(newId) {
        if (newId) {
          this.fetchAudioContent(newId);
        }
      },
      immediate: true
    }
  },

  methods: {
    async fetchAudioContent(id) {
      try {
        this.isLoading = true;
        const response = await fetchContent(id);
        // console.log('AUDIO:', response.toObject());
        this.audioLink = response.toObject().contentData;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script>

<style scoped>
.audio-container {
  width: 100%;
}
.audio-container__player {
  width: 100%;
  border-radius: 8px;
}
</style>
