const routes = [
  {
    path: '/payment-success',
    name: 'payment-success',
    meta: {layout: 'profile'},
    component: () => import('@/views/payments/PaymentSuccess.vue')
  },
  {
    path: '/payment-failure',
    name: 'payment-failure',
    meta: {layout: 'profile'},
    component: () => import('@/views/payments/PaymentFailure.vue')
  }
]


export default routes
