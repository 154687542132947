// source: taskeditor/taskeditor.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_type_datetime_pb = require('../google/type/datetime_pb.js');
goog.object.extend(proto, google_type_datetime_pb);
goog.exportSymbol('proto.kazatel.tasks.TaskCard', null, global);
goog.exportSymbol('proto.kazatel.tasks.TaskCardCansel', null, global);
goog.exportSymbol('proto.kazatel.tasks.TaskCardCreate', null, global);
goog.exportSymbol('proto.kazatel.tasks.TaskCardCreateResponse', null, global);
goog.exportSymbol('proto.kazatel.tasks.TaskCardDelete', null, global);
goog.exportSymbol('proto.kazatel.tasks.TaskCardList', null, global);
goog.exportSymbol('proto.kazatel.tasks.TaskCardSaveComplete', null, global);
goog.exportSymbol('proto.kazatel.tasks.TaskCardSaveContentInit', null, global);
goog.exportSymbol('proto.kazatel.tasks.TaskCardSaveContentInitResponse', null, global);
goog.exportSymbol('proto.kazatel.tasks.TaskCardSaveGenerateUrlParts', null, global);
goog.exportSymbol('proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.tasks.TaskCardCreate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.tasks.TaskCardCreate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.tasks.TaskCardCreate.displayName = 'proto.kazatel.tasks.TaskCardCreate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.tasks.TaskCard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.tasks.TaskCard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.tasks.TaskCard.displayName = 'proto.kazatel.tasks.TaskCard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.tasks.TaskCardCreateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.tasks.TaskCardCreateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.tasks.TaskCardCreateResponse.displayName = 'proto.kazatel.tasks.TaskCardCreateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.tasks.TaskCardDelete = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.tasks.TaskCardDelete, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.tasks.TaskCardDelete.displayName = 'proto.kazatel.tasks.TaskCardDelete';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.tasks.TaskCardCansel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.tasks.TaskCardCansel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.tasks.TaskCardCansel.displayName = 'proto.kazatel.tasks.TaskCardCansel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.tasks.TaskCardList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.tasks.TaskCardList.repeatedFields_, null);
};
goog.inherits(proto.kazatel.tasks.TaskCardList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.tasks.TaskCardList.displayName = 'proto.kazatel.tasks.TaskCardList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.tasks.TaskCardSaveContentInit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.tasks.TaskCardSaveContentInit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.tasks.TaskCardSaveContentInit.displayName = 'proto.kazatel.tasks.TaskCardSaveContentInit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.tasks.TaskCardSaveContentInitResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.tasks.TaskCardSaveContentInitResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.tasks.TaskCardSaveContentInitResponse.displayName = 'proto.kazatel.tasks.TaskCardSaveContentInitResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlParts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kazatel.tasks.TaskCardSaveGenerateUrlParts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.tasks.TaskCardSaveGenerateUrlParts.displayName = 'proto.kazatel.tasks.TaskCardSaveGenerateUrlParts';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse.repeatedFields_, null);
};
goog.inherits(proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse.displayName = 'proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kazatel.tasks.TaskCardSaveComplete = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kazatel.tasks.TaskCardSaveComplete.repeatedFields_, null);
};
goog.inherits(proto.kazatel.tasks.TaskCardSaveComplete, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kazatel.tasks.TaskCardSaveComplete.displayName = 'proto.kazatel.tasks.TaskCardSaveComplete';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.tasks.TaskCardCreate.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.tasks.TaskCardCreate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.tasks.TaskCardCreate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.TaskCardCreate.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodeId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.tasks.TaskCardCreate}
 */
proto.kazatel.tasks.TaskCardCreate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.tasks.TaskCardCreate;
  return proto.kazatel.tasks.TaskCardCreate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.tasks.TaskCardCreate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.tasks.TaskCardCreate}
 */
proto.kazatel.tasks.TaskCardCreate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.tasks.TaskCardCreate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.tasks.TaskCardCreate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.tasks.TaskCardCreate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.TaskCardCreate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string node_id = 1;
 * @return {string}
 */
proto.kazatel.tasks.TaskCardCreate.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.tasks.TaskCardCreate} returns this
 */
proto.kazatel.tasks.TaskCardCreate.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.tasks.TaskCard.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.tasks.TaskCard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.tasks.TaskCard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.TaskCard.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nodeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    statusId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    created: (f = msg.getCreated()) && google_type_datetime_pb.DateTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.tasks.TaskCard}
 */
proto.kazatel.tasks.TaskCard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.tasks.TaskCard;
  return proto.kazatel.tasks.TaskCard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.tasks.TaskCard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.tasks.TaskCard}
 */
proto.kazatel.tasks.TaskCard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatusId(value);
      break;
    case 4:
      var value = new google_type_datetime_pb.DateTime;
      reader.readMessage(value,google_type_datetime_pb.DateTime.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.tasks.TaskCard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.tasks.TaskCard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.tasks.TaskCard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.TaskCard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatusId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_type_datetime_pb.DateTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string task_id = 1;
 * @return {string}
 */
proto.kazatel.tasks.TaskCard.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.tasks.TaskCard} returns this
 */
proto.kazatel.tasks.TaskCard.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string node_id = 2;
 * @return {string}
 */
proto.kazatel.tasks.TaskCard.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.tasks.TaskCard} returns this
 */
proto.kazatel.tasks.TaskCard.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 status_id = 3;
 * @return {number}
 */
proto.kazatel.tasks.TaskCard.prototype.getStatusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.tasks.TaskCard} returns this
 */
proto.kazatel.tasks.TaskCard.prototype.setStatusId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.type.DateTime created = 4;
 * @return {?proto.google.type.DateTime}
 */
proto.kazatel.tasks.TaskCard.prototype.getCreated = function() {
  return /** @type{?proto.google.type.DateTime} */ (
    jspb.Message.getWrapperField(this, google_type_datetime_pb.DateTime, 4));
};


/**
 * @param {?proto.google.type.DateTime|undefined} value
 * @return {!proto.kazatel.tasks.TaskCard} returns this
*/
proto.kazatel.tasks.TaskCard.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.tasks.TaskCard} returns this
 */
proto.kazatel.tasks.TaskCard.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.tasks.TaskCard.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.tasks.TaskCardCreateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.tasks.TaskCardCreateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.tasks.TaskCardCreateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.TaskCardCreateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    task: (f = msg.getTask()) && proto.kazatel.tasks.TaskCard.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.tasks.TaskCardCreateResponse}
 */
proto.kazatel.tasks.TaskCardCreateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.tasks.TaskCardCreateResponse;
  return proto.kazatel.tasks.TaskCardCreateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.tasks.TaskCardCreateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.tasks.TaskCardCreateResponse}
 */
proto.kazatel.tasks.TaskCardCreateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.kazatel.tasks.TaskCard;
      reader.readMessage(value,proto.kazatel.tasks.TaskCard.deserializeBinaryFromReader);
      msg.setTask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.tasks.TaskCardCreateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.tasks.TaskCardCreateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.tasks.TaskCardCreateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.TaskCardCreateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTask();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.kazatel.tasks.TaskCard.serializeBinaryToWriter
    );
  }
};


/**
 * optional TaskCard task = 1;
 * @return {?proto.kazatel.tasks.TaskCard}
 */
proto.kazatel.tasks.TaskCardCreateResponse.prototype.getTask = function() {
  return /** @type{?proto.kazatel.tasks.TaskCard} */ (
    jspb.Message.getWrapperField(this, proto.kazatel.tasks.TaskCard, 1));
};


/**
 * @param {?proto.kazatel.tasks.TaskCard|undefined} value
 * @return {!proto.kazatel.tasks.TaskCardCreateResponse} returns this
*/
proto.kazatel.tasks.TaskCardCreateResponse.prototype.setTask = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kazatel.tasks.TaskCardCreateResponse} returns this
 */
proto.kazatel.tasks.TaskCardCreateResponse.prototype.clearTask = function() {
  return this.setTask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kazatel.tasks.TaskCardCreateResponse.prototype.hasTask = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.tasks.TaskCardDelete.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.tasks.TaskCardDelete.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.tasks.TaskCardDelete} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.TaskCardDelete.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.tasks.TaskCardDelete}
 */
proto.kazatel.tasks.TaskCardDelete.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.tasks.TaskCardDelete;
  return proto.kazatel.tasks.TaskCardDelete.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.tasks.TaskCardDelete} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.tasks.TaskCardDelete}
 */
proto.kazatel.tasks.TaskCardDelete.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.tasks.TaskCardDelete.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.tasks.TaskCardDelete.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.tasks.TaskCardDelete} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.TaskCardDelete.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string task_id = 1;
 * @return {string}
 */
proto.kazatel.tasks.TaskCardDelete.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.tasks.TaskCardDelete} returns this
 */
proto.kazatel.tasks.TaskCardDelete.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.tasks.TaskCardCansel.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.tasks.TaskCardCansel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.tasks.TaskCardCansel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.TaskCardCansel.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cause: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.tasks.TaskCardCansel}
 */
proto.kazatel.tasks.TaskCardCansel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.tasks.TaskCardCansel;
  return proto.kazatel.tasks.TaskCardCansel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.tasks.TaskCardCansel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.tasks.TaskCardCansel}
 */
proto.kazatel.tasks.TaskCardCansel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCause(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.tasks.TaskCardCansel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.tasks.TaskCardCansel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.tasks.TaskCardCansel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.TaskCardCansel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCause();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string task_id = 1;
 * @return {string}
 */
proto.kazatel.tasks.TaskCardCansel.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.tasks.TaskCardCansel} returns this
 */
proto.kazatel.tasks.TaskCardCansel.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cause = 2;
 * @return {string}
 */
proto.kazatel.tasks.TaskCardCansel.prototype.getCause = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.tasks.TaskCardCansel} returns this
 */
proto.kazatel.tasks.TaskCardCansel.prototype.setCause = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.tasks.TaskCardList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.tasks.TaskCardList.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.tasks.TaskCardList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.tasks.TaskCardList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.TaskCardList.toObject = function(includeInstance, msg) {
  var f, obj = {
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    proto.kazatel.tasks.TaskCard.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.tasks.TaskCardList}
 */
proto.kazatel.tasks.TaskCardList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.tasks.TaskCardList;
  return proto.kazatel.tasks.TaskCardList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.tasks.TaskCardList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.tasks.TaskCardList}
 */
proto.kazatel.tasks.TaskCardList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.kazatel.tasks.TaskCard;
      reader.readMessage(value,proto.kazatel.tasks.TaskCard.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.tasks.TaskCardList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.tasks.TaskCardList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.tasks.TaskCardList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.TaskCardList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.kazatel.tasks.TaskCard.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TaskCard tasks = 1;
 * @return {!Array<!proto.kazatel.tasks.TaskCard>}
 */
proto.kazatel.tasks.TaskCardList.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.kazatel.tasks.TaskCard>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kazatel.tasks.TaskCard, 1));
};


/**
 * @param {!Array<!proto.kazatel.tasks.TaskCard>} value
 * @return {!proto.kazatel.tasks.TaskCardList} returns this
*/
proto.kazatel.tasks.TaskCardList.prototype.setTasksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.kazatel.tasks.TaskCard=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kazatel.tasks.TaskCard}
 */
proto.kazatel.tasks.TaskCardList.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.kazatel.tasks.TaskCard, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.tasks.TaskCardList} returns this
 */
proto.kazatel.tasks.TaskCardList.prototype.clearTasksList = function() {
  return this.setTasksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.tasks.TaskCardSaveContentInit.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.tasks.TaskCardSaveContentInit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.tasks.TaskCardSaveContentInit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.TaskCardSaveContentInit.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.tasks.TaskCardSaveContentInit}
 */
proto.kazatel.tasks.TaskCardSaveContentInit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.tasks.TaskCardSaveContentInit;
  return proto.kazatel.tasks.TaskCardSaveContentInit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.tasks.TaskCardSaveContentInit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.tasks.TaskCardSaveContentInit}
 */
proto.kazatel.tasks.TaskCardSaveContentInit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.tasks.TaskCardSaveContentInit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.tasks.TaskCardSaveContentInit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.tasks.TaskCardSaveContentInit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.TaskCardSaveContentInit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.tasks.TaskCardSaveContentInitResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.tasks.TaskCardSaveContentInitResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.tasks.TaskCardSaveContentInitResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.TaskCardSaveContentInitResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    uploadId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.tasks.TaskCardSaveContentInitResponse}
 */
proto.kazatel.tasks.TaskCardSaveContentInitResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.tasks.TaskCardSaveContentInitResponse;
  return proto.kazatel.tasks.TaskCardSaveContentInitResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.tasks.TaskCardSaveContentInitResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.tasks.TaskCardSaveContentInitResponse}
 */
proto.kazatel.tasks.TaskCardSaveContentInitResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.tasks.TaskCardSaveContentInitResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.tasks.TaskCardSaveContentInitResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.tasks.TaskCardSaveContentInitResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.TaskCardSaveContentInitResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUploadId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string upload_id = 1;
 * @return {string}
 */
proto.kazatel.tasks.TaskCardSaveContentInitResponse.prototype.getUploadId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.tasks.TaskCardSaveContentInitResponse} returns this
 */
proto.kazatel.tasks.TaskCardSaveContentInitResponse.prototype.setUploadId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlParts.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.tasks.TaskCardSaveGenerateUrlParts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.tasks.TaskCardSaveGenerateUrlParts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlParts.toObject = function(includeInstance, msg) {
  var f, obj = {
    uploadId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    numberParts: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.tasks.TaskCardSaveGenerateUrlParts}
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlParts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.tasks.TaskCardSaveGenerateUrlParts;
  return proto.kazatel.tasks.TaskCardSaveGenerateUrlParts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.tasks.TaskCardSaveGenerateUrlParts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.tasks.TaskCardSaveGenerateUrlParts}
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlParts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberParts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlParts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.tasks.TaskCardSaveGenerateUrlParts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.tasks.TaskCardSaveGenerateUrlParts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlParts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUploadId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumberParts();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string upload_id = 1;
 * @return {string}
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlParts.prototype.getUploadId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.tasks.TaskCardSaveGenerateUrlParts} returns this
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlParts.prototype.setUploadId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 number_parts = 2;
 * @return {number}
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlParts.prototype.getNumberParts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.kazatel.tasks.TaskCardSaveGenerateUrlParts} returns this
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlParts.prototype.setNumberParts = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    uploadId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    urlsPartsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse}
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse;
  return proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse}
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addUrlsParts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUploadId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrlsPartsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string upload_id = 1;
 * @return {string}
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse.prototype.getUploadId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse} returns this
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse.prototype.setUploadId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string urls_parts = 2;
 * @return {!Array<string>}
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse.prototype.getUrlsPartsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse} returns this
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse.prototype.setUrlsPartsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse} returns this
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse.prototype.addUrlsParts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse} returns this
 */
proto.kazatel.tasks.TaskCardSaveGenerateUrlPartsResponse.prototype.clearUrlsPartsList = function() {
  return this.setUrlsPartsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kazatel.tasks.TaskCardSaveComplete.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kazatel.tasks.TaskCardSaveComplete.prototype.toObject = function(opt_includeInstance) {
  return proto.kazatel.tasks.TaskCardSaveComplete.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kazatel.tasks.TaskCardSaveComplete} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.TaskCardSaveComplete.toObject = function(includeInstance, msg) {
  var f, obj = {
    uploadId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    partsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kazatel.tasks.TaskCardSaveComplete}
 */
proto.kazatel.tasks.TaskCardSaveComplete.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kazatel.tasks.TaskCardSaveComplete;
  return proto.kazatel.tasks.TaskCardSaveComplete.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kazatel.tasks.TaskCardSaveComplete} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kazatel.tasks.TaskCardSaveComplete}
 */
proto.kazatel.tasks.TaskCardSaveComplete.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addParts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kazatel.tasks.TaskCardSaveComplete.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kazatel.tasks.TaskCardSaveComplete.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kazatel.tasks.TaskCardSaveComplete} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kazatel.tasks.TaskCardSaveComplete.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUploadId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPartsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string upload_id = 1;
 * @return {string}
 */
proto.kazatel.tasks.TaskCardSaveComplete.prototype.getUploadId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kazatel.tasks.TaskCardSaveComplete} returns this
 */
proto.kazatel.tasks.TaskCardSaveComplete.prototype.setUploadId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string parts = 2;
 * @return {!Array<string>}
 */
proto.kazatel.tasks.TaskCardSaveComplete.prototype.getPartsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.kazatel.tasks.TaskCardSaveComplete} returns this
 */
proto.kazatel.tasks.TaskCardSaveComplete.prototype.setPartsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.kazatel.tasks.TaskCardSaveComplete} returns this
 */
proto.kazatel.tasks.TaskCardSaveComplete.prototype.addParts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kazatel.tasks.TaskCardSaveComplete} returns this
 */
proto.kazatel.tasks.TaskCardSaveComplete.prototype.clearPartsList = function() {
  return this.setPartsList([]);
};


goog.object.extend(exports, proto.kazatel.tasks);
