import { setContentMeta, setCanonicalUrl, removeCanonicalUrl } from '@/utils/meta';


export default {
  install(app, options) {
    app.config.globalProperties.$setMetaDescription = description => setContentMeta('name', 'description', description);
    
    app.config.globalProperties.$setOgMeta = (ogType, content) => setContentMeta('property', ogType, content);
    
    app.config.globalProperties.$setCanonicalUrl = setCanonicalUrl;

    app.config.globalProperties.$removeCanonicalUrl = removeCanonicalUrl;
  }
}
