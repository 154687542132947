!<template>
  <a 
    href="/manual-money.pdf"
    class="banner-card original-solutions original-solutions__back"
    target="_blank"
  >
    <span class="heading heading_size_h2">
      Мы платим за оригинальные решения
    </span>
    <span class="body body_size_S banner-card__description">
      Заполняй решения для отмеченных красным тэгом заданий и зарабатывай «денежки»
    </span>
  </a>
</template>

<script>
import KazButton from '@/components/KazUI/atoms/button';

import { openPublicFile } from '@/utils/links.js';


export default {
  name: 'banner-original-solutions',

  components: {
    KazButton,
  },

  methods: {
    openPublicFile,
  }
}
</script>

<style scoped>
@import url('./styles.css');

.original-solutions {
  background: #FFBBBB;
}

.original-solutions__back {
  background-image: url('@/assets/KazIllustrations/finance/denezki-logo.png');
  background-repeat: no-repeat;
  background-position: bottom;
}

@media screen and (max-width: 992px) {
  .original-solutions__back {
    background-position: right;
  }
}
</style>
