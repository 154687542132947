const COURSE_ORDER = [
  'Русский язык',
  'Математика',
  'Английский язык',
  'Алгебра',
  'Геометрия',
  'Физика',
  'История',
  'Сочинения',
  'Литература',
  'География',
  'Химия',
  'Окружающий мир',
  'Биология',
  'Обществознание',
  'Информатика',
  'Немецкий язык',
  'Литературное чтение',
  'Технология',
  'ОБЖ',
  'Французский язык',
  'Музыка',
  'ИЗО',
  'Искусство',
  'Китайский язык',
  'Экология',
  'Испанский язык',
  'Естествознание',
  'Природоведение',
  'Мир природы и человека',
];

function getCustomOrder() {
  const nameToOrder = COURSE_ORDER.reduce(
    (acc, cur, ind) => ({
      ...acc, 
      [cur.toLocaleLowerCase()]: ind + 1,
    }),
    {}
  );
  return courseName => nameToOrder[courseName.toLocaleLowerCase()] || 1000;
}

function coursesComparator(l, r) {
  let res = 0;
  if (l.order < r.order) {
    res = -1;
  } else if (l.order > r.order) {
    res = 1;
  }
  return res;
}


export {
  getCustomOrder,
  coursesComparator,
}
