import STATES from '@/utils/states';

/**
 * The module stores ordered list of links using array.
 * Each link item MUST be an object with 3 keys:
 *  + title - name of the link used in user interface;
 *  + path - router-link object;
 *  + order - number for sorting in user interface.
 * 
 * The object also accepts optional key 'state':
 *  + state (optional) - loading status to know that the link is ready for usage.
 */

export const lessonsRouter = {
  namespaced: true,

  state: () => ({
    links: [],
  }),

  getters: {
    getLinks: state => state.links.toSorted((a, b) => {
      if (a.order < b.order) {
        return -1
      } else if (a.order === b.order) {
        return 0
      } else {
        return 1
      }
    }),
    getState(state) {
      const counter = {
        [STATES.INIT]: 0,
        [STATES.LOADING]: 0,
        [STATES.ERROR]: 0,
        [STATES.LOADED]: 0,
      };
      
      for (const link of state.links) {
        if (link.state in counter) {
          ++counter[link.state];
        } else {
          console.warn(`Unknown loading state: ${link.state}`);
        }
      }

      let currentState = STATES.LOADED;
      if (counter[STATES.ERROR]) {
        currentState = STATES.ERROR;
      } else if (counter[STATES.LOADING]) {
        currentState = STATES.LOADING;
      } else if (counter[STATES.INIT]) {
        currentState = STATES.INIT;
      }
      return currentState
    }
  },

  mutations: {
    addLink(state, newLink) {
      try {
        newLink.state ??= STATES.INIT;
        state.links.push({...newLink});
        newLink.linkIndex = state.links.length - 1; 
      } catch (error) {
        console.warn(error);
      }
    },
    removeLink(state, index) {
      try {
        state.links.splice(index, 1);
      } catch (error) {
        console.warn(error);
      }
    },
    changeLinkState(state, {linkIndex, newState}) {
      try {
        if (linkIndex < 0 || linkIndex >= state.links.length) {
          throw new Error('Out of array bounds index: ', linkIndex);
        }
        state.links[linkIndex].state = newState;
      } catch (error) {
        console.warn(error);
      }
    },
    changeLinkContent(state, {linkIndex, path, title}) {
      try {
        if (path !== undefined) {
          state.links[linkIndex].path = path;
        }
        if (title !== undefined) {
          state.links[linkIndex].title = title;
        }
      } catch (error) {
        console.warn(error);
      }
    },
    resetState(state) {
      state.links = [];
    }
  }
}